<style lang="less">
.calendar {
  .year {
    margin: 60px 0;
    .month {
      margin: 40px 0;
      .month-title {
        font-weight: bold;
        font-size: 1.3rem;
        text-align: center;
        margin:20px 0 6px;
      }
      .week-header {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.7rem;
          height: 40px;
          flex: 1 1 0;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
        }
      }
      .week {
        display: flex;
        align-items: stretch;
        justify-content: stretch;

        .day {
          flex: 1 1 0;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:not(.blank) {
            box-shadow: inset 0 0 1px white;
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
          }
          &.today {
            background-color: rgba(255, 255, 255, 0.4);
            font-weight: bold;
            font-size: 1.1em;
          }
        }
      }
    }
  }
}
</style>


<template>
  <div class="calendar" ref="refCalendar">
    <div v-for="year in years" :key="`year-${year}`" class="year">
      <div v-for="m in 12" :key="`month-${year}-${m}`" class="month">
        <div class="month-title">{{ dayjs().month(m-1).format('MMMM') }} {{ year }}</div>
        <div class="week-header">
          <div>MO</div>
          <div>TU</div>
          <div>WE</div>
          <div>TH</div>
          <div>FR</div>
          <div>SA</div>
          <div>SU</div>
        </div>
        <div class="week" v-for="week of getWeeks(m, year)">
          <div class="day" :class="{ blank:!day, today: isToday(day, m, year)}" @click="clickDay(day, m, year)" v-for="day of week">{{ day }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, nextTick} from 'vue';
import dayjs from "dayjs";
import _ from "lodash";
import {clientRouter} from "@/client/router";

const refCalendar = ref(null);

const years = ref([
    new Date().getFullYear() - 2,
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1,
    new Date().getFullYear() + 2,
]);

const today = {
  day: new Date().getDate(),
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
};
const clickDay = async (day, month, year) => {
  const dateNumber = dayjs().year(year).month(month-1).date(day).startOf('day').toDate().toDateNum();
  void clientRouter.goToDate(dateNumber);
};
const isToday = (day, month, year) => {
  return today.day === day && today.month === month && today.year === year;
};

const getWeeks = (month, year) => {
  const d = dayjs().month(month-1).year(year).startOf('month');
  const days = _.times(d.daysInMonth()).map(n => n + 1);
  days.splice(0, 0, ..._.times(d.day() - 1).map(x => ''));
  const weeks = _.chunk(days, 7);
  const lastWeek = _.last(weeks);
  while (lastWeek.length < 7) {
    lastWeek.push('');
  }
  return weeks;
};

onMounted(() => {
  nextTick(() => {
    const screen = refCalendar.value.closest('.screen');
    const today = refCalendar.value.querySelector('.today');
    screen.scrollTop = today.offsetTop - (screen.clientHeight / 2 - today.clientHeight / 2);
  });
});

</script>

