import { clientContext } from 'src/client/context';
import { onMounted, ref, Ref, watch } from 'vue';
import { buildMapboxMap, IBuildMapboxMapResponse } from 'src/radar/build';
import { IMapboxLocations, setupMapboxLocationMarkers } from 'src/radar/locations';

export interface ISetupMapLoaderArgs {
  divMap:Ref<HTMLDivElement>;
  isOpen:Ref<boolean>;
  mapRef: Ref<mapboxgl.Map>;
  timeRef: Ref<string>;
  progressRef: Ref<number>;
}

export interface ISetupMapLoaderResponse {
  destroy: () => void;
}

export const setupMapLoader = (args:ISetupMapLoaderArgs):ISetupMapLoaderResponse => {
  const { divMap, isOpen, timeRef, progressRef, mapRef } = args;
  let mm:IBuildMapboxMapResponse = null;
  let ml:IMapboxLocations = null;

  const loadIfVisible = () => {
    if (clientContext.pageIndex !== 0) return;
    if (mm) return;
    mm = buildMapboxMap({
      divMap: divMap.value, isOpen, timeRef, progressRef
    });

    ml = setupMapboxLocationMarkers({
      map: mm.map,
      isOpen,
    });

    mapRef.value = mm.map as any;
  };

  onMounted(loadIfVisible);
  watch(() => clientContext.pageIndex, loadIfVisible);

  const destroy = () => {
    mm?.destroy();
    ml?.destroy();
  };

  return { destroy };
};
