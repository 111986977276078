import { IBigGraphConfigTemp } from 'src/screen/big-graph/types';
import _ from 'lodash';
import { convertPointsToBezierUsingCatmulRom } from 'src/utils/bezier';
import { clientContext } from '../../client/context';

const dpr = window.devicePixelRatio || 1;

export const drawTempsLine = (canvas: HTMLCanvasElement, temps:IBigGraphConfigTemp[], isFaded: boolean) => {
  if (!temps?.length) return;

  const width = canvas.width;
  const height = canvas.height;
  const tempToPoint = (temp:IBigGraphConfigTemp, i:number):IPoint => {
    return { x: (i / (temps.length - 1)) * width,  y: (1 - temp.perc) * height };
  };
  const points = temps.map((temp, i) => tempToPoint(temp, i));
  const line = convertPointsToBezierUsingCatmulRom(points);

  const ctx = canvas.getContext('2d');
  ctx.moveTo(line.start.x, line.start.y);
  ctx.beginPath();
  ctx.lineTo(line.start.x, line.start.y);
  for (const p of line.points) {
    ctx.bezierCurveTo(p.c1.x, p.c1.y, p.c2.x, p.c2.y, p.p.x, p.p.y);
  }
  ctx.strokeStyle = isFaded ? 'rgba(255, 255, 255, 0.2)' : '#ffffff';
  ctx.lineWidth = (isFaded ? 2.5 : 5) * dpr;
  ctx.stroke();

  const drawLabel = (t:IBigGraphConfigTemp, above: boolean) => {
    const tIndex = temps.indexOf(t);
    const p = tempToPoint(t, tIndex);
    const y = p.y + (above ? -10 : 14);
    const halfWidth = ctx.measureText(t.label).width / 2;
    const centerX = (p.x + 2);
    const x = Math.min(Math.max(halfWidth * 0.5, centerX - halfWidth), width - halfWidth * 2.5);
    ctx.textBaseline = above ? 'bottom' : 'top';
    ctx.textAlign = 'left';
    ctx.strokeStyle = clientContext.theme.color;
    ctx.lineWidth = 14;
    ctx.strokeText(t.label, x, y);
    ctx.fillStyle = '#fff';
    ctx.fillText(t.label, x, y);

    ctx.beginPath();
    ctx.arc(centerX, p.y, 10, 0, Math.PI * 2);
    ctx.lineWidth = 8;
    ctx.stroke();
    ctx.fill();

  };

  if (!isFaded && temps.length > 10) {
    const minTemp = _.minBy(temps, t => t.perc);
    drawLabel(minTemp, true);

    const maxTemp = _.maxBy(temps, t => t.perc);
    drawLabel(maxTemp, false);
  }

};

