<style lang="less">
.mdi-icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;

  &.spin {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <component :is="src" class="mdi-icon" :style="{fontSize: props.size ? `${props.size}em` : null}" :class="{spin:spin}" />
</template>

<script lang="ts" setup>
import { IMdiIcon, mdiIconsUtil } from 'src/client/mdi-icons';
import { computed } from 'vue';

const props = defineProps<{
  icon: IMdiIcon,
  size?: number,
  spin?: boolean,
}>();

const src = computed(() => mdiIconsUtil.get(props.icon));

</script>
