import { clientContext } from '../../client/context';
import { allDeclarations } from '../../utils/declarations';
import { IPickerModalOption, launchPickerModal } from '../../client/picker/util';
import { sackSynced } from '../../utils/sack';
import { nativeStoreKitService } from '../../native/native-store-kit-service';
import { nativeService } from '../../native/service';

declare global {
  interface ISettingsOption<T = any> {
    title: string;
    category: string;
    get value(): string;
    click?: () => any;
  }
}

const buildPickerOption = (args: {
  title: string;
  category: string;
  model: ISack<any>;
  options: readonly IPickerModalOption<any>[];
}):ISettingsOption<any> => {
  return {
    title: args.title,
    category: args.category,
    get value(): string {
      return args.options.find(o => o.id === args.model.value)?.title ?? args.model.value;
    },
    async click () {
      const result = await launchPickerModal({
        title: args.title,
        options: args.options,
        value: args.model.value,
        multiple: false,
      });
      args.model.value = result;
    }
  }
};

const boolOption = (args: {
  title: string,
  category: string,
  model: ISack<boolean>,
}) => {
  return buildPickerOption({
    title: args.title,
    category: args.category,
    model: args.model,
    options: [
      { id: true, title: 'On' },
      { id: false, title: 'Off' },
    ],
  });
};

export const getSettingsOptions = ():ISettingsOption[] => {
  return [
    buildPickerOption({
      title: 'Temperature',
      category: 'Units',
      model: sackSynced(clientContext.settings, 'tempUnits'),
      options: allDeclarations.tempUnits.all,
    }),
    buildPickerOption({
      title: 'Precipitation',
      category: 'Units',
      model: sackSynced(clientContext.settings, 'precipUnits'),
      options: allDeclarations.precipUnits.all,
    }),
    buildPickerOption({
      title: 'Distance',
      category: 'Units',
      model: sackSynced(clientContext.settings, 'distanceUnits'),
      options: allDeclarations.distanceUnits.all,
    }),
    buildPickerOption({
      title: 'Wind',
      category: 'Units',
      model: sackSynced(clientContext.settings, 'windUnits'),
      options: allDeclarations.windUnits.all,
    }),
    boolOption({
      title: 'Full Day',
      category: 'Units',
      model: sackSynced(clientContext.settings, 'showFullDay'),
    }),


    buildPickerOption({
      title: 'Timezone',
      category: 'Locale',
      model: sackSynced(clientContext.settings, 'timezone'),
      options: allDeclarations.timezones.all,
    }),
    buildPickerOption({
      title: 'Language',
      category: 'Locale',
      model: sackSynced(clientContext.settings, 'langCode'),
      options: allDeclarations.langCodes.all,
    }),
    buildPickerOption({
      title: 'Country',
      category: 'Locale',
      model: sackSynced(clientContext.settings, 'countryCode'),
      options: allDeclarations.countryCodes.all,
    }),


    buildPickerOption({
      category: 'Radar',
      title: 'Radar Style',
      model: sackSynced(clientContext.settings, 'radarStyle'),
      options: allDeclarations.radarStyles.all,
    }),
    buildPickerOption({
      category: 'Radar',
      title: 'Map Style',
      model: sackSynced(clientContext.settings, 'radarMapStyle'),
      options: allDeclarations.radarMapStyles.all,
    }),
    boolOption({
      title: 'Playback',
      category: 'Radar',
      model: sackSynced(clientContext.settings, 'radarIsPlaying'),
    }),
    boolOption({
      title: 'Show Location',
      category: 'Radar',
      model: sackSynced(clientContext.settings, 'radarShowLocation'),
    }),
    boolOption({
      title: 'Other Locations',
      category: 'Radar',
      model: sackSynced(clientContext.settings, 'radarShowOtherLocations'),
    }),

    {
      category: 'Debug',
      title: 'GetReceipt',
      value: '',
      async click(){
        alert(JSON.stringify({
          context: nativeService.context.receipt,
          live: await nativeStoreKitService.getReceipt(null),
        }));
      },
    },
    {
      category: 'Debug',
      title: 'RefreshReceipt',
      value: '',
      async click(){
        const result = await nativeStoreKitService.refreshReceipt(null);
        alert(JSON.stringify(result));
      },
    },
    {
      category: 'Debug',
      title: 'Purchase',
      value: '',
      async click(){
        const result = await nativeStoreKitService.purchase({
          productId: 'WNONE',
        });
        alert(JSON.stringify(result));
      },
    },
    {
      category: 'Debug',
      title: 'GetProducts',
      value: '',
      async click(){
        const result = await nativeStoreKitService.getProducts({
          productIds: ['WNONE', 'WNTWOMONTHS'],
        });
        alert(JSON.stringify(result));
      },
    },
  ];
};
