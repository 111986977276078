import * as PIXI from 'pixi.js';
import { clamp } from 'src/utils/math';
import snowflake from './snowflake.png';
import { IBigGraphConfig } from 'src/screen/big-graph/types';

export const buildRainDrops = (pixiApp: PIXI.Application, config: IBigGraphConfig): PIXI.Container => {
  const updaters: (() => void)[] = [];
  const container = new PIXI.Container();

  const bg = new PIXI.Graphics();
  bg.beginFill(config.isCold ? '#FFFFFF' : '#00CCFF', 0.5);
  bg.rect(0, 0, pixiApp.renderer.width, pixiApp.renderer.height);
  bg.endFill();
  container.addChild(bg);
  pixiApp.stage.addChild(container);

  if (!config.rain?.length) return container;

  for (const [i, r] of config.rain.entries()) {
    if (i === (config.rain.length - 1)) continue;
    const minX = (i / (config.rain.length - 1)) * pixiApp.screen.width;
    const maxX = ((i + 1) / (config.rain.length - 1)) * pixiApp.screen.width;
    const intensity = r.chance;
    const numberOfDrops = clamp(Math.round(intensity * 5), 1, 5);
    for (let j = 0; j < numberOfDrops; j++) {
      const xPerc = numberOfDrops <= 1 ? 0.5 : (j / (numberOfDrops - 1));
      const x = minX + ((maxX - minX) * xPerc);
      const length = Math.random() * 12 + 5;
      const y = Math.random() * pixiApp.screen.height;
      let speed = Math.pow(Math.max(intensity, 0.1), 1.5) * 12 + 4;
      speed *= 1 + (Math.random() * 2 - 1) * 0.2;
      let spin = 0;

      let sprite: PIXI.Sprite | PIXI.Graphics;
      const isSnowflake = precipTypeToRainOrSnow(r.type) === 'snow';

      if (isSnowflake) {
        //if (Math.random() > 0.5) continue;
        sprite = PIXI.Sprite.from(snowflake);
        sprite.scale.set(0.5 * (0.8 + Math.random() * 0.4));
        speed /= 3;
        spin = (Math.random() * 0.05) * (Math.random() < 0.5 ? -1 : 1);

        updaters.push(() => {
          sprite.rotation += spin;
        });
      } else {
        sprite = new PIXI.Graphics();
        sprite.beginFill(0xFFFFFF);
        sprite.rect(0, 0, 1.5, length);
        sprite.endFill();
      }

      sprite.x = x;
      sprite.y = y;
      sprite.alpha = 0.4;

      updaters.push(() => {
        sprite.y += speed;
        if (sprite.y > (pixiApp.screen.height + sprite.height)) {
          sprite.y = -10;
        }
      });

      container.addChild(sprite);
    }
  }

  pixiApp.ticker.add(() => {
    updaters.forEach(updater => updater());
  });

  return container;
};


const precipTypeToRainOrSnow = (precipitationType:IPrecipitationType) => {
  if (precipitationType === 'snow') return 'snow';
  if (precipitationType === 'sleet' || precipitationType === 'mixed') {
    return Math.random() > 0.5 ? 'snow' : 'rain';
  }
  return 'rain';
};
