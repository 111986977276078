<style lang="less">
.report-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .report-error-icon {
    img {
      width: 100%;
      max-width: 200px;
    }
  }

  .error-message {
    font-size: 1.3rem;
    font-weight: bold;
    padding: 0 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  .btn-reload {
    background-color: #fff;
    padding:0.3rem 0.8rem;
    font-size:1.2rem;
    font-weight: bold;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    color: black;
    mix-blend-mode: screen;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}
</style>

<template>
  <div class="screens">
    <screen :settings="{ screenIndex: 0, hideLocations: false }">
      <div class="report-error" :style="style">
        <div class="report-error-icon">
          <img src="../media/wn-icon-sad.png" />
        </div>
        <div class="error-message">{{ props.error }}</div>
        <div class="btn-reload" @click="clickReload">Try Again <mdi-icon icon="chevron_right" /></div>
      </div>
    </screen>
  </div>
</template>

<script setup lang="ts">
import Screen from '@/screen/screen.vue';
import { clientContext } from '@/client/context';
import { computed } from 'vue';
import MdiIcon from '@/client/mdi-icon.vue';

const props = defineProps<{ error: string }>();

const style = computed(() => {
  return {
    minHeight: `${clientContext.screenHeight - clientContext.statusBarHeight }px`,
  };
});

const clickReload = async () => {
  clientContext.latestReloadRequestTime = Date.now();
};

</script>
