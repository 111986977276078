import { IMdiIcon } from 'src/client/mdi-icons';


const all = [
  {
    code: 'BlowingDust',
    description: 'Blowing Dust',
    type: 'visibility',
    icon: 'air',
  },
  {
    code: 'Clear',
    description: 'Clear',
    type: 'visibility',
    icon: 'sunny',
  },
  {
    code: 'Cloudy',
    description: 'Cloudy',
    type: 'visibility',
    icon: 'cloud',
  },
  {
    code: 'Foggy',
    description: 'Foggy',
    type: 'visibility',
  },
  {
    code: 'Haze',
    description: 'Haze',
    type: 'visibility',
  },
  {
    code: 'MostlyClear',
    description: 'Mostly Clear',
    type: 'visibility',
    icon: 'sunny',
  },
  {
    code: 'MostlyCloudy',
    description: 'Mostly Cloudy',
    type: 'visibility',
    icon: 'cloud',
  },
  {
    code: 'PartlyCloudy',
    description: 'Partly Cloudy',
    type: 'visibility',
    icon: 'partly_cloudy_day',

  },
  {
    code: 'Smoky',
    description: 'Smoky',
    type: 'visibility',
    icon: 'heat',
  },
  {
    code: 'Breezy',
    description: 'Breezy',
    type: 'wind',
    icon: 'air',
  },
  {
    code: 'Windy',
    description: 'Windy',
    type: 'wind',
    icon: 'air',
  },
  {
    code: 'Drizzle',
    description: 'Drizzle',
    type: 'precipitation',
    icon: 'water_drop',
  },
  {
    code: 'HeavyRain',
    description: 'Heavy Rain',
    type: 'precipitation',
    icon: 'rainy_heavy',
  },
  {
    code: 'IsolatedThunderstorms',
    description: 'Isolated Storms',
    type: 'precipitation',
    icon: 'thunderstorm',
  },
  {
    code: 'Rain',
    description: 'Rain',
    type: 'precipitation',
    icon: 'rainy',
  },
  {
    code: 'SunShowers',
    description: 'Sun / Rain',
    type: 'precipitation',
    icon: 'rainy',
  },
  {
    code: 'ScatteredThunderstorms',
    description:  'Scattered Storms',
    type: 'precipitation',
    icon: 'thunderstorm',
  },
  {
    code: 'StrongStorms',
    description: 'Strong Storms',
    type: 'precipitation',
    icon: 'thunderstorm',
  },
  {
    code: 'Thunderstorms',
    description: 'Thunderstorms',
    type: 'precipitation',
    icon: 'thunderstorm',
  },
  {
    code: 'Frigid',
    description: 'Frigid',
    type: 'hazardous',
    icon: 'severe_cold',
  },
  {
    code: 'Hail',
    description: 'Hail',
    type: 'hazardous',
    icon: 'weather_hail',
  },
  {
    code: 'Hot',
    description: 'High Temps',
    type: 'hazardous',
    icon: 'sunny',
  },
  {
    code: 'Flurries',
    description: 'Flurries',
    type: 'winter-precipitation',
    icon: 'snowflake',
  },
  {
    code: 'Sleet',
    description: 'Sleet',
    type: 'winter-precipitation',
    icon: 'rainy_snow',
  },
  {
    code: 'Snow',
    description: 'Snow',
    type: 'winter-precipitation',
    icon: 'snowflake',
  },
  {
    code: 'SunFlurries',
    description: 'Snow Flurries',
    type: 'winter-precipitation',
    icon: 'snowflake',
  },
  {
    code: 'WintryMix',
    description: 'Wintry Mix',
    type: 'winter-precipitation',
    icon: 'rainy_snow',
  },
  {
    code: 'Blizzard',
    description: 'Blizzard',
    type: 'hazardous-winter',
    icon: 'severe_cold',
  },
  {
    code: 'BlowingSnow',
    description: 'Blowing Snow',
    type: 'hazardous-winter',
    icon: 'severe_cold',
  },
  {
    code: 'FreezingDrizzle',
    description: 'Freezing Drizzle',
    type: 'hazardous-winter',
    icon: 'rainy',
  },
  {
    code: 'FreezingRain',
    description: 'Freezing Rain',
    type: 'hazardous-winter',
    icon: 'rainy',
  },
  {
    code: 'HeavySnow',
    description: 'Heavy Snow',
    type: 'hazardous-winter',
    icon: 'severe_cold',
  },
  {
    code: 'Hurricane',
    description: 'Hurricane',
    type: 'tropical-hazard',
    icon: 'storm',
  },
  {
    code: 'TropicalStorm',
    description: 'Tropical Storm',
    type: 'tropical-hazard',
    icon: 'storm',
  },
] as const;

declare global {
  type IConditionCode = (typeof all)[number]['code'];

  interface IConditionCodeOption {
    code: IConditionCode;
    description: string;
    icon: IMdiIcon;
  }
}

export const conditionCodeUtil = {
  all: all as any as IConditionCodeOption[],
  get (code:IConditionCode):IConditionCodeOption {
    return all.find(c => c.code === code) as any;
  },
};
