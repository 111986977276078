import { INativeAppEvent, INativeContext } from 'src/native/types';
import { runNativeAppOperation } from 'src/native/app-operations';
import { TinyEmitter } from 'tiny-emitter';
import { reactive } from 'vue';

const nativeEmitter = new TinyEmitter();

export const nativeService = reactive({
  isConnected: false,
  context: INativeContext.demo(),
  run: (async () => null) as typeof runNativeAppOperation,
  onNative(event: INativeAppEvent, callback: () => any) { return nativeEmitter.on(event, callback) },
});

export const initClientNative = () => {
  window['wn.native.triggerNativeEvent'] = (e:INativeAppEvent, arg:any) => nativeEmitter.emit(e, arg);
  window.shake = () => nativeEmitter.emit('shake');

  const context = window['wn.native.context'];
  if (!context) return;

  nativeService.isConnected = true;
  nativeService.context = context;
  nativeService.run = runNativeAppOperation;

  void nativeService.run('is-loading', { isLoading: false });
};

