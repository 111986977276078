
<style lang="less">
.radar-toggle {
  display: flex;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  font-weight: bold;

  .toggle-option {
    flex: 1;
    padding: 5px;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .toggle-option.active {
    background-color: #fff;
    color: #000;
  }

  .toggle-option:not(.active):hover {
    background-color: #666;
  }
}
</style>


<template>
  <div class="radar-toggle">
    <div
        class="toggle-option"
        :class="{ active: props.params.isOn.value }"
        @click="selectLeft"
    >
      {{ props.params.onLabel }}
    </div>
    <div
        class="toggle-option"
        :class="{ active: !props.params.isOn.value }"
        @click="selectRight"
    >
      {{ props.params.offLabel }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Ref } from 'vue';

export interface IRadarToggleParams {
  onLabel: string;
  offLabel: string;
  isOn: Ref<boolean>;
}

const props = defineProps<{ params: IRadarToggleParams }>();

function selectLeft() {
  props.params.isOn.value = true;
}

function selectRight() {
  props.params.isOn.value = false;
}
</script>

