import dayjs from 'dayjs';
import { clientContext } from 'src/client/context';
import _ from 'lodash';
import { clientFormatters } from 'src/utils/formatters.client';
import { IBigGraphConfig, IBigGraphConfigBuilder, IBigGraphConfigBuilderArgs } from 'src/screen/big-graph/types';
import { reportUtils } from 'src/utils/report';
import { computed, ref } from 'vue';
import { clamp } from 'src/utils/math';

export interface IGetDayGraphConfigBuilderArgs {
  dateNumber: IDateNumber;
  report: IWeatherReport;
}

export const getDayGraphConfigBuilder = (args:IGetDayGraphConfigBuilderArgs):IBigGraphConfigBuilder => {
  return (args2:IBigGraphConfigBuilderArgs):IBigGraphConfig => {
    const { report, dateNumber } = args;
    const days = [...report.days, report.calendarDay].filter(x => x);
    const day = days.find(d => d.dateNumber === dateNumber);
    if (!day) throw new Error(`No day found for ${args.dateNumber}`);

    const startTime = dayjs(Date.fromDateNum(day.dateNumber)).tz(args.report.timezone).startOf('day').set('hour', clientContext.settings.showFullDay ? 0 : 5).toDate().getTime();
    const endTime = dayjs(Date.fromDateNum(day.dateNumber)).tz(args.report.timezone).endOf('day').toDate().getTime();
    const hours = day.hours.filter(h => h.time >= startTime);

    const prevDay = days.find(d => d.daysFromToday === (day.daysFromToday - 1));
    const prevHours = (() => {
      if (!prevDay?.hours?.length) return [];
      const prevDayStartTime = dayjs(Date.fromDateNum(prevDay.dateNumber)).tz(args.report.timezone).startOf('day').set('hour', clientContext.settings.showFullDay ? 0 : 5).toDate().getTime();
      return prevDay.hours.filter(h => h.time >= prevDayStartTime);
    })();

    const allTemps = [...hours, ...(prevHours ?? [])].map(t => parseFloat(clientFormatters.temp(t.tempReal)));
    let minTemp = _.min(allTemps);
    minTemp = Math.floor(minTemp / 10) * 10;
    let maxTemp = _.max(allTemps);
    maxTemp = Math.ceil(maxTemp / 10) * 10;

    const config:IBigGraphConfig = {};

    config.grid = {
      ys: [
        { label: `${minTemp}°`, perc: 0 },
        ...(() => {
          const n = Math.floor((maxTemp - minTemp) / 10) - 1;
          const gap = (maxTemp - minTemp) / (n + 1);
          return _.times(n, i => {
            const temp = minTemp + ((i + 1) * gap);
            const perc = (temp - minTemp) / (maxTemp - minTemp);
            return { perc, label: `${Math.round(temp)}°` };
          });
        })(),
        { label: `${maxTemp}°`, perc: 1 },
      ].flatMap(x => x),
      xs: (() => {
        if (clientContext.settings.showFullDay) {
          return [
            { perc: 3/24, label: '3am' },
            { perc: 6/24, label: '6am' },
            { perc: 9/24, label: '9am' },
            { perc: 12/24, label: 'Noon' },
            { perc: 15/24, label: '3pm' },
            { perc: 18/24, label: '6pm' },
            { perc: 21/24, label: '9pm' },
          ];
        }else{
          return [
            { perc: 1/19, label: '6am' },
            { perc: 4/19, label: '9am' },
            { perc: 7/19, label: 'Noon' },
            { perc: 10/19, label: '3pm' },
            { perc: 13/19, label: '6pm' },
            { perc: 16/19, label: '9pm' },
          ];
        }
      })(),
    };

    config.isCold = reportUtils.precipTypeIsCold(day.precipType);

    config.rain = hours.map(h => ({
      type: h.precipType,
      chance: h.precipChance,
      intensity: h.precipAmount,
    }));

    const tempToPerc = (t:number):number => {
      const tempConverted = parseFloat(clientFormatters.temp(t));
      return (tempConverted - minTemp) / (maxTemp - minTemp);
    };
    config.prevTemps = prevHours?.map(h => ({
      perc: tempToPerc(h.tempReal),
      label: clientFormatters.temp(h.tempReal),
    }));
    config.temps = hours.map(h => ({
      perc: tempToPerc(h.tempReal),
      label: clientFormatters.temp(h.tempReal),
    }));


    config.wind = hours.map(h => ({
      speed: h.windSpeed,
    }));

    config.nowLine = {};
    if (day.daysFromToday === 0) {
      const nowRef = ref(Date.now());
      config.nowLine.perc = computed(() => report.isDemo ? 0.6 : clamp((nowRef.value - startTime) / (endTime - startTime), 0, 1));
    }

    return config;
  };
};
