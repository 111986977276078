import { clientContext } from 'src/client/context';
import { IFetchWeatherReportArgs, IFetchWeatherReportResponse } from 'src/report/server/load/fetch';
import { ILoadCalendarDayArgs } from 'src/report/server/calendar/load-calendar-day';
import { ISearchLocationsArgs, ISearchLocationsResultsArgs } from '../settings/locations-modal/search.routes';
import { IValidateReceiptRouteArgs, IValidateReceiptRouteResults } from '../registration/validate-receipt.routes';

const buildUrl = (path:string):string => {
  path = String(path || '').replace(/^\/+/, '');
  return `/api/${path}`;
  // if (clientContext.isBrowser) {
  // }else{
  //   return `https://weathernerdapi.allstarapps.com/api/${path}`;
  // }
};

export const clientApi = {
  loadCalendarDay: async (args: Omit<ILoadCalendarDayArgs, 'log'>):Promise<IWeatherReportDay> => {
    const url = buildUrl(`/load-calendar-day`);
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(args),
      method: 'POST',
    }).then(x => x.json()) as IWeatherReportDay;
    return res;
  },
  loadWeatherReport: async (args: Omit<IFetchWeatherReportArgs, 'log'>):Promise<IFetchWeatherReportResponse> => {
    const url = buildUrl(`/load-report`);
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(args),
      method: 'POST',
    }).then(x => x.json()) as IFetchWeatherReportResponse;
    if (!res) throw new Error(`Client api returned empty response.`);
    if (res.error) throw new Error(res.error);
    return res;
  },
  searchLocations: async (args: ISearchLocationsArgs):Promise<ISearchLocationsResultsArgs> => {
    const url = buildUrl(`/search-locations`);
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(args),
      method: 'POST',
    }).then(x => x.json()) as ISearchLocationsResultsArgs;
    return res;
  },
  validateReceipt: async (args: IValidateReceiptRouteArgs):Promise<IValidateReceiptRouteResults> => {
    const url = buildUrl(`/validate-receipt`);
    const res = await fetch(url, {
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(args),
      method: 'POST',
    });

    if (!res.ok && res.status >= 400) {
      throw String(res.text());
    }

    return res.json();
  },
};

