import { clientContext } from 'src/client/context';
import { Ref, watch } from 'vue';
import mapboxgl from 'mapbox-gl';
import { IRainViewerLayer, IRainViewerWeatherMapsFrame, loadRainViewer } from 'src/radar/rain-viewer';
import _ from 'lodash';
import { IBuildMapboxMapArgs } from 'src/radar/build';

mapboxgl.accessToken = "pk.eyJ1Ijoiam9zaGF0ZmlybWFwcCIsImEiOiJjbTBqbmtpdTIwemhlMmtvYWo0ZjM3aDhlIn0.J2Q6nUeF-fqE-WBjrQBSIA";

const getMapboxStyle = () => {
  return clientContext.settings.radarMapStyle === 'streets'
    ? "mapbox://styles/mapbox/streets-v10"
    : "mapbox://styles/mapbox/satellite-streets-v12";
};

export interface IMapboxOverlay {
  destroy: () => void;
}

export const buildMapboxOverlay = (map:mapboxgl.Map, args:IBuildMapboxMapArgs):IMapboxOverlay => {
  const { isOpen, progressRef, timeRef } = args;

  const rainViewer = loadRainViewer({
    isOpen,
  });

  let layerIds:string[] = [];
  const loadLayers = () => {
    const layers = rainViewer.layersRef.value;
    for (const layer of layers) {
      layerIds.push(layer.id);
      map.addSource(layer.id, {
        type: "raster",
        tiles: [ layer.tile ],
        tileSize: 256,
      });
      map.addLayer({
        id: layer.id,
        type: "raster",
        paint: { 'raster-opacity': 0 },
        minzoom: 0,
        maxzoom: 12,
        source: layer.id,
      });
    }
  };

  const unloadLayers = () => {
    for (const layerId of layerIds) {
      map.removeLayer(layerId);
      map.removeSource(layerId);
    }
    layerIds = [];
  };

  const hideAllLayers = () => {
    for (const layerId of layerIds) {
      try {
        map.setPaintProperty(layerId, 'raster-opacity', 0);
      }catch(e) { /**/ }
    }
  };

  const showLayer = (frame:IRainViewerLayer) => {
    //map.setLayoutProperty(frame.id, "visibility", "visible");
    map.setPaintProperty(frame.id, 'raster-opacity', 1);
    timeRef.value = new Date(frame.time * 1000).toLocaleTimeString().replace(/:\d\d /, '');
    const min = _.minBy(rainViewer.layersRef.value, f => f.time).time;
    const max = _.maxBy(rainViewer.layersRef.value, f => f.time).time;
    progressRef.value = (frame.time - min) / (max - min);
  };

  let playTo:any = null;
  let playFrameIndex = -1;
  const updatePlayPause = () => {
    const layers = rainViewer.layersRef.value;
    clearTimeout(playTo);
    playTo = null;
    playFrameIndex = -1;
    if (!layers.length) return;

    if (layers.length > 1) {
      const showNext = () => {
        playFrameIndex += 1;
        const idx = playFrameIndex % layers.length;
        const prevLayer = idx === 0 ? null : layers[idx - 1];
        const layer = layers[idx];
        const isLastLayer = layer === _.last(layers);
        hideAllLayers();
        if (prevLayer) showLayer(prevLayer);
        showLayer(layer);
        playTo = setTimeout(showNext, isLastLayer ? 3000 : 800);
      };
      showNext();
    }else{
      const layer = _.last(layers);
      hideAllLayers();
      showLayer(layer);
    }
  };

  const reload = () => {
    unloadLayers();
    if (!map.loaded) return;
    if (!rainViewer.layersRef.value?.length) return;
    loadLayers();
    updatePlayPause();
  };

  map.on("load", reload);

  watch(() => rainViewer.layersRef.value, reload);

  watch(() => clientContext.settings.radarMapStyle, () => {
    map.setStyle(getMapboxStyle());
  });

  return {
    destroy () {
      unloadLayers();
      rainViewer?.destroy();
    },
  };
};
