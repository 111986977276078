

<template>
  <big-graph :builder="builder" @onNowPerc="onNowPerc" @load="emits('load')" />
</template>

<script lang="ts" setup>
import { clientContext } from 'src/client/context';
import { getDayGraphConfigBuilder } from 'src/screen/day-screen/day-graph/build-graph';
import BigGraph from 'src/screen/big-graph/big-graph.vue';
import dayjs from 'dayjs';

const props = defineProps<{ dateNumber: IDateNumber }>();
const emits = defineEmits(['onNowTime', 'load']);
const startTime = dayjs(Date.fromDateNum(props.dateNumber)).tz(clientContext.report.timezone).startOf('day');
const endTime = dayjs(Date.fromDateNum(props.dateNumber)).tz(clientContext.report.timezone).endOf('day');

const onNowPerc = (perc?:number) => {
  let time:number = null;
  if (typeof perc === 'number') {
    if (!clientContext.settings.showFullDay) {
      perc = (5/24) + (perc * (24 - 5) / 24);
    }
    time = Math.round(startTime + ((endTime - startTime) * perc));
  }
  emits('onNowTime', time);
};

const builder = getDayGraphConfigBuilder({
  dateNumber: props.dateNumber,
  report: clientContext.report,
});

//onNowPerc(0.5);

</script>
