
<style lang="less">
.graph-grid {
  position: absolute;
  inset: 0 0 0 0;

  .label {
    font-weight: bold;
    font-size: 0.75rem;
    color: #fff;
  }

  .x-lines {
    position: absolute;
    top: calc(100% - 30px);
    left: 0;
    right: 0;
    width: 100%;

    .x-line {
      position: absolute;
      transform: translateX(-50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tick {
        width: 1px;
        height: 5px;
        background-color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .y-lines {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 30px);
    width: 100%;

    .y-line {
      position: absolute;
      height: 0;
      overflow: visible;
      width: 100%;

      .tick {
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.5);
      }

      .label {
        color: rgba(255, 255, 255, 0.8);
        margin: 2px;

        &.top {
          position: relative;
          top:-1rem;
        }
      }
    }
  }
}
</style>

<template>
  <div class="graph-grid">
    <div class="x-lines">
      <div class="x-line"
          v-for="x of props.params.xs"
          :style="{ left: `${x.perc * 100}%` }">
        <div class="tick"></div>
        <div class="label">{{ x.label }}</div>
      </div>
    </div>
    <div class="y-lines">
      <div class="y-line"
          v-for="y of props.params.ys"
          :style="{ bottom: `${y.perc * 100}%` }">
        <div class="tick"></div>
        <div :class="{top: y.perc < 0.05}" class="label">{{ y.label }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
export interface IGraphGridParams {
  xs: { perc: number; label: string; }[];
  ys: { perc: number; label: string; }[];
}

const props = defineProps<{ params: IGraphGridParams }>();

</script>
