
<template>
  <anchored-item
      v-for="config of lingeringConfigs"
      :key="config.id"
      :config="config"
      :class="{ 'is-being-removed': pendingRemovals.has(config) }"
  />
</template>

<script lang="ts" setup>
import { modalConfigs } from '@/client/modal/service';
import AnchoredItem from '@/client/modal/anchored-item.vue';
import { computed, ref, watch } from 'vue';

const pendingRemovals = ref<Set<IModalConfig>>(new Set());
const lingeringConfigs = computed<IModalConfig[]>(() => {
  return [
    ...modalConfigs.value,
    ...Array.from(pendingRemovals.value),
  ];
});

watch(modalConfigs, (newConfigs, oldConfigs) => {
  const removedItems = oldConfigs.filter(item => !newConfigs.includes(item));
  removedItems.forEach(item => {
    pendingRemovals.value.add(item);
    setTimeout(() => {
      pendingRemovals.value.delete(item);
    }, 310);
  });
});

let isShrunk = false;
watch(modalConfigs, () => {
  const shouldBeShrunk = modalConfigs.value.length > 0;
  if (shouldBeShrunk === isShrunk) return;
  isShrunk = shouldBeShrunk;
  const el = document.querySelector('div.device-body');
  if (el) el.classList[isShrunk ? 'add' : 'remove']('shrunk');
});

</script>
