import { IBigGraphConfig } from 'src/screen/big-graph/types';
import * as PIXI from 'pixi.js';
import gsap from 'gsap';
import { clamp } from '../../utils/math';

export const buildRainOutline = (pixiApp: PIXI.Application, config: IBigGraphConfig):PIXI.Graphics => {
  if (!config?.rain?.length) return;

  // Create the graphics object for the shape
  const size = { w: pixiApp.renderer.width, h: pixiApp.renderer.height };

  // config.rain[config.rain.length - 3].chance = 0.9;
  // config.rain[config.rain.length - 2].chance = 0.8;
  // for (const r of config.rain) {
  //   r.chance = Math.random();
  // }

  // identify points
  const points = config.rain.flatMap((m, i) => {
    const x = (i / (config.rain.length - 1)) * size.w;
    const y = (1 - clamp(m.chance || 0, 0 , 1)) * size.h;
    return [
      i === 0 ? { x: x, y } : null,
      { x: x + (size.w / config.rain.length), y },
    ].filter(x => x);
  });
  for (const p of points) {
    if (p.y === size.h) p.y += 10;
  }

  // const drawValue = (v) => v.toString().padStart(4, ' ');
  // const drawPoint = (p) => `${drawValue(p.x)} x ${drawValue(p.y)}`;
  // for (const p of bezPoints) {
  //   console.log(` c1: ${drawPoint(p.c1)}`);
  //   console.log(` c2: ${drawPoint(p.c2)}`);
  //   console.log(`end: ${drawPoint(p.end)}`);
  // }

  const shape = new PIXI.Graphics();
  pixiApp.stage.addChild(shape);

  const isHour = points.length > 30;
  const redraw = () => {
    const bezPoints = convertToBezierPoints(points);
    shape.clear();
    shape.beginFill(0xFFFFFF);
    shape.moveTo(-20, size.h + 40);
    shape.lineTo(-20, points[0].y);
    for (const [i, bp] of bezPoints.entries()) {
      if (i === 0) {
        shape.lineTo(bp.end.x, bp.end.y);
      } else {
        if (isHour) {
          shape.lineTo(bp.end.x, bp.end.y);
        }else{
          shape.bezierCurveTo(bp.c1.x, bp.c1.y, bp.c2.x, bp.c2.y, bp.end.x, bp.end.y);
        }
      }
    }
    shape.lineTo(size.w + 20, points[points.length - 1].y);
    shape.lineTo(size.w + 20, size.h + 40);
    shape.lineTo(-20, size.h + 40);
    shape.endFill();
  };
  redraw();

  (() => {
    for (const p of points.slice(2, points.length - 2)) {
      // Animate the end point y-value
      gsap.to(p, {
        y: p.y + (Math.random() - 0.5) * (isHour ? 20 : 20),
        duration: 0.5 + Math.random() * 0.5,
        yoyo: true,
        repeat: -1,
        ease: 'sine.inOut'
      });
    }
  })();

  pixiApp.ticker.add(redraw);

  return shape;
};


function convertToBezierPoints(points: {x: number, y: number}[]) {
  const scaler = 0.8;
  return points.map((end, index) => {
    const previousPoint = points[index - 1] ?? end;

    const c1 = {
      x: previousPoint.x + (end.x - previousPoint.x) * scaler,
      y: previousPoint.y,
    };
    const c2 = {
      x: end.x + (previousPoint.x - end.x) * scaler,
      y: end.y,
    };

    return { c1, c2, end };
  });
}
