import exp from 'constants';
import { ref, Ref, watch } from 'vue';
import { clientContext } from 'src/client/context';
import _ from 'lodash';
import { appFlags } from 'src/flags';

export interface IRadarData {
  past: IRainViewerWeatherMapsFrame[];
  nowcast: IRainViewerWeatherMapsFrame[];
}

export interface IRainViewerWeatherMapsFrame {
  time: number;
  path: string;
}

export interface ISatelliteData {
  infrared: IRainViewerWeatherMapsFrame[];
}

export interface IRainViewerApiResponse {
  version: string;
  generated: number;
  host: string;
  radar: IRadarData;
  satellite: ISatelliteData;
}

export interface IRainViewerLayer {
  id: string;
  time: number;
  tile: string;
}

export interface ILoadRainViewerArgs {
  isOpen: Ref<boolean>;
}

export interface IRainViewer {
  layersRef: Ref<IRainViewerLayer[]>;
  destroy: () => void;
}

export const directLoad = async ():Promise<IRainViewerApiResponse> => {
  if (appFlags.hideRadar) return { radar: { past: [], nowcast: [] }, satellite: { infrared: [] } } as any;
  return fetch('https://api.rainviewer.com/public/weather-maps.json').then(x => x.json());
};

export const loadRainViewer = (args:ILoadRainViewerArgs):IRainViewer => {
  let res:IRainViewerApiResponse = null;
  const layersRef = ref<IRainViewerLayer[]>([]);

  const getFrames = () => {
    if (clientContext.settings.radarStyle === 'radar') {
      return res?.radar.past || [];
    }else{
      return res?.satellite.infrared || [];
    }
  };
  const getLayers = () => {
    const layers = getFrames().map(f => ({
      id: f.path,
      tile: res.host + f.path + '/256/{z}/{x}/{y}/4/0_0.png',
      time: f.time,
    }));
    const doPlay = clientContext.settings.radarIsPlaying && args.isOpen.value;
    return doPlay ? layers : _.takeRight(layers, 1);
  };

  const reload = async () => {
    res = await directLoad();
    const oldLayers = layersRef.value;
    const newLayers = getLayers();
    const isSame = newLayers.map(l => l.id).join(',') === oldLayers.map(l => l.id).join(',');
    if (isSame) return;
    layersRef.value = newLayers;
  };

  setTimeout(reload, 10);
  watch(() => clientContext.settings.radarStyle, reload);
  watch(() => clientContext.settings.radarIsPlaying, reload);
  watch(() => args.isOpen.value, reload);

  const interval = setInterval(reload, 1000 * 30);

  const viewer = {
    layersRef,
    destroy () {
      clearInterval(interval);
    }
  };

  return viewer;
};
