
<template>
  <div class="screens" ref="divScreens">
    <hour-screen />
    <day-screen
        v-for="[i, dn] of dayNumbers.entries()"
        :date-number="dn"
        :key="`day-${dn}`"
        :screenIndex="i + 1"
    />
    <week-screen />
    <calendar-screen />
  </div>
  <footer-bar />
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { clientContext } from 'src/client/context';
import _ from 'lodash';
import DayScreen from 'src/screen/day-screen/day-screen.vue';
import HourScreen from 'src/screen/hour-screen/hour-screen.vue';
import WeekScreen from 'src/screen/week-screen/week-screen.vue';
import CalendarScreen from 'src/screen/calendar-screen/calendar-screen.vue';
import { nativeHapticService } from '@/native/haptic-service';
import FooterBar from '@/device/footer-bar.vue';

const divScreens = ref<HTMLDivElement>(null);
const dayNumbers = ref<IDateNumber[]>(clientContext.report.days.slice(1, 3).map(d => d.dateNumber));

onMounted(() => {
  if (!divScreens.value) return;

  divScreens.value.scrollLeft = clientContext.screenWidth * clientContext.pageIndex;

  divScreens.value.addEventListener('scroll', _.throttle(() => {
    const isSnapped = (divScreens.value.scrollLeft % clientContext.screenWidth) === 0;
    clientContext.pageIndexDecimal = divScreens.value.scrollLeft / clientContext.screenWidth;
    if (isSnapped) {
      clientContext.pageIndex = Math.round(divScreens.value.scrollLeft / clientContext.screenWidth);
      clientContext.pageIndexDecimal = clientContext.pageIndex;
    }
  }, 10));

  // Haptic
  (() => {
    let prevWasExact = Math.floor(clientContext.pageIndexDecimal) === clientContext.pageIndexDecimal;
    watch(() => clientContext.pageIndexDecimal, () => {
      const current = Math.floor(clientContext.pageIndexDecimal) === clientContext.pageIndexDecimal;
      if (current === prevWasExact) return;
      prevWasExact = current;
      if (!current) {
        nativeHapticService.send('low');
      }
    });
  })();
});
</script>
