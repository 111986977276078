<style lang="less">
.status-bar {
  position: fixed;
  top: 0;
  z-index: 2;

  .status-bar-status {
    //background-color: rgba(255, 255, 0, 0.2);
  }
  .status-bar-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .status-bar-title {
      text-align: center;
      text-transform: uppercase;
      color:rgba(255, 255, 255, 0.8);
      font-weight: bold;
      z-index: 1;
      letter-spacing: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;

      .mdi-icon {
        position: relative;
        top:-2px;
      }
    }
  }
}
</style>

<template>
  <div class="status-bar bright-blurry-bg" :style="{ width: `${clientContext.screenWidth}px`, height: `${clientContext.statusBarHeight}px` }">
    <div class="status-bar-status" :style="{height: `${clientContext.statusBarHeightOfStatus}px`}"></div>
    <div class="status-bar-title-row" :style="{
      height: `${clientContext.statusBarHeightOfTitle}px`,
      fontSize: clientContext.statusBarHeightOfTitle < 30 ? '0.6em' : '0.8em',
    }">
      <div class="status-bar-title">
        <span @click="clickTitle">
          <mdi-icon v-if="icon" :icon="icon" />
          {{ title || '' }}
        </span>
        <mdi-icon v-if="true" @click="clickDebug" icon="bug_report" />
      </div>
    </div>
    <status-bar-loading-indicator />
  </div>
</template>

<script lang="ts" setup>
import { clientContext } from 'src/client/context';
import { computed } from 'vue';
import MdiIcon from '@/client/mdi-icon.vue';
import StatusBarLoadingIndicator from '@/device/status-bar-loading-indicator.vue';
import { nativeService } from '@/native/service';
import { showClientDebugger } from '@/client/debugger/init';
import { launchLocationsModal } from '@/settings/locations-modal/util';
import { filterInPlace } from '@/utils/array';

const icon = computed(() => {
  const l = clientContext.settings.currentLocation;
  return l?.id === 'live' ? 'near_me' : null;
});

const title = computed(() => {
  const l = clientContext.settings.currentLocation;
  if (!l) return 'Weather Nerd';
  if (l?.id === 'live') {
    return clientContext.report?.locationTitle || l?.title || 'My Location';
  }
  return String(l?.title || '').replace(/, United States$/ig, '');
});

const clickTitle = () => {
  if (!clientContext.settings.currentLocation) return;
  void launchLocationsModal();
};
const clickDebug = () => {
  void showClientDebugger();
};
</script>
