
<style lang="less">
.day-stat-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 24px;

  .day-stat {
    flex: 1 1 0;
    text-align: center;

    .day-stat-value {
      font-weight: bold;
      font-size: 1.3rem;
      white-space: pre;
      line-height: 1em;
      height: 28px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .super {
        font-size: 0.5em;
        vertical-align: top;
        position: relative;
        top: 3px;
      }
    }
    .day-stat-label {
      font-weight: bold;
      font-size: 0.7rem;
      opacity: 0.5;
    }
  }
}
</style>

<template>
  <div class="day-stat-row">
    <div class="day-stat" v-for="ic of internalCells" :class="{big: ic.stat?.big}">
      <div>
        <div class="day-stat-value" v-if="ic.stat?.value !== null" :style="ic.valueStyle">{{ String(ic.stat?.value ?? '').replace(/\s/g, '\n') }}<span class="super" v-if="ic.stat?.valueSuffix">{{ ic.stat.valueSuffix.toUpperCase() }}</span></div>
        <div class="day-stat-label" v-if="ic.stat?.label">{{ ic.stat.label.toUpperCase() }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import _ from 'lodash';

export interface IStatCell {
  value: string;
  valueSuffix?: string;
  label: string;
  big?: boolean;
}

interface IInternalCell {
  stat: IStatCell;
  valueStyle: any;
}

const props = defineProps<{ cells: IStatCell[] }>();

const internalCells = computed<IInternalCell[]>(() => {
  return _.times(4, i => {
    const stat:IStatCell = props.cells[i];
    const valueStyle = {} as any;
    if (typeof stat?.value === 'string' && stat.value.length) {
      const maxLength = _.reduce(stat.value.split(''), (t, l) => t + getCharWidth(l), 0);
      const idealMax = 5;
      if (idealMax < maxLength) {
        valueStyle.fontSize = `${(idealMax / maxLength) * 1.4}em`;
        valueStyle.top = `-${(maxLength - idealMax) / 3}px`;
      }
    }
    return { stat, valueStyle };
  });
});

const getCharWidth = (c:string):number => {
  if ('.!1Iil:°'.includes(c)) return 0.5;
  if ('MmWw'.includes(c)) return 2;
  return 1;
};

</script>
