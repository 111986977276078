import { clientContext } from 'src/client/context';
import { ref, Ref } from 'vue';

export interface ISetupRadarOpenCloseArgs {
  divMapContainer: Ref<HTMLDivElement>;
  mapRef: Ref<mapboxgl.Map>;
  isOpen:Ref<boolean>;
}

export const setupRadarOpenClose = (args:ISetupRadarOpenCloseArgs) => {
  const { divMapContainer, isOpen, mapRef } = args;
  let parentDiv:HTMLElement = null;
  const clickClose = () => {
    const div = divMapContainer.value;
    parentDiv.appendChild(div);
    const targetDiv = div.parentElement;
    const targetRect = targetDiv.getBoundingClientRect();

    div.style.top = `${targetRect.top}px`;
    div.style.left = `${targetRect.left}px`;
    div.style.width = `${targetRect.width}px`;
    div.style.height = `${targetRect.height}px`;
    div.style.borderRadius = '10px';
    isOpen.value = false;

    const interval = setInterval(() => {
      mapRef.value?.resize();
    }, 10);
    setTimeout(() => {
      clearInterval(interval);
      div.style.transition = ``;
      div.style.zIndex = ``;
      div.style.position = `absolute`;
      div.style.top = `0`;
      div.style.left = `0`;
      div.style.bottom = `0`;
      div.style.right = `0`;
      mapRef.value?.resize();
    }, 500);
  };

  const clickOpen = () => {
    const div = divMapContainer.value;
    parentDiv = div.parentElement;
    document.body.appendChild(div);
    const divRect = div.getBoundingClientRect();

    div.style.transition = '';
    div.style.position = 'fixed';
    div.style.top = `${divRect.top}px`;
    div.style.left = `${divRect.left}px`;
    div.style.width = `${divRect.width}px`;
    div.style.height = `${divRect.height}px`;
    div.style.zIndex = `1000`;

    requestAnimationFrame(() => {
      div.style.borderRadius = '0';
      div.style.transition = 'all 500ms ease';
      div.style.top = `0px`;
      div.style.left = `0px`;
      div.style.width = `${clientContext.screenWidth}px`;
      div.style.height = `${clientContext.screenHeight}px`;
      div.style.borderRadius = `0`;
      isOpen.value = true;
      const interval = setInterval(() => {
        mapRef.value?.resize();
      }, 10);
      setTimeout(() => {
        clearInterval(interval);
        mapRef.value?.resize();
      }, 500);
    });
  };

  return { clickOpen, clickClose, isOpen };
};
