import { reactive } from 'vue';

export class LoadingTracker {
  name: string;
  isLoading: boolean = true;
  constructor(name: string) {
    this.name = name;
    return reactive(this);
  }
  restart () {
    this.isLoading = true;
  }
  end(){
    this.isLoading = false;
  }
}

export class LoadingStatus {
  trackers: LoadingTracker[] = [];

  constructor() {
    return reactive(this);
  }

  get isLoading () {
    const is = !!this.trackers.find(t => t.isLoading);
    return is;
  }

  start (name: string):ILoadingTracker {
    const tracker = new LoadingTracker(name);
    this.trackers.push(tracker);
    return tracker;
  }

  append(tracker: LoadingTracker) {
    this.trackers.push(tracker);
  }
}

export const globalLoading = new LoadingStatus();

declare global {
  type ILoadingStatus = LoadingStatus;
  type ILoadingTracker = LoadingTracker;
}
