
<style lang="less">
.screen-header {
  font-weight: bold;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;

  .screen-header-title {
    font-size: 1.6rem;
  }
  .screen-header-subtitle {
    font-size: 0.8rem;
    font-weight: normal;
    margin:3% 3% 0 3%;
  }
}
</style>

<template>
  <div class="screen-header" :style="{ height: `${clientContext.headerHeight}px` }">
    <div style="flex: 1;">
      <div class="screen-header-title">
        <span v-fit>{{ props.title }}</span>
      </div>
      <div class="screen-header-subtitle" v-if="props.subtitle">
        {{ props.subtitle }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { clientContext } from 'src/client/context';
const props = defineProps<{
  title: string,
  subtitle?: string,
}>();
</script>
