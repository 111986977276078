
<style lang="less">
.locations-screen {
  padding: 0 15px 50px;

  .locations-screen-subheading {
    color: rgba(255, 255, 255, 0.2);
    padding: 40px 0 10px;
    font-size: 0.8em;
  }

  .locations-screen-item {
    color: #fff;
    padding: 5px 10px;
    background-color: #191919;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: solid 2px rgba(255, 255, 255, 0.15);
    opacity: 0.8;
    border-radius: 12px;
    margin-bottom: 10px;
    font-size: 0.8em;

    &:hover {
      opacity: 1;
      border-color: rgba(255, 255, 255, 0.5);
      background-color: #333;
    }

    &.active {
      color: #3af;
      border-color: #3af;
    }

    .title {
      padding: 10px;
      flex: 1;
    }

    .btn {
      padding: 10px;
      color: rgba(255, 255, 255, 0.2);
      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}
</style>

<template>
  <wn-modal :settings="modalSettings" v-slot="{ modal }">
    <div class="locations-screen">
      <Search :q="q" />
      <Current v-if="!q.value.trim()" />
      <Results v-else :q="q" />
    </div>
  </wn-modal>
</template>

<script lang="ts" setup>
import Search from './search.vue';
import WnModal from '@/client/modal/wn-modal.vue';
import { useModalService } from '@/client/modal/service';
import { sackFor } from '@/utils/sack';
import Current from './current.vue';
import Results from './results.vue';

const q = sackFor('');
const modal = useModalService();
const modalSettings:IModalSettings = {
  bgColor: '#111',
  direction: 'bottom',
  onClickAbove () {
    modal.close();
  },
  title: '',
  rightButton: {
    label: 'Close',
    click () { modal.close(); },
  },
};


</script>
