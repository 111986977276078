
<style lang="less" scoped>
.locations-results {
  .locations-results-item {
    color: #fff;
    padding: 10px 15px;
    font-size: 0.75em;
    border-bottom: 1px solid #222;
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: #222;
    }
  }
}
</style>

<template>
  <div class="locations-results">
    <div v-if="isLoading" style="display: flex; align-items: center; justify-content: center; margin-top: 20px;">
      <svg style="width: 30px; height: 30px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a5" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#FFFFFF"></stop><stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a5)" stroke-width="16" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="1" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="16" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
    </div>
    <template v-else>
      <div v-for="l of searchResults"
          :key="l.id"
          class="locations-results-item"
          @click="clickResult(l)"
      >{{ l.title }}</div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import { clientApi } from '@/client/api';
import { ref, watch } from 'vue';
import { useModalService } from '@/client/modal/service';

const props = defineProps<{ q: ISack<string> }>();
const searchResults = ref<IUserLocationSettings[]>([]);
const isLoading = ref<boolean>(false);
const modal = useModalService();

const update = _.debounce(async () => {
  try {
    const x = await clientApi.searchLocations({
      q: props.q.value,
    });
    searchResults.value = x.locations;
  }finally {
    isLoading.value = false;
  }
}, 1000, { trailing: true, leading: false });

const clickResult = async (l:IUserLocationSettings) => {
  modal.close(l);
};

watch(() => props.q.value, () => {
  isLoading.value = true;
  update();
});

</script>
