
<style lang="less" src="./reset.less" />
<style lang="less" src="./root.less" />
<style lang="less" src="./fonts/fonts.less" />

<template>
  <device />
</template>

<script setup lang="ts">
import Device from 'src/device/device.vue';
import { nextTick, onMounted } from 'vue';
import { globalLoading } from '@/client/loading-tracker';

const rootLoading = globalLoading.start('root');
const fontsLoading = globalLoading.start('fonts');

(async () => {
  await Promise.all([
    document.fonts.load('bold 16px "Politica"'),
    document.fonts.load('16px "Politica"'),
  ]);
  fontsLoading.end();
})();

onMounted(() => {
  nextTick(() => rootLoading.end());
});

</script>
