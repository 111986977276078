export declare type ExternalizedPromise<T> = Promise<T> & { isDone: boolean; resolve:(val:T) => void, reject: (err:any) => void };

export const getExternalizedPromise = <T>():ExternalizedPromise<T> => {
  let resolve, reject = null;
  let isDone = false;
  const promise = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  }).then(
    x => {
      isDone = true;
      return x;
    },
    err => {
      isDone = true;
      return Promise.reject(err);
    },
  );
  return Object.assign(promise, {
    isDone,
    resolve,
    reject,
  });
};
