
<style lang="less">
.day-post-graph {

  .day-post-graph-title {
    font-size: 1.4rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
</style>

<template>
  <div class="day-post-graph" :style="{ height: `${clientContext.postGraphHeight}px` }">
    <div class="day-post-graph-title" :style="{ height: `${clientContext.postGraphHeight * 0.5}px` }">
      <span style="margin-right: 5px;">{{ condition.description }}</span>
      <mdi-icon :icon="condition.icon" :size="1.5" />
    </div>
    <day-stat-row :cells="cells" :style="{ height: `${clientContext.postGraphHeight * 0.5}px` }" />
  </div>
</template>

<script lang="ts" setup>
import MdiIcon from 'src/client/mdi-icon.vue';
import DayStatRow, { IStatCell } from 'src/screen/day-screen/day-stat-row.vue';
import { conditionCodeUtil } from 'src/report/types/condition-codes';
import { clientContext } from 'src/client/context';
import { clientFormatters } from 'src/utils/formatters.client';
import { reportUtils } from 'src/utils/report';

const props = defineProps<{ dateNumber: IDateNumber }>();

const days = [...clientContext.report.days, clientContext.report.calendarDay].filter(x => x);
const dayReport = days.find(d => d.dateNumber === props.dateNumber);
const condition = conditionCodeUtil.get(dayReport.conditionCode);

const cells:IStatCell[] = (() => {
  if (dayReport.daysFromToday === 0) {
    const c = clientContext.report.current;
    return [
      { label: 'NOW', value: clientFormatters.temp(c.temp), big: true },
      //{ label: 'FEELS LIKE', value: clientFormatters.temp(c.tempFeel) },
      { label: 'LOW', value: clientFormatters.temp(dayReport.tempLow) },
      { label: 'HIGH', value: clientFormatters.temp(dayReport.tempHigh) },
      { label: reportUtils.precipTypeToRainOrSnow(dayReport.precipType), value: Math.round(dayReport.precipChance * 100), valueSuffix: '%' },
    ];
  } else {
    return [
      { label: 'WIND', value: clientFormatters.windSpeed(dayReport.windSpeed), valueSuffix: clientFormatters.windUnits() },
      { label: 'LOW', value: clientFormatters.temp(dayReport.tempLow) },
      { label: 'HIGH', value: clientFormatters.temp(dayReport.tempHigh) },
      { label: reportUtils.precipTypeToRainOrSnow(dayReport.precipType), value: Math.round(dayReport.precipChance * 100), valueSuffix: '%' },
    ];
  }
})();

</script>
