import { clientContext } from './context';
import { reactive, watch } from 'vue';
import { runNativeAppOperation } from '../native/app-operations';
import { nativeService } from '../native/service';
import { setTimeoutAsync } from '../utils/timeout';

declare type IClientLocationServiceError = 'disabled' | 'denied' | 'unknown' | 'invalid' | 'timeout' | string;

async function fetchLocation ():ReturnType<typeof runNativeAppOperation> {
  if (nativeService.isConnected) {
    return runNativeAppOperation('get-location', { timeout: 10000 });
  } else {
    await setTimeoutAsync(2000);
    return { lat: 35.222569, lon: -97.439476 };
  }
}

class ClientLocationService {
  public latest:ILatLon = null;
  public isLoading = false;
  private error:IClientLocationServiceError = null;
  private watchInterval:NodeJS.Timeout = null;

  public get errorMessage():string {
    if (!this.error) return null;
    if (this.error === 'disabled') return `Location permissions are disabled for Weather Nerd.`;
    if (this.error === 'denied') return `Please enable location permissions in Settings > Weather Nerd`;
    if (this.error === 'unknown') return `An unknown error occurred.`;
    if (this.error === 'invalid') return `An invalid error occurred.`;
    if (this.error === 'timeout') return `Unable to load location. Please try again.`;
    return this.error;
  }

  constructor() {
    return reactive(this);
  }

  disable () {
    console.log('loc.disable');
    if (this.watchInterval) {
      clearInterval(this.watchInterval);
      this.watchInterval = null;
    }
  }

  private async updateIfNeeded () {
    const now = new Date().getTime();
    const cutoff = now + (1000 * 60 * 4);
    const isNeeded = !this.latest?.time || this.latest.time < cutoff;
    if (!isNeeded) return;

    if (this.isLoading) return;
    try {
      this.isLoading = true;
      const res = await fetchLocation();
      if (res?.lat && res?.lon) {
        this.error = null;
        this.latest = { lat: res.lat, lon: res.lon, time: Date.now() };
      } else {
        this.error = res?.error || 'Latest GPS position is missing.';
      }
    }finally{
      this.isLoading = false;
    }
  }

  async enable () {
    console.log('loc.enable');
    if (this.watchInterval) {
      clearInterval(this.watchInterval);
      this.watchInterval = null;
    }
    this.watchInterval = setInterval(() => void this.updateIfNeeded(), 1000 * 60 * 5);
    void this.updateIfNeeded();
  }
}

export let clientLocationService: ClientLocationService;

export const initClientLocation = () => {
  clientLocationService = new ClientLocationService();

  watch(() => clientContext.settings?.currentLocation?.id, () => {
    const doEnable = clientContext.settings?.currentLocation?.id === 'live';
    console.log(`loc.isLive = ${doEnable}`);
    void clientLocationService[doEnable ? 'enable' : 'disable']();
  }, { immediate: true });

  watch(() => clientLocationService.latest, () => {
    if (!clientLocationService.latest?.lon) return;
    for (const l of [clientContext.settings?.currentLocation, ...(clientContext.settings?.locations || [])]) {
      if (l?.id !== 'live') continue;
      l.ll = clientLocationService.latest;
    }
  }, { immediate: true, deep: true });
};
