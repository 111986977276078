

<template>
<screen :settings="{ screenIndex: 0 }">
  <screen-header title="The Next Hour" :subtitle="subtitle" />
  <hour-graph />
  <radar style="margin-top: 20px;" :style="{ height: `${clientContext.postGraphHeight - 40}px` }" />
  <div style="height: 100px"></div>
</screen>
</template>

<script lang="ts" setup>
import Screen from 'src/screen/screen.vue';
import ScreenHeader from 'src/screen/screen-header.vue';
import Radar from 'src/radar/radar.vue';
import { clientContext } from 'src/client/context';
import HourGraph from 'src/screen/hour-screen/hour-graph.vue';
import { reportUtils } from 'src/utils/report';

const subtitle = reportUtils.getNextHourSummary(clientContext.report);

</script>
