
<style lang="less">
.graph-details {
  overflow: hidden;
  .graph-details-head {
    .graph-details-time {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      margin: 0.3em auto 0.2em;
    }
  }
}
</style>

<template>
  <div class="graph-details" :style="{ height: `${clientContext.headerHeight}px` }">
    <div class="graph-details-head">
      <div class="graph-details-time">
        {{ timeStr }}<span class="superscript">{{ timeAmPmStr.toUpperCase() }}</span>
        {{ dayNameStr }}
      </div>
    </div>
    <day-stat-row :cells="cells" />
  </div>
</template>

<script setup lang="ts">
import { clientContext } from 'src/client/context';
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
const props = defineProps<{ time: number, day: IWeatherReportDay }>();

import { clientFormatters } from 'src/utils/formatters.client';
import { getInterpolator } from 'src/utils/interpolation';
import { conditionCodeUtil } from 'src/report/types/condition-codes';
import { reportUtils } from 'src/utils/report';
import DayStatRow, { IStatCell } from '@/screen/day-screen/day-stat-row.vue';
import _ from 'lodash';

const snappedTime = computed(() => {
  let dj = dayjs(new Date(props.time)).tz(clientContext.report.timezone).startOf('minute');
  dj = dj.minute(Math.round(dj.minute()/5) * 5);
  return dj.toDate().getTime();
});
const timeStr = computed(() => dayjs(new Date(snappedTime.value)).tz(clientContext.report.timezone).format('h:mm'));
const timeAmPmStr = computed(() => dayjs(new Date(snappedTime.value)).tz(clientContext.report.timezone).format('a'));
const dayNameStr = computed(() => {
  if (props.day.daysFromToday === 0) return `Today`;
  if (props.day.daysFromToday === -1) return `Yesterday`;
  if (props.day.daysFromToday === 1) return `Tomorrow`;
  return dayjs(Date.fromDateNum(props.day.dateNumber)).format('MMMM Do');
});

const bookendHours = computed<IWeatherReportDayHour[]>(() => {
  const prevHour = props.day.hours.findLast(h => h.time <= snappedTime.value);
  if (!prevHour) return [];
  const idx = props.day.hours.indexOf(prevHour);
  return [prevHour, props.day.hours[idx+1]].filter(x => x);
});


const conditionsStr = computed(() => {
  return conditionCodeUtil.get(props.day.conditionCode)?.description;
});
const conditionsIcon = computed(() => {
  return conditionCodeUtil.get(props.day.conditionCode)?.icon;
});
const getInterpolated = (getter: (h:IWeatherReportDayHour) => number):number => {
  const interpolator = getInterpolator(...bookendHours.value.map(getter).filter(x => typeof x === 'number'));
  const perc = (snappedTime.value - bookendHours.value[0].time) / (bookendHours.value[1].time - bookendHours.value[0].time);
  return interpolator(perc);
};

const getNearest = <T>(getter: (h:IWeatherReportDayHour) => T):T => {
  const vals = bookendHours.value.map(getter).filter(x => x !== null && x !== undefined);
  const minutes = new Date(snappedTime.value).getMinutes();
  return minutes >= 30 ? _.last(vals) : vals[0];
};

const cells = computed<IStatCell[]>(() => {
  return [
    { label: 'TEMP', value: clientFormatters.temp(getInterpolated(h => h.tempReal)) },
    { label: 'Feels Like', value: clientFormatters.temp(getInterpolated(h => h.tempFeel)) },
    { label: reportUtils.precipTypeToRainOrSnow(getNearest(h => h.precipType)), value: Math.round(getInterpolated(h => h.precipChance) * 100), valueSuffix: '%' },
    { label: 'WIND', value: clientFormatters.windSpeed(getInterpolated(h => h.windSpeed)), valueSuffix: clientFormatters.windUnits() },
  ];
});

</script>
