import { IBigGraphConfig, IBigGraphConfigBuilder, IBigGraphConfigBuilderArgs } from 'src/screen/big-graph/types';
import { clientContext } from 'src/client/context';
import { reportUtils } from 'src/utils/report';

export const getHourGraphConfigBuilder = ():IBigGraphConfigBuilder => {
  return (args:IBigGraphConfigBuilderArgs):IBigGraphConfig => {
    const nh = clientContext.report.nextHour;

    const config:IBigGraphConfig = {
      grid: {
        ys: [
          { perc: 0, label: '' },
          { perc: 1/3, label: 'Light' },
          { perc: 2/3, label: 'Medium' },
          { perc: 1, label: 'Heavy' },
        ],
        xs: [
          { perc: 1/6, label: '10 min' },
          { perc: 2/6, label: '20 min' },
          { perc: 3/6, label: '30 min' },
          { perc: 4/6, label: '40 min' },
          { perc: 5/6, label: '50 min' },
        ],
      },
    };

    config.rain = nh.minutes.map(m => ({
      chance: m.chance,
      type: nh.precipitationType,
      intensity: m.intensity,
    }));
    config.isCold = reportUtils.precipTypeIsCold(nh.precipitationType);


    return config;
  };
};
