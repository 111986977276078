
let lastTick = Date.now();
const tick = () => {
  const now = Date.now();
  if ((now - lastTick) > 5000) {
    console.log('sandbox.foreground');
  }
  lastTick = now;
  setTimeout(tick, 1000);
};

export const initSandbox = () => {
  console.log("initSandbox");

  tick();
};

