import { launchModal } from '../modal/service';
import Modal from './modal.vue';

export interface IPickerModalOption<T = string> {
  id: T;
  title: string;
}

export interface IPickerModalArgs {
  title: string;
  value: string;
  values: string[];
  multiple: boolean;
  options: IPickerModalOption[];
}

export interface ILaunchPickerModalArgs extends IPickerModalArgs {

}

export const launchPickerModal = async <T extends boolean>(args:ILaunchPickerModalArgs & { multiple: T }): Promise<T extends true ? string[] : string> => {
  await launchModal({
    component: Modal,
    props: { args },
  });
  const response = args.multiple ? args.values : args.value;
  return response;
};
