
<style lang="less" src="./radar.less" />

<template>
  <div class="radar">
    <div class="map-container" ref="divMapContainer">
      <div class="map" ref="divMap"></div>
      <div v-if="isOpen" @click="clickClose" class="btn-cancel btn-radar radar-fade-in" :style="{top: `${nativeService.context.safeAreaInsets?.top || 0}px`}"><mdi-icon icon="cancel" :size="2" /></div>
      <img class="radar-key" src="./radar-key.png" />
      <div class="radar-footer radar-fade-in" v-if="isOpen">
        <div @click="clickSettings" class="btn-settings btn-radar"><mdi-icon icon="settings" :size="1.5" /></div>
        <template v-if="timeRef">
          <div v-if="clientContext.settings.radarIsPlaying" class="radar-timeline">
            <div class="radar-timeline-marker" :style="{left: `${progressRef * 100}%`}"></div>
          </div>
          <div v-else style="flex: 1;"></div>
          <div class="radar-time">{{ timeRef }}</div>
          <div @click="clickPlayPause" class="btn-play-pause btn-radar"><mdi-icon :icon="clientContext.settings.radarIsPlaying ? 'pause_circle' : 'play_circle' " :size="2" /></div>
        </template>
      </div>
    </div>
    <div v-if="!isOpen" @click="clickOpen" class="click-interceptor"></div>
    <radar-settings v-if="isSettingsOpen" @close="isSettingsOpen = false" />
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, ref } from 'vue';
import MdiIcon from 'src/client/mdi-icon.vue';
import { setupMapLoader } from 'src/radar/load';
import { setupRadarOpenClose } from 'src/radar/open-close';
import { clientContext } from 'src/client/context';
import RadarSettings from 'src/radar/radar-settings.vue';
import { nativeService } from '@/native/service';

const isOpen = ref<boolean>(false);
const isSettingsOpen = ref<boolean>(false);
const divMap = ref<HTMLDivElement>(null);
const divMapContainer = ref<HTMLDivElement>(null);
const mapRef = ref<mapboxgl.Map>(null);
const timeRef = ref<string>(null);
const progressRef = ref<number>(1);

const mapLoader = setupMapLoader({
  divMap, isOpen, progressRef, timeRef, mapRef: mapRef as any
});

const { clickOpen, clickClose } = setupRadarOpenClose({
  divMapContainer, isOpen, mapRef: mapRef as any,
});

const clickSettings = () => {
  isSettingsOpen.value = true;
};

const clickPlayPause = () => {
  clientContext.settings.radarIsPlaying = !clientContext.settings.radarIsPlaying;
};

onBeforeUnmount(() => {
  mapLoader?.destroy();
});

</script>
