
export const filterInPlace = <T>(array:T[], condition:(item:T, index: number, array:T[])=>boolean):T[] => {
  let i = 0;
  let origIndex = 0;

  while (i < array.length) {
    const val = array[i];
    const keep = condition(val, origIndex, array);
    if (!keep) {
      array.splice(i, 1);
    } else {
      i++;
    }
    origIndex++;
  }

  return array;
};
