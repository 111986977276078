

export const blockMapboxEvents = () => {
  const originalFetch = window.fetch;

  window.fetch = function(req) {
    if (req?.url?.includes?.('events.mapbox.com')) {
      return new Promise(() => {}); // Block the request by returning a pending promise
    }
    return originalFetch.apply(this, arguments);
  };
};
