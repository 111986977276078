import { clientContext } from 'src/client/context';
import { Ref, watch } from 'vue';
import mapboxgl from 'mapbox-gl';
import { buildMapboxOverlay } from 'src/radar/overlay';

mapboxgl.accessToken = "pk.eyJ1Ijoiam9zaGF0ZmlybWFwcCIsImEiOiJjbTBqbmtpdTIwemhlMmtvYWo0ZjM3aDhlIn0.J2Q6nUeF-fqE-WBjrQBSIA";

const getMapboxStyle = () => {
  return clientContext.settings.radarMapStyle === 'streets'
    ? "mapbox://styles/mapbox/streets-v10"
    : "mapbox://styles/mapbox/satellite-streets-v12";
};

export interface IBuildMapboxMapArgs {
  divMap:HTMLDivElement;
  isOpen:Ref<boolean>;
  timeRef: Ref<string>;
  progressRef: Ref<number>;
}

export interface IBuildMapboxMapResponse {
  map: mapboxgl.Map;
  destroy: () => void;
}

export const buildMapboxMap = (args:IBuildMapboxMapArgs):IBuildMapboxMapResponse => {
  const map = new mapboxgl.Map({
    center: clientContext.settings.currentLocation.ll,
    style: getMapboxStyle(),
    container: args.divMap,
    zoom: 8,
  });

  const overlay = buildMapboxOverlay(map, args);

  const destroy = () => {
    overlay.destroy();
  };

  return { map, destroy };
};
