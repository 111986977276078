
import { Directive } from 'vue';

const vCollapse:Directive<HTMLDivElement, null> = {
  beforeMount(el: HTMLElement) {

  },
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    console.log(`mounted`, el, binding.value);
    if (!binding.value) {
      el.style.display = 'none';
    }
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    console.log(`updated`, el, binding.value);
    const didChange = !!binding.value !== !!binding.oldValue;
    if (!didChange) return;

    clearTimeout(el._collapseTimeout);

    const ms = 300;
    const show = !!binding.value;
    el.style.willChange = 'height';
    el.style.transition = null;
    el.style.maxHeight = null;
    el.style.display = null;
    const fullHeight = el.getBoundingClientRect().height;
    const startHeight = show ? 0 : fullHeight;
    const endHeight = show ? fullHeight : 0;
    el.style.maxHeight = `${startHeight}px`;
    el.style.transition = `max-height ${ms}ms ease`;
    el.style.overflow = 'hidden';

    setTimeout(() => {
      el.style.maxHeight = `${endHeight}px`;
    }, 1);
    el._collapseTimeout = setTimeout(() => {
      el.style.willChange = null;
      el.style.transition = null;
      el.style.display = show ? null : 'none';
      el.style.maxHeight = null;
      el.style.overflow = null;
      el._collapseTimeout = null;
    }, ms);
  },
  beforeUnmount(el: HTMLElement) {
    console.log(`beforeUnmount`, el, binding.value);
    el.style.display = null;
    el.style.transition = null;
    el.style.willChange = null;
    el.style.maxHeight = null;
    clearTimeout(el._collapseTimeout);
  }
};

export default vCollapse;
