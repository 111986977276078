import { inject, markRaw, ref } from 'vue';
import { getExternalizedPromise } from '../../utils/promise';
import { randomUtil } from '../../utils/random';

export const modalConfigs = ref<IModalConfig[]>([]);

export const useModalService = () => {
  return inject<IModalService>('modal-service');
};

export const launchModal = async <T>(args:ILaunchModalArgs<T>):Promise<T> => {
  markRaw(args.component);

  const config:IModalConfig<T> = {
    id: randomUtil.alphaNumericLowercase(20),
    promise: getExternalizedPromise<T>(),
    ...args,
  };
  modalConfigs.value = [...modalConfigs.value, config];
  const result = await config.promise;
  modalConfigs.value = modalConfigs.value.filter(c => c.id !== config.id);
  return result;
};
