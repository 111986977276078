import _ from 'lodash';
import { clientContext } from '../client/context';

const isTouchScreen = 'ontouchend' in window || navigator.maxTouchPoints > 0;

export const setupScreenScrollSnap = (el:HTMLDivElement) => {
  let didScroll = false;
  const onComplete = () => {
    if (!didScroll) return;
    didScroll = false;

    const scrollTop = el.scrollTop;

    const stops = {
      locations: clientContext.pullToRefreshHeight,
      report: clientContext.pullToRefreshHeight + clientContext.locationsHeight,
    }
    const stopPoints = [stops.locations, stops.report].map(top => ({
      top,
      distance: Math.abs(top - scrollTop),
    }));
    const nearestStop = _.minBy(stopPoints, stop => stop.distance);

    if (scrollTop <= 0) {
      setTimeout(() => {
        el.scrollTo({ top: stops.report, behavior: 'smooth' });
        clientContext.latestReloadRequestTime = Date.now();
      }, 1500);
      return;
    }

    if (nearestStop.distance > 40) return;
    el.scrollTo({ top: nearestStop.top, behavior: 'smooth' });
  };

  if (isTouchScreen) {
    el.addEventListener('scroll', () => { didScroll = true; });
    el.addEventListener('touchcancel', onComplete);
    el.addEventListener('touchend', onComplete);
    el.addEventListener('mouseup', onComplete);
  }else{
    el.addEventListener('scroll', _.debounce(() => {
      didScroll = true;
      onComplete();
    }, 100));
  }
};
