
<template>
  <div>
    <div class="locations-screen-subheading">
      MY LOCATION
    </div>
    <div v-for="l of clientContext.settings.locations.filter(x => x?.id === 'live')"
        :key="l.id"
        :class="{active: l.id === clientContext.settings.currentLocation?.id}"
        class="locations-screen-item"
        @click="clickLocation(l)"
    >
      <mdi-icon v-if="l.id === clientContext.settings.currentLocation?.id" icon="chevron_right" :size="0.7" />
      <div class="title">
        <mdi-icon icon="near_me" />
        {{ l.title }}
      </div>
    </div>
    <div class="locations-screen-subheading" v-if="savedLocations.length">
      FAVORITES
    </div>
    <div v-for="l of savedLocations"
        :key="l.id"
        :class="{active: l.id === clientContext.settings.currentLocation?.id}"
        class="locations-screen-item"
        @click="clickLocation(l)"
    >
      <mdi-icon v-if="l.id === clientContext.settings.currentLocation?.id" icon="chevron_right" :size="0.7" />
      <div class="title">{{ l.title }}</div>
      <div class="btn" @click.prevent.stop="clickEdit(l)"><mdi-icon icon="edit" /></div>
      <div class="btn" @click.prevent.stop="clickDelete(l)" :style="{visibility: l.id === clientContext.settings.currentLocation?.id ? 'hidden' : 'visible'}"><mdi-icon icon="delete" /></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clientContext } from '@/client/context';
import MdiIcon from '@/client/mdi-icon.vue';
import { filterInPlace } from '@/utils/array';
import { useModalService } from '@/client/modal/service';

const modal = useModalService();

const savedLocations = clientContext.settings.locations.filter(x => x && x?.id !== 'live');

const clickLocation = async (l:IUserLocationSettings) => {
  modal.close(l);
};

const clickEdit = async (l:IUserLocationSettings) => {
  const newTitle = prompt('Title', l.title);
  if (!newTitle) return;
  l.title = newTitle;
};

const clickDelete = async (l:IUserLocationSettings) => {
  const isSelected = clientContext.settings.currentLocation?.id === l.id;
  if (isSelected) return alert(`You can't delete "${l.title}" because it is currently selected.`);
  if (!confirm(`Delete ${l.title}?`)) return;
  filterInPlace(clientContext.settings.locations, loc => loc.id !== l.id);
};

</script>
