
<template>
  <component
      :is="props.config.component"
      v-bind="props.config.props"
  />
</template>

<script lang="ts" setup>
import { provide } from 'vue';

const props = defineProps<{ config: IModalConfig }>();

const service:IModalService = {
  close: (result) => {
    props.config.promise.resolve(result);
  },
};

provide('modal-service', service);

</script>
