import { onBeforeUnmount, onMounted, Ref } from 'vue';
import tearImgUrl from './tear.png';

export interface IStartTearsArgs {
  refLogo: Ref<HTMLDivElement>;
}

let isOdd = false;
export const startTears = (args: IStartTearsArgs) => {

  const tearWidth = 20;
  let intervalId: number | null = null;

  const createTear = () => {
    const logo = args.refLogo.value;
    if (!logo) return;

    isOdd = !isOdd;

    const logoSize = { w: logo.clientWidth, h: logo.clientHeight };
    const pos = {
      x: logo.offsetLeft + logoSize.w * 0.5 - (tearWidth / 2) + (logoSize.w * 0.2  * (isOdd ? -1 : 1)),
      y: logo.offsetTop + logoSize.h * 0.85,
    };
    const tear = document.createElement('img');
    tear.classList.add('expired-tear');
    tear.src = tearImgUrl;
    tear.style.position = 'absolute';
    tear.style.width = `${tearWidth}px`;
    tear.style.left = `${pos.x}px`;
    tear.style.top = `${pos.y}px`;
    tear.style.zIndex = '2';
    document.body.appendChild(tear);

    let opacity = 0;
    let velocity = 0;
    const gravity = 0.02;

    const fall = () => {
      opacity = Math.min(opacity + 0.01, 1);
      velocity += gravity;
      pos.y += velocity;
      tear.style.top = `${pos.y}px`;
      tear.style.opacity = `${opacity}`;

      if (pos.y > window.innerHeight) {
        tear.remove();
        cancelAnimationFrame(animationId);
      } else {
        animationId = requestAnimationFrame(fall);
      }
    };

    let animationId = requestAnimationFrame(fall);
  };

  onMounted(() => {
    intervalId = setInterval(createTear, 1000);
  });

  onBeforeUnmount(() => {
    if (intervalId) clearInterval(intervalId);
  });
};
