import { launchModal } from '../../client/modal/service';
import Modal from './modal.vue';
import { filterInPlace } from '../../utils/array';
import { clientContext } from '../../client/context';

export const launchLocationsModal = async ():Promise<IUserLocationSettings> => {
  const l = await launchModal<IUserLocationSettings>({
    component: Modal,
    props: {},
  });

  if (!l) return;
  filterInPlace(clientContext.settings.locations, x => x.id !== l.id);
  clientContext.settings.locations.push(l);
  clientContext.settings.currentLocation = l;
  return l;
};
