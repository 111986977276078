
<style lang="less" scoped>
.settings-picker-screen {
  background-color: #333;
  padding-bottom: 50px;

  .settings-picker-item {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 0 14px;
    border-bottom: solid 1px #444;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;

    &:hover {
      background-color: #444;
    }

    .settings-picker-item-label {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      flex: 1;
    }
  }
}
</style>

<template>
  <wn-modal :settings="modalSettings" v-slot="{ modal }">
    <div class="settings-picker-screen">
      <div class="settings-picker-item"
          v-for="option of props.args.options"
          @click="clickOption(option)"
          :key="option.id">
        <div class="settings-picker-item-label">{{ option.title }}</div>
        <mdi-icon v-if="isSelected(option)" :icon="'check'" style="color: #3cf;" />
      </div>
    </div>
  </wn-modal>
</template>

<script lang="ts" setup>
import WnModal from '@/client/modal/wn-modal.vue';
import { useModalService } from '@/client/modal/service';
import MdiIcon from '@/client/mdi-icon.vue';
import { IPickerModalArgs, IPickerModalOption } from '@/client/picker/util';
import { filterInPlace } from '@/utils/array';

const props = defineProps<{ args: IPickerModalArgs }>();

const modal = useModalService();
const modalSettings:IModalSettings = {
  bgColor: '#333',
  direction: 'right',
  onClickAbove () {
    modal.close();
  },
  title: props.args.title,
  leftButton: {
    label: 'Back',
    click () { modal.close(); },
  },
};

const clickOption = (option:IPickerModalOption):void => {
  if (props.args.multiple) {
    const wasSelected = isSelected(option);
    filterInPlace(props.args.values, v => v !== option.id);
    if (!wasSelected) props.args.values.push(option.id);
  }else{
    props.args.value = option.id;
    modal.close();
  }
};

const isSelected = (option:IPickerModalOption):boolean => {
  const vals = props.args.multiple ? props.args.values : [props.args.value];
  return vals.includes(option.id);
};

</script>
