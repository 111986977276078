import { capitalize } from 'src/utils/strings';
import { findBiggestChange } from 'src/utils/weighted';
import { clientFormatters } from 'src/utils/formatters.client';
import dayjs from 'dayjs';
import _ from 'lodash';


export const reportUtils = {
  tempToPrecipType (temp:number):IPrecipitationType {
    if (temp > 2) return 'rain';
    if (temp > -1)  return 'sleet';
    return 'snow';
  },
  precipTypeToRainOrSnow (type:IPrecipitationType):string {
    return type === 'snow' ? 'snow' : 'rain';
  },
  precipTypeIsCold (type:IPrecipitationType):boolean {
    return ['snow'].includes(type);
  },
  getNextHourSummary(report:IWeatherReport):string {
    const nh = report.nextHour;
    if (!nh.minutes?.length) return '';
    const rainOrSnow = reportUtils.precipTypeToRainOrSnow(nh.precipitationType);
    const gradeMinute = (chance:number) => {
      if (chance === 0) return null;
      if (chance < 0.1) return rainOrSnow === 'snow' ? 'flurries' : 'drizzle';
      if (chance < 0.3) return `light ${rainOrSnow}`;
      if (chance < 0.65) return `moderate ${rainOrSnow}`;
      return `heavy ${rainOrSnow}`;
    };
    const change = findBiggestChange(nh.minutes.map(m => m.chance), 5);
    if (change?.delta) {
      const m1 = nh.minutes[change.startIndex];
      const m2 = nh.minutes[change.endIndex];
      const g1 = gradeMinute(m1.chance);
      const g2 = gradeMinute(m2.chance);
      if (g1 === g2) return '';
      const numMinutes = Math.ceil((change.startIndex + change.endIndex) / 2) + 1;
      if (g2) return `${capitalize(g2)} starting in ${numMinutes} minute${numMinutes === 1 ? '' : 's'}.`;
      if (g1) return `${capitalize(g1)} stopping in ${numMinutes} minute${numMinutes === 1 ? '' : 's'}.`;
    }

    const grade = gradeMinute(nh.minutes[0].chance);
    if (grade) return `${capitalize(grade)} for at least an hour.`;

    return `No ${rainOrSnow} for the next hour.`;
  },
  getWeekSummary(report:IWeatherReport):string {
    const days = report.week.days;
    if (!days?.length || days.length < 2) return;
    const today = days[0];
    const rest = days.slice(1);
    const tempSummary = (() => {
      const maxHighDay = _.maxBy(rest, d => d.tempHigh);
      const minHighDay = _.minBy(rest, d => d.tempHigh);
      if (maxHighDay.tempHigh > today.tempHigh) return `Temperatures top out at ${clientFormatters.temp(maxHighDay.tempHigh)} on ${dayjs(Date.fromDateNum(maxHighDay.dateNumber)).format('dddd')}.`;
      if (minHighDay.tempHigh < today.tempHigh) return `Temperatures drop to ${clientFormatters.temp(minHighDay.tempHigh)} on ${dayjs(Date.fromDateNum(minHighDay.dateNumber)).format('dddd')}.`;
      return `Highs from ${clientFormatters.temp(minHighDay.tempHigh)} ${dayjs(Date.fromDateNum(minHighDay.dateNumber)).format('dddd')} to ${clientFormatters.temp(maxHighDay.tempHigh)} ${dayjs(Date.fromDateNum(maxHighDay.dateNumber)).format('dddd')}.`
    })();
    const precipSummary = (() => {
      const rainOrSnow = reportUtils.precipTypeToRainOrSnow(today.precipType);
      const gradeDay = (chance:number) => {
        if (chance <= 0.1) return 'low';
        if (chance < 0.4) return `medium`;
        return `high`;
      };
      const grades = rest.map(day => ({ day, grade: gradeDay(day.precipChance) }));
      const groups = _.chain(grades)
        .groupBy(g => g.grade)
        .values()
        .map(gs => {
          return {
            grade: gs[0].grade,
            days: gs.length,
            start: gs[0].day,
            end: _.last(gs).day,
          };
        })
        .value();
      const todayGrade = gradeDay(today);
      const rainGroups = groups.filter(g => g.grade === 'high');
      if (rainGroups.length > 1) return `${capitalize(rainOrSnow)} off and on through ${dayjs(Date.fromDateNum(_.last(rainGroups).end.dateNumber)).format('dddd')}.`
      const firstRainGroup = _.first(rainGroups);
      if (firstRainGroup) {
        if (firstRainGroup.start.daysFromToday === 1) {
          if (todayGrade === 'medium' || todayGrade === 'high') {
            return `${capitalize(rainOrSnow)} through ${dayjs(Date.fromDateNum(firstRainGroup.end.dateNumber)).format('dddd')}.`
          }
        }
        if (firstRainGroup.days === 1) {
          return `${capitalize(rainOrSnow)} on ${dayjs(Date.fromDateNum(firstRainGroup.start.dateNumber)).format('dddd')}.`
        }else{
          return `${capitalize(rainOrSnow)} from ${dayjs(Date.fromDateNum(firstRainGroup.start.dateNumber)).format('dddd')} through ${dayjs(Date.fromDateNum(firstRainGroup.end.dateNumber)).format('dddd')}.`
        }
      }
      const mediumGroups = groups.filter(g => g.grade === 'medium');
      if (mediumGroups.length > 1) {
        return `Slight chance of ${rainOrSnow.toLowerCase()} off and on through ${dayjs(Date.fromDateNum(_.last(mediumGroups).end.dateNumber)).format('dddd')}.`
      }
      if (mediumGroups.length) {
        const mediumGroup = mediumGroups[0];
        if (todayGrade === 'medium' && mediumGroup.start.daysFromToday === 1) {
          return `Slight chance of ${rainOrSnow.toLowerCase()} through ${dayjs(Date.fromDateNum(mediumGroup.end.dateNumber)).format('dddd')}.`
        }else if (mediumGroup.days > 1){
          return `Slight chance of ${rainOrSnow.toLowerCase()} from ${dayjs(Date.fromDateNum(mediumGroup.start.dateNumber)).format('dddd')} through ${dayjs(Date.fromDateNum(mediumGroup.end.dateNumber)).format('dddd')}.`
        }else{
          return `Slight chance of ${rainOrSnow.toLowerCase()} on ${dayjs(Date.fromDateNum(mediumGroup.start.dateNumber)).format('dddd')}.`
        }
      }
      const isZero = !rest.find(r => r.precipChance > 0);
      if (isZero) {
        return `No chance of ${rainOrSnow.toLowerCase()} through ${dayjs(Date.fromDateNum(_.last(days).dateNumber)).format('dddd')}.`
      }
      return `${capitalize(rainOrSnow)} is unlikely.`
    })();

    return [tempSummary, precipSummary].filter(x => x).join(' ');
  },
};
