

export const tryParse = <T>(json:string, _default?: T):T => {
  try {
    return JSON.parse(json) as T;
  }catch(e){
    return _default || null;
  }
};

export const jsonClone = <T>(obj:T):T => {
  return JSON.parse(JSON.stringify(obj));
};
