<style lang="less">
.device {
  position: absolute;
  overflow:hidden;
  color: #fff;
  user-select: none;
  transition: opacity 500ms linear;

  .device-body {
    position: relative;
    overflow:hidden;
    transition: transform 300ms ease-in-out;

    &.shrunk {
      transform: scale(0.95);
      border-radius: 15px;
    }
  }
}
</style>

<template>
  <div class="device" :style="deviceStyle">
    <registration v-if="!clientContext.settings.registrationTime" />
    <div class="device-body" :style="sizeStyle" v-else>
      <theme />
      <status-bar />
      <screens />
    </div>
  </div>
  <modal-anchor />
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted } from 'vue';
import Theme from 'src/theme/theme.vue';
import { clientContext } from 'src/client/context';
import StatusBar from 'src/device/status-bar.vue';
import Screens from 'src/device/screens.vue';
import ModalAnchor from 'src/client/modal/anchor.vue';
import { startReportLoader } from '@/client/reloader';
import { globalLoading } from '@/client/loading-tracker';
import Registration from '@/registration/registration.vue';

void startReportLoader();

const loading = globalLoading.start('device.render');

const sizeStyle = computed(() => {
  return {
    width: `${clientContext.screenWidth}px`,
    height: `${clientContext.screenHeight}px`,
  };
});

const deviceStyle = computed(() => {
  return {
    opacity: globalLoading.isLoading ? 0.01 : 1,
    ...sizeStyle.value,
  };
});

onMounted(() => {
  nextTick(() => loading.end());
});

</script>
