import { reactive } from 'vue';
import _ from 'lodash';

export const win = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
});

const throttledResize = _.throttle(() => {
  win.width = window.innerWidth;
  win.height = window.innerHeight;
}, 50);

window.addEventListener('resize', throttledResize);
