
<style lang="less">
.week-days {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-column-gap: 4px;
  margin: 0 6px;
}
</style>

<template>
<screen :settings="{ screenIndex: 3 }">
  <screen-header title="Upcoming Week" :subtitle="subtitle" />
  <div class="week-days" ref="refDays" :style="{minHeight: `${visibleHeight}px`}">
    <week-day v-for="day of clientContext.report.week.days" :week="clientContext.report.week" :day="day" />
  </div>
</screen>
</template>

<script lang="ts" setup>
import Screen from 'src/screen/screen.vue';
import ScreenHeader from 'src/screen/screen-header.vue';
import { reportUtils } from 'src/utils/report';
import { clientContext } from 'src/client/context';
import WeekDay from '@/screen/week-screen/week-day.vue';
import { onMounted, ref } from 'vue';

const visibleHeight = clientContext.bodyHeight - clientContext.headerHeight;
const subtitle = reportUtils.getWeekSummary(clientContext.report);

const refDays = ref<HTMLDivElement>();

const adjustHeights = () => {
  const days = refDays.value;
  if (!days) return;

  // Reset heights
  for (const day of days.children) {
    for (const cell of day.children) {
      cell.style.flex = null;
    }
  }

  // calculate scale
  const scaler = (() => {
    let y = 0;
    for (const cell of Array.from(days.children[0].children)) {
      if ((y + cell.clientHeight) > visibleHeight) {
        return visibleHeight / y;
      }
      y += cell.clientHeight;
    }
  })();
  if (!scaler) return;

  const firstDay = days.children[0];
  const heights = Array.from(firstDay.children).map(cell => Math.round(cell.clientHeight * scaler));
  for (const day of days.children) {
    for (const [i, cell] of Array.from(day.children).entries()) {
      const newHeight = heights[i];
      cell.style.flex = `0 0 ${newHeight}px`;
    }
  }
};

onMounted(adjustHeights);

</script>
