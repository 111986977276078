
export function calcWeightedAverages(arr:number[], n:number):number[] {
  const result = [];
  const length = arr.length;

  for (let i = 0; i < length; i++) {
    let sum = 0;
    let totalWeight = 0;

    for (let j = Math.max(0, i - n); j <= Math.min(length - 1, i + n); j++) {
      const weight = n + 1 - Math.abs(i - j);
      sum += arr[j] * weight;
      totalWeight += weight;
    }

    result.push(sum / totalWeight);
  }

  return result;
}


export interface IBiggestChange {
  startIndex: number; endIndex: number, delta: number;
}

export function findBiggestChange(arr:number[], maxDistance: number):IBiggestChange {
  let big:IBiggestChange = null;

  for (let startIndex = 0; startIndex < (arr.length - 1); startIndex++) {
    for (let endIndex = startIndex + 1; endIndex <= Math.min(startIndex + maxDistance, arr.length - 1); endIndex++) {
      const delta = arr[startIndex] - arr[endIndex];
      if (!big || Math.abs(big?.delta) < Math.abs(delta)) {
        big = { startIndex, endIndex, delta };
      }
    }
  }

  if (!big?.delta) return null;

  return big;
}
