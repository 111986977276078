
export interface IDemoReceiptUtilGenerateArgs {
  status: IReceiptStatus;
}

export const demoReceiptUtil = {
  generate (args:IDemoReceiptUtilGenerateArgs):string {
    const json = JSON.stringify(args.status);
    return `demo-`+Array.from(new TextEncoder().encode(json))
      .map(b => b.toString(16).padStart(2,'0'))
      .join('');
  },
  parse (receipt:string):IReceiptStatus {
    try {
      if (!receipt?.startsWith('demo-')) {
        return;
      }
      const hex = receipt.substring(5);
      const json = Buffer.from(hex, 'hex').toString('utf8');
      return JSON.parse(json);
    }catch(err) {
      console.log(`Failed to parse receipt: ${err}`);
      return null;
    }
  },
};