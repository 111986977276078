import { IBigGraphConfig } from 'src/screen/big-graph/types';
import { clamp } from 'src/utils/math';
import fanPng from './fan.png';
import * as PIXI from 'pixi.js';


export const drawGraphWindFans = (pixiApp: PIXI.Application, config:IBigGraphConfig) => {
  const winds = config.wind;
  if (!winds?.length) return;

  const size = { w: pixiApp.renderer.width, h: pixiApp.renderer.height };
  const updaters:(() => void)[] = [];
  const container = new PIXI.Container();
  pixiApp.stage.addChild(container);

  winds.forEach((wind, i) => {
    const fan = PIXI.Sprite.from(fanPng);
    fan.anchor.set(0.5);
    const speedPerc = clamp((wind.speed / 40), 0.02, 0.97);
    fan.scale = 0.3;
    fan.alpha = 0.5;

    const spin = 0.002 + speedPerc * 0.3;
    fan.x = (i / winds.length) * size.w;
    fan.y = (1 - speedPerc) * size.h;
    container.addChild(fan);
    updaters.push(() => {
      fan.rotation += spin;
    });
  });

  pixiApp.ticker.add(() => {
    updaters.forEach(updater => updater());
  });
};

