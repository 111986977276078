import { clientContext } from './context';
import { clientApi } from './api';
import { inject, nextTick, watch } from 'vue';
import _ from 'lodash';
import { LoadingTracker } from './loading-tracker';
import { jsonClone } from '../utils/json';
import { generateJSONPatch } from 'generate-json-patch';
import { nativeService } from '../native/service';


let expiredTimeout:NodeJS.Timeout;
const loading = new LoadingTracker('report');

export const startReportLoader = async () => {
  loading.restart();
  void reloadNowIfNotLoading();
  watchSettings();
  watchRequestTime();
};

const _reloadNow = async () => {
  try {
    console.log('reloader.reloadNow.start...');
    clearTimeout(expiredTimeout);
    loading.restart();
    clientContext.reportError = null;

    await new Promise(resolve => setTimeout(resolve, 1000 * 2));
    //await new Promise(resolve => setTimeout(resolve, 1000 * 9999));

    if (!clientContext.settings.currentLocation) {
      throw new Error('First choose a location.');
    }

    if (!clientContext.settings.currentLocation?.ll?.lat) {
      throw new Error('Location has no GPS position.');
    }

    console.log('reloader.reloadNow.load...');
    const resAsync = clientApi.loadWeatherReport({
      timezone: clientContext.settings.timezone,
      countryCode: clientContext.settings.countryCode,
      langCode: clientContext.settings.langCode,
      ll: clientContext.settings.currentLocation.ll,
      calendarDateNumber: clientContext.settings.calendarDateNumber,
      receipt: nativeService.context.receipt,
    });

    const res = await resAsync;
    clientContext.report = res.report;
  }catch(e){
    console.log(`reloader.reloadNow.error... ${e}`);
    clientContext.report = null;
    clientContext.reportError = ('message' in e) ? e.message : String(e);
  }finally{
    console.log(`reloader.reloadNow.finally...`);
    expiredTimeout = setTimeout(() => void reloadSoon(), 1000 * 60 * 4);

    void nextTick(() => {
      setTimeout(() => loading.end(), 100);
    });
    console.log(`reloader.reloadNow.end...`);
  }
};

const reloadNowIfNotLoading = async () => {
  const doIt = !clientContext.isReportLoading;
  console.log(`reloader.reloadNowIfNotLoading... ${JSON.stringify({ doIt })}`);
  if (clientContext.isReportLoading) return;
  try {
    clientContext.isReportLoading = true;
    await _reloadNow();
  }finally{
    clientContext.isReportLoading = false;
  }
};

const _reloadSoon = _.debounce(reloadNowIfNotLoading, 10, { leading: false, trailing: true });
const reloadSoon = async () => {
  console.log(`reloader.reloadSoon...`);
  await _reloadSoon();
};

const watchRequestTime = () => {
  watch(() => clientContext.latestReloadRequestTime, () => {
    const rep = clientContext.report?.reportTime;
    const req = clientContext.latestReloadRequestTime;

    const doIt = (() => {
      if (!req) return false;

      // no report yet? Then maybe it's a retry attempt
      if (!rep) return true;

      // minimum wait between reload requests
      const ms = req - rep;
      const min = 1000 * 60 * 5;
      return ms >= min;
    })();

    console.log(`reloader.requested... ${JSON.stringify({ doIt })}`);
    if (!doIt) return;

    void reloadSoon();
  });
};

let prevState = null;
const watchSettings = () => {
  watch(() => [
    clientContext.theme,
    clientContext.settings.currentLocation?.ll?.lat,
    clientContext.settings.currentLocation?.ll?.lon,
    clientContext.settings.timezone,
    clientContext.settings.countryCode,
    clientContext.settings.showFullDay,
    clientContext.settings.distanceUnits,
    clientContext.settings.precipUnits,
    clientContext.settings.tempUnits,
    clientContext.settings.windUnits,
    clientContext.settings.langCode,
    nativeService.context.receipt,
  ], async () => {
    console.log('reloader.settingsChanged...');
    const newState = jsonClone({
      ...clientContext.settings,
      receipt: nativeService.context.receipt,
    });
    if (prevState) {
      const diff = generateJSONPatch(prevState, newState);
      if (diff.length) {
        console.log(`reloader.stateDiff: ${JSON.stringify(diff)}`);
      }else{
        console.log(`reloader.stateDiff (same)`);
        return;
      }
    }
    prevState = newState;
    await reloadSoon();
  });
};
