<style lang="less">
.screens-loading {
  position: absolute;
  inset: 0;

  svg#weather-nerd {
    width: 100%;
    height: 100%;

    #weather-nerd-u-ears, #weather-nerd-u-glasses {
      animation: glasses-pulse 1200ms infinite ease-in-out;
      transform-origin: center center;
      animation-delay: 100ms;
      animation-fill-mode: forwards;
      opacity: 1;
    }
    @keyframes glasses-pulse {
      0% { transform: scale(0.2) translateX(-50px) rotateX(90deg); opacity: 0; }
      40% { transform: scale(1.1); opacity: 1; }
      70% { transform: scale(1); opacity: 1; }
      100% { transform: scale(0.1) translateX(-50px) rotateX(90deg); opacity: 0; }
    }

    ellipse {
      animation: pulse 600ms infinite ease-in-out;
      animation-direction: alternate;
      transform-origin: center center;
    }
    #weather-nerd-u-cloud-1 { animation-delay: 200ms; }
    #weather-nerd-u-cloud-2 { animation-delay: 160ms; }
    #weather-nerd-u-cloud-3 { animation-delay: 120ms; }
    #weather-nerd-u-cloud-4 { animation-delay: 80ms; }
    #weather-nerd-u-cloud-5 { animation-delay: 40ms; }
    #weather-nerd-u-cloud-6 { animation-delay:   0ms; }
    @keyframes pulse {
      0% { transform: scale(0.04); cx: 800px; cy: 800px; }
      50% { transform: scale(1.2); }
      100% { transform: scale(1); }
    }
  }
}
</style>

<template>
  <div class="screens-loading" :style="style">
    <!-- https://app.svgator.com/editor#/89a1e8c22daf47c688b3cce65a19f4eb -->
    <svg id="weather-nerd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1600 1600" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" preserveAspectRatio="xMidYMid slice">
      <path id="weather-nerd-u-ears" d="M639.375473,762.690469c.18369-14.23632,11.74744-27.09494,21.95149-27.09494s11.30135.00114,16.81987.00114c3.67901,0,4.61612,2.77661,2.81132,8.32984c0,0-4.92878,15.57547-10.51642,18.84627-8.04323,4.7082-31.06626-.0823-31.06626-.0823v-.00001Zm289.71833.30849c-5.58765-3.2708-10.51642-18.84627-10.51642-18.84627-1.8048-5.55323-.86769-8.32984,2.81132-8.32984c5.51852,0,6.61581-.00114,16.81987-.00114s21.7678,12.85862,21.9515,27.09494c0,0-23.02303,4.79051-31.06626.0823l-.00001.00001Z" stroke-width="3.2"/>
      <ellipse id="weather-nerd-u-cloud-6" rx="84.85" ry="84.85" cx="757.37" cy="751.76" fill="#fff" stroke-width="0"/>
      <ellipse id="weather-nerd-u-cloud-5" rx="69.924" ry="69.924" cx="854.698828" cy="764.662898" fill="#fff" stroke-width="0"/>
      <ellipse id="weather-nerd-u-cloud-4" rx="69.925" ry="69.925" cx="900.792087" cy="803.423138" fill="#fff" stroke-width="0"/>
      <ellipse id="weather-nerd-u-cloud-3" rx="64.11" ry="64.11" cx="843.175516" cy="852.659116" fill="#fff" stroke-width="0"/>
      <ellipse id="weather-nerd-u-cloud-2" rx="72.35" ry="72.35" cx="749.989" cy="856.944562" fill="#fff" stroke-width="0"/>
      <ellipse id="weather-nerd-u-cloud-1" rx="73.4" ry="73.4" cx="694.467578" cy="809.80373" fill="#fff" stroke-width="0"/>
      <path id="weather-nerd-u-glasses" d="M835.65166,742.52590c17.49985,0,90.37835.29249,101.49284.29249c19.01163,0,23.10644,12.86941,23.10644,19.88909-.00003,21.10975-6.72719,64.05456-8.18962,72.82916s-12.86941,23.10644-22.81395,23.10644-84.52862.29249-87.74598.29249-10.72822-2.49799-15.82614-14.08416c-7.19043-16.68982-14.1512-39.96227-16.76174-68.38433-.03862-.42047-.06994-.84051-.09435-1.25967h-18.10277c-.02441.41916-.05573.8392-.09435,1.25967-2.61054,28.42206-9.57131,51.69451-16.76174,68.38433-5.09792,11.58617-12.60879,14.08416-15.82614,14.08416s-77.80144-.29249-87.74598-.29249-21.35152-14.33184-22.81395-23.10644-8.18959-51.71941-8.18962-72.82916c0-7.01968,4.09481-19.88909,23.10644-19.88909c11.11449,0,83.99299-.29249,101.49284-.29249c14.86181,0,20.92096,8.46267,22.41002,11.0141h26.94773c1.48906-2.55143,7.54821-11.0141,22.41002-11.0141Zm-3.67428,38.05431c.91703,9.17034,2.44543,17.27082,4.12665,25.98264s5.95051,20.10169,7.93741,23.92267s4.03831,7.40935,9.69336,7.40935s56.98042,0,64.62238,0s13.09339-8.47183,13.92085-16.1288c.69669-6.44688,4.19397-34.62517,4.19397-42.18335s-6.51223-15.20344-15.23659-15.20344-69.84747-.00001-75.04399-.00001-15.13107,7.0306-14.21403,16.20094h-.00001Zm-64.41923,0c.91704-9.17034-9.0175-16.20094-14.21403-16.20094s-66.31963.00001-75.04399.00001-15.23659,7.64526-15.23659,15.20344s3.49728,35.73647,4.19397,42.18335c.82746,7.65697,6.2789,16.1288,13.92085,16.1288s58.96733,0,64.62238,0s7.70645-3.58837,9.69336-7.40935s6.25618-15.21084,7.93741-23.92267s3.20962-16.8123,4.12665-25.98264h-.00001Z" stroke-width="3.2"/>
    </svg>

  </div>
</template>

<script setup lang="ts">
import { clientContext } from '@/client/context';
import Screen from '@/screen/screen.vue';
import { computed } from 'vue';

const style = computed(() => {
  return {
    minHeight: `${clientContext.screenHeight - clientContext.statusBarHeight }px`,
  };
});

</script>
