import { Ref, watch } from 'vue';
import { clientContext } from 'src/client/context';
import mapboxgl from 'mapbox-gl';


export interface ISetupMapboxLocationMarkersArgs {
  isOpen: Ref<boolean>;
  map: mapboxgl.Map;
}

export interface IMapboxLocations {
  destroy: () => void;
}

const diameter = 20;
const thickness = 1.5;
const color = '#000';

export const setupMapboxLocationMarkers = (args: ISetupMapboxLocationMarkersArgs):IMapboxLocations => {
  let myEl:HTMLDivElement = null;
  const themEls:HTMLDivElement[] = [];
  const markers:mapboxgl.Marker[] = [];

  for (const loc of clientContext.settings.locations) {
    if (!loc?.ll?.lat || !loc?.ll?.lon) continue;

    const el = document.createElement('div');
    const isMe = loc.id === clientContext.settings.currentLocation.id;
    if (isMe) {
      myEl = el;
    }else{
      themEls.push(el);
    }
    // el.style.border = `solid 1px #000`;
    // el.style.borderRadius = `${(diameter + 2)/2}px`;
    el.style.transition = `opacity 500ms ease-in-out`;
    el.style.width = `${diameter + 2}px`;
    el.style.height = `${diameter + 2}px`;

    const h = document.createElement('div');
    h.style.position = 'absolute';
    h.style.top = `${(diameter - thickness) / 2}px`;
    h.style.width = `${diameter}px`;
    h.style.height = `${thickness}px`;
    h.style.backgroundColor = color;
    h.style.boxShadow = '0 0 10px 2px #fff';
    el.appendChild(h);

    const v = document.createElement('div');
    v.style.position = 'absolute';
    v.style.left = `${(diameter - thickness) / 2}px`;
    v.style.height = `${diameter}px`;
    v.style.width = `${thickness}px`;
    v.style.backgroundColor = color;
    v.style.boxShadow = '0 0 10px 2px #fff';
    el.appendChild(v);

    const h2 = document.createElement('div');
    h2.style.position = 'absolute';
    h2.style.top = `${(diameter - thickness) / 2}px`;
    h2.style.width = `${diameter}px`;
    h2.style.height = `${thickness}px`;
    h2.style.backgroundColor = color;
    el.appendChild(h2);

    const marker = new mapboxgl.Marker(el)
      .setLngLat(loc.ll)
      .addTo(args.map);
    markers.push(marker);
  }

  watch(() => [
    clientContext.settings.radarShowLocation,
    clientContext.settings.radarShowOtherLocations,
    args.isOpen.value,
  ], () => {
    const showMe = args.isOpen.value && clientContext.settings.radarShowLocation;
    if (myEl) myEl.style.opacity = showMe ? '1' : '0';

    const showThem = args.isOpen.value && clientContext.settings.radarShowOtherLocations;
    for (const el of themEls) {
      el.style.opacity = showThem ? '1' : '0';
    }
  }, { immediate: true });

  return {
    destroy(){
      for (const marker of markers) {
        marker.remove();
      }
    },
  };
};
