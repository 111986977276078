import { win } from 'src/client/window';

export declare type INativeAppEvent = 'shake';

export class INativeContext {
  language: ILangCode;
  appStoreVersion: string;
  isDebug: boolean;
  receipt?: string;
  resolutionInPixels: { width: number; height: number };
  resolutionInPoints: { width: number; height: number };
  safeAreaInsets: { left: number; top: number; bottom: number; right: number };
  deviceModel: string;

  static demo ():INativeContext {
    return {
      language: 'en-US',
      appStoreVersion: '0.0.0',
      isDebug: false,
      receipt: null,
      resolutionInPixels: { width: Math.round(win.height * (9/19.5)) * 2, height: win.height * 2 },
      resolutionInPoints: { width: Math.round(win.height * (9/19.5)), height: win.height },
      safeAreaInsets: { top: 0, left: 0, right: 0, bottom: 0 },
      deviceModel: 'browser',
    }
  }
}
