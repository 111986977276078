
declare global {
  interface IPoint {
    x: number;
    y: number;
  }

  interface IBezierPoint {
    c1: IPoint;
    c2: IPoint;
    p: IPoint;
  }

  interface IBezierLine {
    start: IPoint;
    points: IBezierPoint[];
  }
}

function getControlPoints(p0: IPoint, p1: IPoint, p2: IPoint, p3: IPoint, tension: number = 0.0): [IPoint, IPoint] {
  const t01 = Math.pow(distance(p0, p1), tension);
  const t12 = Math.pow(distance(p1, p2), tension);
  const t23 = Math.pow(distance(p2, p3), tension);

  const m1 = {
    x: (1 - tension) * (p2.x - p1.x + t12 * ((p1.x - p0.x) / t01 - (p2.x - p0.x) / (t01 + t12))),
    y: (1 - tension) * (p2.y - p1.y + t12 * ((p1.y - p0.y) / t01 - (p2.y - p0.y) / (t01 + t12)))
  };

  const m2 = {
    x: (1 - tension) * (p2.x - p1.x + t12 * ((p3.x - p2.x) / t23 - (p3.x - p1.x) / (t12 + t23))),
    y: (1 - tension) * (p2.y - p1.y + t12 * ((p3.y - p2.y) / t23 - (p3.y - p1.y) / (t12 + t23)))
  };

  const c1 = {
    x: p1.x + m1.x / 3,
    y: p1.y + m1.y / 3
  };

  const c2 = {
    x: p2.x - m2.x / 3,
    y: p2.y - m2.y / 3
  };

  return [c1, c2];
}

function distance(p1: IPoint, p2: IPoint): number {
  const dx = p2.x - p1.x;
  const dy = p2.y - p1.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function convertPointsToBezierUsingCatmulRom(points: IPoint[]): IBezierLine {
  if (points.length < 2) {
    throw new Error("At least two points are required to create a Bézier curve.");
  }

  const result: IBezierLine = {
    start: points[0],
    points: []
  };

  if (points.length === 2) {
    result.points.push({
      c1: points[0],
      c2: points[1],
      p: points[1]
    });
    return result;
  }

  const n = points.length;

  // Add virtual start and end points
  const startPoint = { x: 2 * points[0].x - points[1].x, y: 2 * points[0].y - points[1].y };
  const endPoint = { x: 2 * points[n - 1].x - points[n - 2].x, y: 2 * points[n - 1].y - points[n - 2].y };

  const allPoints = [startPoint, ...points, endPoint];

  for (let i = 0; i < n - 1; i++) {
    const [c1, c2] = getControlPoints(allPoints[i], allPoints[i + 1], allPoints[i + 2], allPoints[i + 3]);
    result.points.push({
      c1,
      c2,
      p: points[i + 1]
    });
  }

  return result;
}
