
import 'src/globals/client/init';
import './debugger/log-collector';
import { initClientDebugger } from './debugger/init';
import { blockMapboxEvents } from 'src/client/block-mapbox-events';
import { createApp } from 'vue';
import Root from '../root/root.vue';
import { initClientNative } from 'src/native/service';
import { vFitDirective } from 'src/utils/v-fit';
import vCollapse from './v-collapse.js';
import { initClientLocation } from './location';
import { initSandbox } from './sandbox';

initClientDebugger();
blockMapboxEvents();
initClientNative();
initClientLocation();
initSandbox();

const app = createApp(Root);

app.directive('fit', vFitDirective);
app.directive('if-collapse', vCollapse);

app.mount('body');
