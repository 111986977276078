import { clientContext } from 'src/client/context';
import dayjs from 'dayjs';
import { allDeclarations } from './declarations';

export const clientFormatters = {
  temp(temp:number):string {
    if (typeof temp !== 'number') return;
    if (clientContext.settings.tempUnits === 'fahrenheit') {
      return `${Math.round(temp * (9/5) + 32)}°`;
    }else{
      return `${Math.round(temp)}°`;
    }
  },
  windDirection(degree:number):string {
    const dirs = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
    return dirs[Math.round(degree / 22.5) % 16];
  },
  windSpeed(mps: number):string {
    return Math.round(allDeclarations.windUnits.get(clientContext.settings.windUnits).fromKmph(mps)).toString();
  },
  windUnits():string {
    return allDeclarations.windUnits.get(clientContext.settings.windUnits)?.suffix;
  },
  date(time: number, format: string):string {
    return dayjs(time).tz(clientContext.settings.timezone).format(format);
  },
  timeString(time: number):string {
    return clientFormatters.date(time, 'h:mmA');
  },
  moonPhase(moonPhase: number):string {
    if (typeof moonPhase !== 'number') return;
    if (moonPhase > 0.5) moonPhase = moonPhase - 0.5;
    return `${Math.round(moonPhase * 200)}%`;
  },
  precipChance(chance:number):string {
    let perc = Math.round(chance * 100);
    perc = Math.round(perc / 5) * 5;
    return `${perc}%`;
  },
  precipTypeToMoistureType(type:IPrecipitationType, tempCelcius?: number):IMoistureType {
    if (!type || type === 'clear' || type === 'precipitation') {
      if (typeof tempCelcius === 'number') {
        return tempCelcius < 0 ? 'snow' : 'rain';
      }
      return 'rain';
    }
    if (type === 'mixed') return 'sleet';
    return type;
  },
};
