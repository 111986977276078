
<template>
  <big-graph :builder="builder" />
</template>

<script lang="ts" setup>
import { getHourGraphConfigBuilder } from 'src/screen/hour-screen/build-graph';
import BigGraph from 'src/screen/big-graph/big-graph.vue';

const builder = getHourGraphConfigBuilder();

</script>
