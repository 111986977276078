
declare global {
  interface IListItem<TId> {
    id: TId;
    title: string;
  }

  interface IList<TItems extends readonly IListItem<any>[]> {
    all: TItems;
    get: (id: TItems[number]['id']) => TItems[number] | undefined;
  }

  type ExtractListItemType<TList> = TList extends IList<infer TItems> ? TItems[number] : null;
  type ExtractListItemId<TList> = TList extends IList<infer TItems> ? TItems[number]['id'] : null;
}

export const declareList = <const TItems extends readonly IListItem<any>[]>(all: TItems): IList<TItems> => {
  return {
    all,
    get(id) {
      return all.find(a => a.id === id);
    },
  };
};

// const boolList = declareList([
//   { id: 'on', title: 'On', foo: 'bar' },
//   { id: 'off', title: 'Off', foo: 'buzz' },
// ] as const);
//
// declare type IBoolListType = ExtractListItemType<typeof boolList>;
// declare type IBoolListId = ExtractListItemId<typeof boolList>;
//
// //boolList.all[0].
// boolList.get('').f
