<style lang="less">
.wn-modal-bg {
  position: absolute;
  z-index: 2;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  animation: modal-bg-in 300ms ease-out;
  animation-fill-mode: forwards;

  &.is-being-removed {
    animation: modal-bg-out 300ms ease-in;
    animation-fill-mode: forwards;
  }
}
@keyframes modal-bg-in {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes modal-bg-out {
  from { opacity: 1; }
  to { opacity: 0; }
}

.wn-modal {
  position: fixed;
  inset: 0;
  z-index: 3;
  font-family: "SF Pro Display", "Helvetica", "sans-serif";
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  .modal-nav {
    flex: 0 0 50px;
    background-color: #111111;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0;
    padding: 6px 15px;
    color: #ffffff;
    border-top: solid 2px rgba(255, 255, 255, 0.1);

    .modal-nav-title {
      flex: 1;
      text-transform: uppercase;
      font-size:1em;
      opacity: 0.2;
    }

    .btn {
      padding: 6px 9px;
      border-radius: 10px;
      font-size: 0.7em;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      border: solid 2px rgba(255, 255, 255, 0.2);
      align-self: stretch;
      opacity: 0.5;
      text-transform: uppercase;

      &:hover { opacity: 1; }
    }
  }

  .modal-body {
    background-color: #111111;
    position: relative;
    overflow-y: scroll;
    flex-grow: 1;
    flex-shrink: 1;
  }


  &.direction-bottom {
    animation-name: modal-in-bottom;
    &.is-being-removed {
      animation-name: modal-out-bottom;
    }
  }

  &.direction-right {
    animation-name: modal-in-right;
    &.is-being-removed {
      animation-name: modal-out-right;
    }
  }

  &.is-being-removed {
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
  }
}

@keyframes modal-in-bottom {
  from { transform: translateY(100%); }
  to { transform: translateY(0); }
}

@keyframes modal-out-bottom {
  from { transform: translateY(0%); }
  to { transform: translateY(100%); }
}

@keyframes modal-in-right {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

@keyframes modal-out-right {
  from { transform: translateX(0); }
  to { transform: translateX(100%); }
}
</style>

<template>
  <div class="wn-modal-bg"
      :style="deviceStyle"
      :class="$attrs.class"
  ></div>
  <div class="wn-modal"
      :style="deviceStyle"
      :class="[$attrs.class, `direction-${props.settings.direction || 'bottom'}`]">
    <div @click="props.settings.onClickAbove" :style="{ flex: `0 0 ${clientContext.statusBarHeight}px` }"></div>
    <div class="modal-nav">
      <div class="btn" v-if="props.settings.leftButton" @click="props.settings.leftButton.click">{{ props.settings.leftButton.label }}</div>
      <div class="modal-nav-title" :style="{textAlign:settings.leftButton ? 'center' : 'left'}">{{ props.settings.title }}</div>
      <div class="btn" v-if="props.settings.rightButton" @click="props.settings.rightButton.click">{{ props.settings.rightButton.label }}</div>
    </div>
    <div class="modal-body" :style="{ backgroundColor: props.settings.bgColor }">
      <slot name="default" :modal="modal"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clientContext } from '@/client/context';
import { useModalService } from '@/client/modal/service';
import { computed } from 'vue';

const props = defineProps<{ settings: IModalSettings }>();
const modal = useModalService();

const deviceStyle = computed(() => {
  return {
    width: `${clientContext.screenWidth}px`,
    height: `${clientContext.screenHeight}px`,
  };
});

</script>
