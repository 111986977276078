
<style lang="less" scoped>
.settings-screen {
  background-color: #28282A;
  padding-bottom: 50px;


  .settings-category-title {
    padding: 40px 14px 10px;
    border-bottom: solid 1px #444;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    background-color: #28282A;
  }

  .settings-item {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 0 14px;
    border-bottom: solid 1px #444449;
    transition: background-color 150ms ease-in-out;
    cursor: pointer;
    background-color: #333336;

    &:hover {
      background-color: #444;
    }

    .settings-item-label {
      font-weight: bold;
      font-size: 16px;
      color: #fff;
      flex: 1;
    }

    .settings-item-value {
      font-size: 15px;
      color: #3af;
      flex: 1;
      text-align: right;
      font-weight: 400;
    }
  }
}
</style>

<template>
  <wn-modal :settings="modalSettings" v-slot="{ modal }">
    <div class="settings-screen">
      <div class="settings-category" v-for="category of categories">
        <div class="settings-category-title">{{ category.title }}</div>
        <div class="settings-item" v-for="option of category.options" @click="clickOption(option)">
          <div class="settings-item-label">{{ option.title }}</div>
          <div class="settings-item-value">
            {{ option.value }}
            <mdi-icon v-if="option.click" :icon="'chevron_right'" size="1.7" style="opacity: 0.5;" />
          </div>
        </div>
      </div>
    </div>
  </wn-modal>
</template>

<script lang="ts" setup>
import WnModal from '@/client/modal/wn-modal.vue';
import { useModalService } from '@/client/modal/service';
import MdiIcon from '@/client/mdi-icon.vue';
import { getSettingsOptions } from '@/settings/modal/options';
import { computed } from 'vue';
import _ from 'lodash';

const modal = useModalService();
const modalSettings:IModalSettings = {
  bgColor: '#28282a',
  direction: 'bottom',
  onClickAbove () {
    modal.close();
  },
  title: 'Settings',
  rightButton: {
    label: 'Done',
    click () { modal.close(); },
  },
  leftButton: null,
};

const options = getSettingsOptions();
const categories = computed(() => {
  return _.chain(options)
      .groupBy(o => o.category)
      .values()
      .map(ops => ({
        title: ops[0].category,
        options: ops,
      }))
      .value();
});

const clickOption = async (option:ISettingsOption) => {
  await option.click?.();
};

</script>
