import { reactive } from 'vue';


declare global {
  interface ISack<T> {
    get value ():T;
    set value (val:T);
  }
}

export const sackFor = <T>(val:T):ISack<T> => {
  return reactive({ value: val });
};

export const sackSynced = <TObj, TKey extends keyof TObj>(obj:TObj, key:TKey):ISack<TObj[TKey]> => {
  return {
    get value () {
      return obj[key];
    },
    set value (v) {
      obj[key] = v;
    },
  }
};
