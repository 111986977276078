import { clientLogCollector } from './log-collector';
import { nativeService } from '../../native/service';
import { launchModal } from '../modal/service';
import Modal from './modal.vue';

export const initClientDebugger = () => {
  setTimeout(() => {
    nativeService.onNative('shake', showClientDebugger);
  }, 50);
};

let isShowingModal = false;
export const showClientDebugger = async () => {
  if (isShowingModal) return;

  try {
    isShowingModal = true;
    await launchModal({
      component: Modal,
      props: { collector: clientLogCollector },
    });
  }finally {
    isShowingModal = false;
  }
};