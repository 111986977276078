
<style lang="less">
.precip-block {
  background-color: rgba(0, 204, 255, 0.5);
  position:absolute;
  inset: 0 0 0 0;
  overflow: hidden;

  &.snow {
    background-color: rgba(255, 255, 255, 0.5);
  }

  > .drop {
    position: absolute;
    bottom: 100%;
    animation-name: drop;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    &.rain {
      width: 1px;
      height: 8px;
      background-color: rgba(255, 255, 255, 0.5);
    }
    &.snow {
      background-image: url('../big-graph/snowflake.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 12px;
      width: 12px;
    }
    &.hide {
      display: none;
    }
  }
}

@keyframes drop {
  0% { bottom: 100%; }
  100% { bottom: -8px; }
}
</style>

<template>
  <div class="precip-block" :class="moistureType">
    <div v-for="n of 5" class="drop" :class="getDropClass(n)" :style="{
      left: `${Math.round(((n-1) / 5) * 100)}%`,
      'animation-duration': `${400 + Math.random() * 200}ms`,
    }"></div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{ moistureType: IMoistureType }>();

const getDropClass = (n:number):string => {
  if (props.moistureType === 'rain') return 'rain';
  if (props.moistureType === 'hail') return 'rain';
  if (props.moistureType === 'snow') return n % 2 === 1 ? 'snow' : 'hide';
  return n % 2 === 0 ? 'rain' : 'snow';
};
</script>