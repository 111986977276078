
<style lang="less">
.screen {
  scroll-snap-align: start;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-grow: 0;
  flex-shrink: 0;
  transition: opacity 300ms ease-in-out;

  .pull-to-refresh {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      opacity: 0.4;
    }

    &.spin > svg {
      animation: spin 1500ms infinite linear;
    }
    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  }
}
</style>

<template>
  <div class="screen hide-scrollbar" ref="refScreen" :style="screenStyle">
    <div :style="{ height: `${clientContext.statusBarHeight}px` }"></div>
    <div class="pull-to-refresh" :class="{spin: pullToRefreshPercVisible === 1 }"
        :style="{ height: `${clientContext.pullToRefreshHeight}px` }">
      <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24"><g><circle cx="3" cy="12" r="2" fill="currentColor"/><circle cx="21" cy="12" r="2" fill="currentColor"/><circle cx="12" cy="21" r="2" fill="currentColor"/><circle cx="12" cy="3" r="2" fill="currentColor"/><circle cx="5.64" cy="5.64" r="2" fill="currentColor"/><circle cx="18.36" cy="18.36" r="2" fill="currentColor"/><circle cx="5.64" cy="18.36" r="2" fill="currentColor"/><circle cx="18.36" cy="5.64" r="2" fill="currentColor"/></g></svg>
    </div>
    <screen-locations
        v-if="!props.settings?.hideLocations"
        :style="{ opacity: isLocationsVisible ? 1 : 0.07  }"
         />
    <div class="screen-body">
      <slot />
    </div>
    <div :style="{ height: `${clientContext.footerBarHeight}px` }"></div>
  </div>
</template>

<script setup lang="ts">
import { clientContext } from 'src/client/context';
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import ScreenLocations from 'src/screen/screen-locations.vue';
import { provide } from 'vue';
import _ from 'lodash';
import { globalLoading, LoadingTracker } from '@/client/loading-tracker';
import { setupScreenScrollSnap } from '@/screen/screen-scroll-snap';
import { clamp } from '@/utils/math';

export interface IScreenSettings {
  hideLocations?: boolean;
  screenIndex: number;
}

const props = defineProps<{ settings: IScreenSettings }>();

const loadingTracker = new LoadingTracker('screen');
if (props.settings?.screenIndex === clientContext.pageIndex) {
  globalLoading.append(loadingTracker);
}

const refScreen = ref<HTMLDivElement>(null);
const latestScrollTop = ref<number>(0);
const updateLatestScrollTop = () => {
  if (refScreen.value) latestScrollTop.value = refScreen.value.scrollTop;
}
const pullToRefreshPercVisible = computed<number>(() => {
  const top = latestScrollTop.value;
  const perc = 1- clamp(top / clientContext.pullToRefreshHeight, 0, 1);
  return perc;
}); // 0-1

const screenStyle = computed(() => {
  return {
    width: `${clientContext.screenWidth}px`,
    height: `${clientContext.screenHeight}px`,
  };
});

const forceHideLocations = ref(true);
onMounted(() => setTimeout(() => { forceHideLocations.value = false; }, 500));
const isLocationsVisible = computed<boolean>(() => !forceHideLocations.value && latestScrollTop.value < clientContext.locationsHeight);

const resetScrollTop = () => {
  const screen:HTMLDivElement = refScreen.value;
  if (!screen) return;
  screen.scrollTop = clientContext.locationsHeight + clientContext.pullToRefreshHeight;
  screen.dispatchEvent(new Event('scroll'));
};

onMounted(() => {
  const screen:HTMLDivElement = refScreen.value;
  if (!screen) return;
  resetScrollTop();
  screen.addEventListener('scroll', _.throttle(updateLatestScrollTop, 10));
  updateLatestScrollTop();
  setupScreenScrollSnap(screen);

  watch(() => clientContext.report, resetScrollTop);

  nextTick(() => loadingTracker.end());
});

</script>
