
<style lang="less">
.screens-register {
  position: absolute;
  inset: 0 0 0 0;
  display: flex;
  flex-direction: column;

  .reg-body {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .reg-body-content {
      width: 280px;
      flex: 1;
      text-align: center;

      .reg-body-section {
        transition: all 1000ms ease-in-out;
        top: 0;
        height: 33.33%;
        overflow: hidden;
      }

      .reg-body-heading {
        color:#fff;
        font-weight: bold;
        font-size: 1.8em;
        text-align:center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .img-wn-logo {
        display: flex;
        align-items: start;
        justify-content: center;
        position: relative;
        transition: top 300ms ease;

        .img-wn-logo-inner {
          width: 100%;
          max-width: 240px;
        }
      }

      .reg-body-btns {
        justify-content: end;
        display: flex;
        flex-direction: column;
      }

      .btn-my-location, .btn-search-location {
        font-weight: bold;
        font-size: 16pt;
        text-align: center;
        background-color: #fff;
        padding: 8px 0;
        border-radius: 8px;
        display: block;
        cursor: pointer;
        transition:transform 150ms ease-in-out;
        margin-bottom: 20px;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
</style>


<template>
  <div class="screens-register">
    <div class="reg-body">
      <div class="reg-body-content">
        <div class="reg-body-section reg-body-heading" :style="{ height: loc ? 0 : null, opacity: loc ? 0 : 1 }">
          <div style="font-size: 0.8em;">GREETINGS</div>
          <div>WEATHER NERD</div>
        </div>
        <div class="reg-body-section img-wn-logo" :style="{ top: loc ? '40px' : null }">
          <div class="img-wn-logo-inner">
            <wn-logo ref="refLogo" />
          </div>
        </div>
        <div class="reg-body-section reg-body-heading" :style="{ height: loc ? null : 0 }">
          <div style="font-size: 0.8em;">
            GREAT!<br /><br />
            NOW CHOOSE A SUBSCRIPTION...
          </div>
        </div>
        <div class="reg-body-section reg-body-btns" :style="{ height: loc ? 0 : null, opacity: loc ? 0 : 1 }">
          <div class="btn-my-location theme-color" @click="clickMyLocation">
            <mdi-icon icon="near_me" />
            Use My Location
          </div>
          <div class="btn-search-location theme-color" @click="clickSearch">
            <mdi-icon icon="search" />
            Search Locations
          </div>
        </div>
        <div class="reg-body-section" :style="{ height: loc ? null : 0 }">
          <renewal-buttons @done="onRenewal" />
        </div>
      </div>
    </div>
    <theme />
  </div>
</template>

<script setup lang="ts">
import Theme from '@/theme/theme.vue';
import MdiIcon from '@/client/mdi-icon.vue';
import { launchLocationsModal } from '@/settings/locations-modal/util';
import { clientContext } from '@/client/context';
import { computed, ref } from 'vue';
import RenewalButtons from '@/expired/renewal/renewal-buttons.vue';
import WnLogo from '@/registration/wn-logo.vue';

const loc = computed<IUserLocationSettings>(() => clientContext.settings.currentLocation);
const refLogo = ref(null);

const clickMyLocation = async () => {
  clientContext.settings.currentLocation = clientContext.settings.locations.find(l => l.id === 'live');
};

const clickSearch = async () => {
  await launchLocationsModal();
};

const onRenewal = async (receipt:string) => {
 refLogo.value.run(() => {
    clientContext.settings.registrationTime = Date.now();
  });
};

</script>
