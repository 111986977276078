
<style lang="less">
.screen-locations {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  transition: opacity 200ms linear;
  padding-right: 30px;
  opacity: 0; // overriden by parent component

  > .btn {
    margin: 10px 0 10px 10px;
    padding: 0 10px 0 0;
    font-weight: bold;
    font-size:0.8rem;
    white-space: nowrap;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.btn-loc {
      padding: 10px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.active {
      background: white;
      color: black;
      mix-blend-mode: screen;
    }
  }
}
</style>


<template>
  <div class="screen-locations hide-scrollbar" :style="{ height: `${clientContext.locationsHeight}px` }">
    <div class="btn" @click="clickSettings"><mdi-icon icon="settings" :size="2" /></div>
    <div class="btn" @click="clickLocations"><mdi-icon icon="search" :size="2" /></div>
    <div v-for="l of clientContext.settings.locations"
        @click="clickLocation(l)"
        class="btn btn-loc"
        :class="{ active: l.id === clientContext.settings.currentLocation.id }"
        >
      <mdi-icon v-if="l.id === 'live'" icon="near_me" />
      <span v-else>{{ l.title }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clientContext } from 'src/client/context';
import MdiIcon from 'src/client/mdi-icon.vue';
import { launchSettingsModal } from '@/settings/modal/util';
import { launchLocationsModal } from '@/settings/locations-modal/util';


const clickLocation = async (loc:IUserLocationSettings) => {
  clientContext.settings.currentLocation = loc;
};

const clickLocations = async () => {
  void launchLocationsModal();
};

const clickSettings = async () => {
  void launchSettingsModal();
};

</script>
