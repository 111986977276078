
<style lang="less">
.big-graph {
  position: relative;

  .graph-body {
    position: absolute;
    inset: 0 0 32px 0;

    .animation {
      position: absolute;
      inset: 0 0 0 0;
    }

    .pixi-container, .plain-canvas {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
    }

    .now-line {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 1px;
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>

<template>
  <div class="big-graph"
      ref="refContainer"
      :style="{
        height: `${clientContext.graphHeight}px`,
      }">
    <graph-grid class="no-pointer-events" v-if="config?.grid" :params="config.grid" />
    <div class="graph-body">
      <div class="animation" ref="refAnimationDiv"></div>
      <div class="no-pointer-events pixi-container" ref="refPixiContainer"></div>
      <canvas class="no-pointer-events plain-canvas" ref="refPlainCanvas"></canvas>
      <div class="no-pointer-events now-line" v-if="typeof nowLinePerc === 'number'" :style="{ left: `${nowLinePerc * 100}%` }"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { clientContext } from 'src/client/context';
import GraphGrid from 'src/screen/big-graph/graph-grid.vue';
import { computed, inject, nextTick, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { IBigGraphConfig, IBigGraphConfigBuilder } from 'src/screen/big-graph/types';
import { buildRainOutline } from 'src/screen/big-graph/rain-outline';
import { buildRainDrops } from 'src/screen/big-graph/rain-drops';
import { drawTempsLine } from 'src/screen/big-graph/temps-line';
import { drawGraphWindFans } from 'src/screen/big-graph/wind';
import { setupNowLineDragging } from 'src/screen/big-graph/now-line';
import * as PIXI from 'pixi.js';
import snowflake from './snowflake.png';
import fan from './fan.png';

const refContainer = ref<HTMLDivElement>(null);
const refAnimationDiv = ref<HTMLDivElement>(null);
const refPixiContainer = ref<HTMLDivElement>(null);
const refPlainCanvas = ref<HTMLCanvasElement>(null);

const props = defineProps<{ builder: IBigGraphConfigBuilder }>();
const config = ref<IBigGraphConfig>(null);
const hasNowLine = computed<boolean>(() => !!config.value?.nowLine);
const nowLinePercOverride = ref<number>(null);
const nowLinePerc = computed<number>(() => nowLinePercOverride.value ?? config.value?.nowLine?.perc);
const emits = defineEmits(['onNowPerc', 'load']);

watch(() => nowLinePercOverride.value, () => {
  emits('onNowPerc', nowLinePercOverride.value);
});

let observer:IntersectionObserver = null;

onMounted(async () => {

  const dpr = window.devicePixelRatio || 1;
  const plainCanvas = refPlainCanvas.value;
  if (window.devicePixelRatio > 1) {
    const dpr = window.devicePixelRatio || 1;
    const width = plainCanvas.clientWidth;
    const height = plainCanvas.clientHeight;
    plainCanvas.width = width * dpr;
    plainCanvas.height = height * dpr;
    plainCanvas.style.width = `${width}px`;
    plainCanvas.style.height = `${height}px`;
  }
  plainCanvas.getContext('2d').font = `bold ${28 * dpr}px Politica`;

  // Create Pixi Application
  const pixiContainer:HTMLDivElement = refPixiContainer.value;
  pixiContainer.style.opacity = '0';
  const pixiApp = new PIXI.Application();
  await pixiApp.init({
    width: pixiContainer.clientWidth,
    height: pixiContainer.clientHeight,
    resolution: dpr,
    autoDensity: true,
    autoStart: true,
    backgroundAlpha: 0,
    antialias: true,
  });
  await PIXI.Assets.load(snowflake);
  await PIXI.Assets.load(fan);

  pixiContainer.appendChild(pixiApp.canvas);

  // Text setup using Pixi
  PIXI.TextStyle.prototype.default = {
    fontFamily: 'Politica',
    fontSize: 32 * dpr,
    fontWeight: 'bold',
    fill: 0xffffff,
  };

  // Custom setup based on props and config
  config.value = props.builder({
    app: pixiApp,
  });

  if (config.value.nowLine) {
    setupNowLineDragging({
      el: refAnimationDiv.value,
      nowLinePercOverride,
      nowLinePerc,
    });
  }

  // Replace `buildRainDrops` and `buildRainOutline` with equivalent Pixi.js logic
  const path = buildRainOutline(pixiApp, config.value);
  const drops = buildRainDrops(pixiApp, config.value);
  if (drops && path) {
    drops.mask = path;
  }

  // Replace with your custom drawing logic for wind fans and temperature lines
  drawGraphWindFans(pixiApp, config.value);
  if (config.value.prevTemps) drawTempsLine(plainCanvas, config.value.prevTemps, true);
  if (config.value.temps) drawTempsLine(plainCanvas, config.value.temps, false);

  pixiContainer.style.opacity = '1';

  observer = new IntersectionObserver((entries) => {
    const isVisible = entries[0].isIntersecting;
    pixiApp.ticker[isVisible ? 'start' : 'stop']();
  }, {
    root: document.querySelector('.device'),
    rootMargin: '-2px',
  });
  if (refContainer.value) observer.observe(refContainer.value);

  setTimeout(() => emits('load'), 10);
});

onBeforeUnmount(() => {
  observer?.disconnect();
});

</script>
