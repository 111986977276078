<style lang="less">
.renewal-buttons {
  position: relative;

  .renewal-buttons-status-text {
    text-transform: uppercase;
    font-size: 9pt;
    margin-top: 10px;
    opacity: 0.6;
  }

  .renewal-buttons-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .btn-renew {
    background-color: #FFF;
    border-radius: 10px;
    padding: 7px 10px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    transition: all 150ms ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-top: 8px;

    > .btn-renew-title {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .btn-renew-subtitle {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.7em;
      opacity: 0.5;
    }

    > .mdi-icon {
      border-left: dashed 1px #06f;
      flex: 0 0 min-content;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>

<template>
  <div class="renewal-buttons">
    <div v-if="isLoading" class="renewal-buttons-loading">
      <mdi-icon icon="progress_activity" spin />
    </div>
    <div :style="{ visibility: isLoading ? 'hidden' : 'visible' }">
      <div class="btn-renew theme-color" @click="btn.click" v-for="btn of btns" style="z-index: 3;">
        <div class="btn-renew-title">{{ btn.title }}</div>
        <div class="btn-renew-subtitle">{{ btn.subtitle }}</div>
        <mdi-icon icon="chevron_right" style="margin-left: 5px; font-size: 1.6em;" />
      </div>
      <div class="btn-renew theme-color" @click="clickRefresh" style="margin-top:20px; z-index: 1;">
        <div class="btn-renew-title">RESTORE PURCHASES</div>
        <mdi-icon icon="chevron_right" style="margin-left: 5px; font-size: 1.6em;" />
      </div>
      <div class="renewal-buttons-status-text" v-if="statusText">{{ statusText }}</div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import MdiIcon from '@/client/mdi-icon.vue';
import { nativeStoreKitService } from '@/native/native-store-kit-service';
import { clientApi } from '@/client/api';
import { nativeService } from '@/native/service';

const emits = defineEmits(['done']);
const btns = ref<{ title:string, subtitle: string, click: Function }[]>(null);
const isPurchasing = ref(false);
const isLoading = computed(() => !btns.value || isPurchasing.value);
const statusText = ref<string>('');

const reloadPrices = async () => {
  const res = await nativeStoreKitService.getProducts({ productIds: nativeStoreKitService.productIds.all });
  if (res.products?.length > 0) {
    btns.value = res.products.map(p => ({
      title: p.title,
      subtitle: [p.currencySymbol, p.price].join(''),
      click: () => clickProduct(p),
    }))
  }else{
    alert(`Failed to load subscription prices. Please try again. ${res.error}`);
    btns.value = [{
      title: 'Try Again',
      subtitle: '',
      click: () => reloadPrices(),
    }];
  }
};

async function attempt(func:() => Promise<void>) {
  try {
    isPurchasing.value = true;
    await func();
  } finally {
    isPurchasing.value = false;
  }
  await check();
}

async function check():Promise<boolean> {
  try {
    const status = await clientApi.validateReceipt({
      receipt: nativeService.context.receipt,
    });
    if (status?.status) {
      if (status?.status?.expiresOnMs) {
        statusText.value = `Expired: ${new Date(status?.status.expiresOnMs).toLocaleDateString()}`;
      }else{
        statusText.value = `Load previous purchases`;
      }
    }
    if (status?.status?.ok) {
      emits('done', nativeService.context.receipt);
    }
    return status.status.ok;
  } catch (error) {
    const msg = String(error?.message || error);
    alert(msg);
    statusText.value = `Error: ${msg.substring(0, 40).trim()}...`;
    return false;
  }
}

async function clickProduct(p: ISkProduct) {
  await attempt(async () => {
    await nativeStoreKitService.purchase({
      productId: p.identifier,
    });
  });
}

const clickRefresh = async () => {
  await attempt(async () => {
    await nativeStoreKitService.refreshReceipt({});
  });
};


onMounted(async () => {
  const ok = await check();
  if (ok) return;
  await reloadPrices();
});

</script>
