import { INativeHapticStrength } from 'src/native/haptic-service';
import { randomUtil } from 'src/utils/random';
import { ExternalizedPromise, getExternalizedPromise } from 'src/utils/promise';
import { filterInPlace } from 'src/utils/array';

declare global {
  interface ISkSubscriptionPeriod {
    unit: string;
    numberOfUnits: number;
  }

  interface ISkIntroductoryPrice {
    price: string;
    paymentMode: string;
    numberOfPeriods: number;
    subscriptionPeriod: ISkSubscriptionPeriod;
  }

  interface ISkProduct {
    identifier: string;
    title: string;
    description: string;
    price: string;
    currency?: string;
    currencySymbol?: string;
    subscriptionPeriod?: ISkSubscriptionPeriod;
    introductoryPrice?: ISkIntroductoryPrice;
  }
}

export type AppOperations = {
  'page-ready': { request: null, response: null, },
  'send-haptic-feedback': {
    request: { strength: INativeHapticStrength },
    response: null,
  },
  'kill': { request: null, response: null, },
  'request-review': { request: null, response: null, },
  'share-url': { request: { url: string; }, response: null },
  'open-url': { request: { url: string; }, response: null },
  'save-user-settings-json': { request: { json: string }, response: null },
  'is-loading': { request: {  isLoading: boolean }, response: null },
  'get-location': { request: {  timeout: number }, response: { error?: '', lat?: number, lon?: number } },
  'get-receipt': { request: null, response: { receipt?: string } },
  'refresh-receipt': { request: null, response: { receipt?: string } },
  'purchase': { request: {  productId: string }, response: { receipt?: string, success: boolean } },
  'get-products': { request: {  productIds: string[] }, response: { products?: ISkProduct[], error?: string } },
};

export declare type IAppOperationId = keyof AppOperations;

interface IPendingOperation {
  start: number,
  callbackId: string;
  operationId: IAppOperationId;
  promise: ExternalizedPromise<any>;
}

const pendingOps:IPendingOperation[] = [];

window['wn.native.callback'] = (args: { callbackId: string, data: any, error: string }) => {
  const op = pendingOps.find(o => o.callbackId === args.callbackId);
  console.log(`app-op:callback:${args.callbackId}, ${op ? 'FOUND' : 'LOST'}, ${args.error ? `ERROR: ${args.error}` : JSON.stringify(args.data)}`);
  if (!op) return;

  filterInPlace(pendingOps, o => o !== op);

  if (args.error) {
    op.promise.reject(args.error);
  }else{
    op.promise.resolve(args.data);
  }
  return 'success';
};

let counter = 0;
export const runNativeAppOperation = async <TId extends IAppOperationId>(operationId:TId, data?:AppOperations[TId]['request']):Promise<AppOperations[TId]['response']> => {
  try {
    counter += 1;
    const callbackId = `${operationId.toLowerCase().replace(/[^a-z0-9]/gi, '') || randomUtil.alphaNumericLowercase(20)}-${counter}`;

    const op:IPendingOperation = {
      start: Date.now(),
      callbackId,
      operationId,
      promise: getExternalizedPromise(),
    };
    pendingOps.push(op);

    setTimeout(() => {
      const wnTransport = window.webkit?.messageHandlers?.wnTransport;
      if (!wnTransport) {
        console.error(`...wnTransport is not found: ${JSON.stringify(op)}`);
        return;
      }
      console.log(`app-op:start:${callbackId}, ${JSON.stringify(data)}`);
      wnTransport.postMessage({ payload: JSON.stringify({ callbackId, operationId, data })});
    }, 1);

    return op.promise;
  } catch (e) {
    setTimeout(() => { throw e; }, 100);
  }
};
