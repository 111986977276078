import { clamp } from 'src/utils/math';
import { MonotonicCubicSpline } from 'src/utils/spline';

export function getInterpolator(...vals: number[]): (perc: number) => number {
  const numSegments = vals.length - 1;
  return (perc: number) => {
    perc = clamp(perc, 0, 1) * numSegments;
    const segmentIndex = Math.floor(perc);
    const segmentPerc = perc - segmentIndex;
    return vals[segmentIndex] + segmentPerc * (vals[segmentIndex + 1] - vals[segmentIndex]);
  };
}


export function getSplineInterpolator(...yValues: number[]) {
  const spline = new MonotonicCubicSpline(
    yValues.map((y, i) => i / (yValues.length - 1)),
    yValues,
  );
  return (x:number) => spline.interpolate(x);
}
