import _ from 'lodash';

export class VFitManager {
  private redrawSoon:(() => void);
  private mutationObs:MutationObserver = null;
  private resizeObs:ResizeObserver = null;
  private origSize:number = null;
  private fontSize:number = null;
  private el: HTMLElement = null;

  constructor(el: HTMLElement) {
    this.el = el;
    this.el.style.display = 'inline-block';
    this.redrawSoon = _.throttle(() => this.redraw(), 20);
    this.resizeObs = new ResizeObserver(() => this.redrawSoon());
    this.resizeObs.observe(el.parentElement);
    this.mutationObs = new MutationObserver(() => this.redrawSoon());
    this.mutationObs.observe(el, {
      childList: true,
      subtree: true,
    });
    const elStyle = getComputedStyle(el);
    this.origSize = parseFloat(elStyle.getPropertyValue('font-size').replace(/a-z/ig, '')) || 1;
    this.redraw();
  }

  redraw(){
    const doLog = this.el.innerText.includes('Wednesday, February 26th');
    const maxSize = { w: this.el.clientWidth, h: this.el.clientHeight };
    if (!maxSize.w) {
      if (doLog) console.log(`No max size`, this.el);
      return;
    }
    this.fontSize = 1;
    const log = (msg:string) => {
      if (!doLog) return;
      console.log(msg);
    };
    while (true) {
      if (this.fontSize <= 0.5) {
        this.el.style.overflow = 'hidden';
        this.el.style.textOverflow = 'ellipsis';
        break;
      }
      const size = { w: this.el.scrollWidth, h: this.el.scrollHeight };
      const ok = size.w <= maxSize.w && size.h <= maxSize.h;
      log(`${JSON.stringify({ maxSize, size, fontSize: this.fontSize, ok })}`);
      if (ok) {
        break;
      }
      this.fontSize = Math.max(this.fontSize * 0.95, 0.5);
      this.el.style.fontSize = `${(this.fontSize).toFixed(3)}em`;
    }
  }

  destroy () {
    this.resizeObs?.disconnect();
    this.mutationObs?.disconnect();
  }
}

// function isRelativish(position:string):boolean {
//   return position === 'relative' || position === 'absolute' || position === 'fixed' || position === 'sticky';
// }
