import { nativeService } from './service';
import { reactive, watch } from 'vue';
import _ from 'lodash';
import { UserSettings } from '../settings/types/user-settings';

class NativeRepoService {
  loadUserSettings():IUserSettings {
    console.log('settings.load...');
    const json = window['wn.native.settingsJson'] || localStorage.getItem('user-settings-json');
    console.log(`settings.load.native.typeof: ${typeof window['wn.native.settingsJson']}`);
    console.log(`settings.load.native: ${window['wn.native.settingsJson']}`);
    console.log(`settings.load.local: ${localStorage.getItem('user-settings-json')}`);
    console.log(`settings.load.json: ${json}`);
    let orig = {};
    try { orig = JSON.parse(json); }
    catch(e){ console.log(`Failed to parse user settings: ${json || '-'}`); }
    console.log(`settings.load.orig: ${JSON.stringify(orig)}`);
    const settings = reactive(UserSettings.init(orig || {}));
    console.log(`settings.load.final: ${JSON.stringify(settings)}`);
    autoSaveUserSettings(settings);
    return settings;
  }
  saveUserSettings(settings:IUserSettings):Promise<void> {
    const json = JSON.stringify(settings);
    console.log('settings.save...', json);
    localStorage.setItem('user-settings-json', JSON.stringify(settings));
    if (nativeService.isConnected) {
      return nativeService.run('save-user-settings-json', { json });
    }
  }
  async reset(){
    localStorage.removeItem('user-settings-json');
    await nativeService.run('save-user-settings-json', { json: '' });
    if (nativeService.isConnected) {
      await nativeService.run('kill', null);
    }else{
      location.reload();
    }
  }
}

export const nativeRepoService = new NativeRepoService();

const autoSaveUserSettings = (settings:IUserSettings) => {
  const saveNow = async () => {
    return nativeRepoService.saveUserSettings(settings);
  };
  const saveSoon = _.throttle(saveNow, 200, {
    leading: false,
    trailing: true,
  });
  watch(() => settings, saveSoon, { immediate: true, deep: true });
};
