import { clientContext } from 'src/client/context';
import { clientApi } from 'src/client/api';
import dayjs from 'dayjs';
import { clientRouter } from '../../client/router';

export const loadCalendarDayClient = async () => {
  clientContext.report.calendarDay = null;
  if (typeof clientContext.settings.calendarDateNumber !== 'number') return;
  clientContext.report.calendarDay = await clientApi.loadCalendarDay({
    calendarDateNumber: clientContext.settings.calendarDateNumber,
    countryCode: clientContext.settings.countryCode,
    langCode: clientContext.settings.langCode,
    ll: clientContext.settings.currentLocation.ll,
    timezone: clientContext.settings.timezone,
  });
  clientContext.report.calendarDay.daysFromToday = -dayjs().diff(dayjs(Date.fromDateNum(clientContext.settings.calendarDateNumber)), 'days');
  if (clientContext.report.calendarDay) {
    setTimeout(() => {
      clientRouter.goTo(4);
    }, 10);
  }
};
