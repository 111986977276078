
<style lang="less" scoped>
.locations-search {
  display: flex;
  flex-direction: row;
  align-items: center;

  input.txt {
    flex: 1;
    box-sizing: border-box;
    padding: 15px 15px;
    margin: 20px 0;
    color: #3cf;
    background-color: #000;
    border: solid 2px rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    outline: none;

    &::placeholder {
      font-weight: 200;
      font-size: 0.8em;
    }
  }

  .btn-clear {
    flex: 0 0 max-content;
    padding: 10px 15px;
    color: #fff;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="locations-search">
    <input v-focus
        autocomplete="off"
        data-1p-ignore
        name="City"
        class="txt"
        type="text"
        placeholder="Search for a city..."
        v-model="props.q.value"
    />
    <mdi-icon class="btn-clear" v-if="q.value" :size="2" icon="cancel" @click="props.q.value = ''" />
  </div>
</template>

<script lang="ts" setup>
import MdiIcon from '@/client/mdi-icon.vue';

const props = defineProps<{ q: ISack<string> }>();

const vFocus = {
  mounted: (el) => el.focus()
};
</script>
