// https://fonts.google.com/icons

import air from '@material-symbols/svg-400/outlined/air.svg';
import calendar_clock from '@material-symbols/svg-400/outlined/calendar_clock.svg';
import calendar_month from '@material-symbols/svg-400/outlined/calendar_month.svg';
import bar_chart from '@material-symbols/svg-400/outlined/bar_chart.svg';
import bug_report from '@material-symbols/svg-400/outlined/bug_report.svg';
import schedule from '@material-symbols/svg-400/outlined/schedule.svg';
import arrow_circle_down from '@material-symbols/svg-400/outlined/arrow_circle_down.svg';
import arrow_circle_right from '@material-symbols/svg-400/outlined/arrow_circle_right.svg';
import near_me from '@material-symbols/svg-400/outlined/near_me-fill.svg';
import check from '@material-symbols/svg-400/outlined/check.svg';
import chevron_right from '@material-symbols/svg-400/outlined/chevron_right.svg';
import cancel from '@material-symbols/svg-400/outlined/cancel.svg';
import settings from '@material-symbols/svg-400/outlined/settings-fill.svg';
import play_circle from '@material-symbols/svg-400/outlined/play_circle.svg';
import pause_circle from '@material-symbols/svg-400/outlined/pause_circle.svg';
import sunny from '@material-symbols/svg-400/outlined/sunny.svg';
import cloud from '@material-symbols/svg-400/outlined/cloud.svg';
import partly_cloudy_day from '@material-symbols/svg-400/outlined/partly_cloudy_day.svg';
import heat from '@material-symbols/svg-400/outlined/heat.svg';
import water_drop from '@material-symbols/svg-400/outlined/water_drop.svg';
import rainy from '@material-symbols/svg-400/outlined/rainy.svg';
import rainy_heavy from '@material-symbols/svg-400/outlined/rainy_heavy.svg';
import thunderstorm from '@material-symbols/svg-400/outlined/thunderstorm.svg';
import severe_cold from '@material-symbols/svg-400/outlined/severe_cold.svg';
import weather_hail from '@material-symbols/svg-400/outlined/weather_hail.svg';
import ac_unit from '@material-symbols/svg-400/outlined/ac_unit.svg';
import search from '@material-symbols/svg-400/outlined/search.svg';
import snowflake from '@material-symbols/svg-400/outlined/ac_unit.svg';
import rainy_snow from '@material-symbols/svg-400/outlined/rainy_snow.svg';
import storm from '@material-symbols/svg-400/outlined/storm.svg';
import edit from '@material-symbols/svg-400/outlined/edit.svg';
import error from '@material-symbols/svg-400/outlined/error.svg';
import delete_ from '@material-symbols/svg-400/outlined/delete.svg';
import progress_activity from '@material-symbols/svg-400/outlined/progress_activity.svg';

const all = {
  ac_unit, //wtf guys
  air,
  arrow_circle_down,
  arrow_circle_right,
  bar_chart,
  bug_report,
  calendar_clock,
  calendar_month,
  cancel,
  check,
  chevron_right,
  cloud,
  delete: delete_,
  edit,
  error,
  heat,
  near_me,
  partly_cloudy_day,
  pause_circle,
  play_circle,
  progress_activity,
  rainy,
  rainy_heavy,
  rainy_snow,
  search,
  settings,
  severe_cold,
  schedule,
  snowflake,
  storm,
  sunny,
  thunderstorm,
  water_drop,
  weather_hail,
};

export const mdiIconsUtil = {
  all,
  get (icon:IMdiIcon) {
    return all[icon];
  }
};

export declare type IMdiIcon = keyof typeof all;
