
<style lang="less">
.footer-bar {
  position: fixed;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: stretch;
  backdrop-filter: blur(20px) brightness(110%) contrast(80%) saturate(110%);

  .footer-bar-btn-highlight {
    position: absolute;
    top: 0;
    height: 100%;
    width: calc(20% - 1px);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .footer-bar-btn {
    flex: 1 1 0;
    text-align: center;
    font-weight: bold;
    font-size: 0.65rem;
    position: relative;

    .footer-bar-btn-body {
      position: absolute;
      inset: 0 0 0 0;
      transition: all 200ms ease-in-out;
      opacity: 0.75;
      cursor: pointer;
      border-right: solid 1px rgba(0, 0, 0, 0.2);

      .btn-icon {
        position: absolute;
        left: 0;
        width: 100%;
        top: 5%;
        height: 55%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .btn-title {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 15%;
        height: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>

<template>
  <div class="footer-bar" :style="{
    width: `${clientContext.screenWidth}px`,
    height: `${clientContext.footerBarHeight}px`,
    backgroundColor: `${clientContext.theme.color}99`,
  }">
    <div class="footer-bar-btn-highlight" :style="highlightStyle"></div>
    <div v-for="[btnIndex, btn] of buttons.entries()"
        class="footer-bar-btn"
        @click="clickIndex(btnIndex)"
    >
      <div class="footer-bar-btn-body">
        <div class="btn-icon"><mdi-icon :size="2.2" :icon="btn.icon" /></div>
        <div class="btn-title">{{ btn.title }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { clientContext } from 'src/client/context';
import { computed } from 'vue';
import MdiIcon from 'src/client/mdi-icon.vue';
import { clientRouter } from 'src/client/router';

const buttons = computed(() => {
  return [
    {
      title: 'Hour',
      icon: 'arrow_circle_down',
    },
    {
      title: 'Today',
      icon: 'schedule',
    },
    {
      title: 'Tomorrow',
      icon: 'arrow_circle_right',
    },
    {
      title: 'Week',
      icon: 'bar_chart',
    },
    {
      title: 'Calendar',
      icon: 'calendar_month',
    },
  ];
});

const highlightStyle = computed(() => {
  return {
    left: `${(clientContext.pageIndexDecimal / 4) * 0.8 * 100}%`,
  };
});

const clickIndex = (idx:number) => {
  if (idx === 4) {
    clientContext.report.calendarDay = null;
    clientContext.settings.calendarDateNumber = null;
  }
  clientRouter.goTo(idx);
};
</script>
