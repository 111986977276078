
<style lang="less">
.week-day {
  display: flex;
  flex-direction: column;
  text-align: center;

  .wk-day-title {
    flex: 1 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .wk-day-graph {
    background-color: rgba(255, 255, 255, 0.05);
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 250px;
    position: relative;
    .wk-day-graph-lbl {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 1.0rem;
      opacity: 1;
      z-index: 1;
      -webkit-text-stroke-width: 0.2rem;
    }
    .wk-day-graph-bar {
      position: absolute;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.2);

      .wk-day-graph-water {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

      }
    }
  }

  .wk-date-title {
    flex: 1 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 0.7rem;
  }
  .wk-day-icon {
    flex: 1 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wk-day-precip {
    flex: 1 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .wk-day-wind-icon {
    flex: 1 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: bold;
    > img {
      animation-name: wk-day-wind-icon-spin;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      width: 50%;
    }
  }
  .wk-day-generic {
    flex: 1 0 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}

@keyframes wk-day-wind-icon-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<template>
  <div class="week-day" @click="clickDay">
    <div class="wk-day-title">{{ djs.format('dd').toUpperCase() }}</div>
    <div class="wk-day-graph">
      <div class="wk-day-graph-lbl theme-stroke-color" :style="{ top: `${1 + (1 - tempToPerc(props.day.tempHigh)) * 100}%` }">{{ clientFormatters.temp(props.day.tempHigh) }}</div>
      <div class="wk-day-graph-bar" :style="{
        top: `${(1 - tempToPerc(props.day.tempHigh)) * 100}%`,
        bottom: `${tempToPerc(props.day.tempLow) * 100}%`,
      }">
        <div v-if="props.day.precipChance" class="wk-day-graph-water" :style="{ height: `${Math.round(props.day.precipChance * 100)}%` }">
          <precip-block :moisture-type="moistureType" />
        </div>
      </div>
      <div class="wk-day-graph-lbl theme-stroke-color" :style="{bottom: `${1 + tempToPerc(props.day.tempLow) * 100}%`}">{{ clientFormatters.temp(props.day.tempLow) }}</div>
    </div>
    <div class="wk-date-title">{{ props.day.daysFromToday === 0 ? 'Today' : djs.format('MMM D') }}</div>
    <div class="wk-day-icon">
      <mdi-icon :icon="conditionCodeUtil.get(props.day.conditionCode)?.icon" :size="1.4" />
    </div>
    <div class="wk-day-precip" :style="{ opacity: props.day.precipChance > 0.1 ? 1 : 0.3}">
      <div>
        <div>{{ clientFormatters.precipChance(props.day.precipChance) }}</div>
        <div style="font-size: 0.7em;">{{ moistureType.toUpperCase() }}</div>
      </div>
    </div>
    <div class="wk-day-wind-icon" :style="{ opacity: isWindy ? 1 : 0.3}">
      <img src="../big-graph/fan.png" :style="{'animation-duration': `${fanDuration}ms`}" />
    </div>
    <div class="wk-day-generic" :style="{ opacity: isWindy ? 1 : 0.3}">
      <div>
        <div style="font-size: 1.2em;">{{ clientFormatters.windSpeed(props.day.windSpeed) }}</div>
        <div style="font-size: 0.6em;">{{ clientFormatters.windUnits().toUpperCase() }}</div>
      </div>
    </div>
    <div v-if="false" class="wk-day-generic" :style="{ opacity: (props.day.humidity < 0.2 || props.day.humidity >= 0.85) ? 1 : 0.3 }">
      <div>
        <div style="font-size: 0.9em;">
          {{ typeof props.day.humidity === 'number' ? `${Math.round(props.day.humidity * 100)}` : '-' }}<span class="superscript">%</span>
        </div>
        <div style="font-size: 0.5em;">HUMID</div>
      </div>
    </div>
    <div class="wk-day-generic" :style="{ opacity: (props.day.cloudCover >= 0.5) ? 1 : 0.3 }">
      <div>
        <div style="font-size: 0.9em;">
          {{ typeof props.day.cloudCover === 'number' ? `${Math.round(props.day.cloudCover * 100)}` : '-' }}<span class="superscript">%</span>
        </div>
        <div style="font-size: 0.5em;">CLOUDY</div>
      </div>
    </div>
    <div class="wk-day-generic" :style="{ opacity: (props.day.maxUvIndex > 6) ? 1 : 0.3 }">
      <div>
        <div style="font-size: 0.9em;">
          {{ typeof props.day.maxUvIndex === 'number' ? `${props.day.maxUvIndex}` : '-' }}
        </div>
        <div style="font-size: 0.7em;">UV</div>
      </div>
    </div>

  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash';
import dayjs from 'dayjs';
import { clientFormatters } from 'src/utils/formatters.client';
import MdiIcon from '@/client/mdi-icon.vue';
import { conditionCodeUtil } from '@/report/types/condition-codes';
import PrecipBlock from '@/screen/week-screen/precip-block.vue';
import { clientRouter } from '@/client/router';
import { clamp } from '@/utils/math';
import { onMounted } from 'vue';

const props = defineProps<{
  week: IWeatherReportWeek,
  day: IWeatherReportDay,
}>();
const djs = dayjs(Date.fromDateNum(props.day.dateNumber));
const maxTemp = _.maxBy(props.week.days, d => d.tempHigh).tempHigh;
const minTemp = _.minBy(props.week.days, d => d.tempLow).tempLow;
const tempToPerc = (temp:number) => ((temp - minTemp) / (maxTemp - minTemp));

const isWindy = props.day.windSpeed >= 20;
const fanDuration = (() => {
  const perc = 1 - clamp(props.day.windSpeed / 30, 0, 1);
  const slowDuration = 4000;
  const fastDuration = 500;
  return fastDuration + ((slowDuration - fastDuration) * perc);
})();

const clickDay = () => {
  clientRouter.goToDate(props.day.dateNumber);
};

const moistureType = clientFormatters.precipTypeToMoistureType(props.day.precipType, (props.day.tempHigh + props.day.tempLow) / 2);


</script>
