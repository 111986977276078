
<style lang="less">
.build-info {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  opacity: 0.5;
  margin:40px 0;
}
</style>

<template>
<screen :settings="{ screenIndex: props.screenIndex }" :style="{ opacity: isLoaded ? 1 : 0.001 }">
  <screen-header v-if="overridingNowTime === null" :title="title" :subtitle="subtitle" />
  <graph-details v-else :time="overridingNowTime" :day="d" />
  <day-graph v-if="d?.hours?.length" :date-number="dateNumber" @onNowTime="t => overridingNowTime = t" @load="onGraphLoad" />
  <day-post-graph :date-number="dateNumber" />
  <div style="height: 2px;"></div>
  <day-stat-row v-for="row of rows" :cells="row" />
  <div class="build-info">BUILD: {{ buildTimestamp }}</div>
  <div style="height: 20px;"></div>
  <div style="height: 200px;" v-if="!d?.hours?.length"></div>
</screen>
</template>

<script lang="ts" setup>
import Screen from 'src/screen/screen.vue';
import ScreenHeader from 'src/screen/screen-header.vue';
import DayPostGraph from 'src/screen/day-screen/day-post-graph.vue';
import DayGraph from 'src/screen/day-screen/day-graph/day-graph.vue';
import DayStatRow, { IStatCell } from 'src/screen/day-screen/day-stat-row.vue';
import dayjs from 'dayjs';
import { getComparativeSummary } from 'src/report/utils/comparative-summary';
import { clientContext } from 'src/client/context';
import _ from 'lodash';
import { clientFormatters } from 'src/utils/formatters.client';
import { ref } from 'vue';
import GraphDetails from 'src/screen/day-screen/graph-details.vue';

const props = defineProps<{
  screenIndex: number,
  dateNumber: IDateNumber,
}>();
const overridingNowTime = ref<number>(null);

const buildTimestamp = __BUILD_TIMESTAMP__;

const title = dayjs(Date.fromDateNum(props.dateNumber)).format('dddd, MMMM Do');
const subtitle = getComparativeSummary({ report: clientContext.report, dayNumber: props.dateNumber }); // `9° cooler than yesterday. 80% chance of rain.`;
const d = [...clientContext.report.days, clientContext.report.calendarDay].find(d => d?.dateNumber === props.dateNumber);

const stats:IStatCell[] = [
  { label: 'Wind Dir', value: clientFormatters.windDirection(d.windDirection) },
  { label: 'Wind', value: clientFormatters.windSpeed(d.windSpeed), valueSuffix: clientFormatters.windUnits() },
  { label: 'Wind Gust', value: clientFormatters.windSpeed(d.windMaxSpeed), valueSuffix: clientFormatters.windUnits() },
  { label: 'UV Index', value: d.maxUvIndex?.toFixed(1) },
  { label: 'Clouds', value: Math.round(d.cloudCover * 100).toString(), valueSuffix: '%' },
  { label: 'Moon', value: clientFormatters.moonPhase(d.moonPhase) },
  { label: 'Sunrise', value: clientFormatters.timeString(d.sunriseTime).replace(/[a-z]+/ig, ''), valueSuffix: clientFormatters.timeString(d.sunriseTime).replace(/[^a-z]/ig, '') },
  { label: 'Sunset', value: clientFormatters.timeString(d.sunsetTime).replace(/[a-z]+/gi, ''), valueSuffix: clientFormatters.timeString(d.sunsetTime).replace(/[^a-z]/ig, '') },
  { label: 'Humidity', value: `${Math.round(d.humidity * 100)}`, valueSuffix: '%' },
  { label: 'Dew Point', value: clientFormatters.temp(d.tempDewPoint) },
  { label: 'Updated', value: clientFormatters.date(clientContext.report.current.time, 'h:mm'), valueSuffix: clientFormatters.date(clientContext.report.current.time, 'a') },
].filter(x => x.label && x.value);

const rows = _.chunk(stats, 4);

const isLoaded = ref(false);
const onGraphLoad = () => {
  isLoaded.value = true;
};

</script>
