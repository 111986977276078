<style lang="less">
@import "../root/root.less";
.screens {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  .hide-scrollbar;
}
</style>

<template>
  <screens-loading v-if="isLoading" />
  <screens-report v-else-if="clientContext.report && clientContext.report.receiptStatus?.expiresOnMs > Date.now()" :key="clientContext.report.id" />
  <screens-expired v-else-if="clientContext.report" :key="`expired-${clientContext.report.id}`" />
  <screens-error v-else :error="error" />
</template>

<script setup lang="ts">
import { clientContext } from 'src/client/context';
import ScreensReport from '@/device/screens-report.vue';
import ScreensError from '@/device/screens-error.vue';
import ScreensLoading from '@/device/screens-loading.vue';
import { computed } from 'vue';
import ScreensExpired from '@/expired/screens-expired.vue';

const isLoading = computed(() => {
  if (clientContext.isReportLoading) return true;
  if (clientContext.isLiveLocation && clientContext.isLiveLocationLoading) return true;
  return false;
});

const error = computed(() => {
  if (clientContext.reportError) return clientContext.reportError;
  if (clientContext.isLiveLocation && clientContext.liveLocationErrorMessage) return clientContext.liveLocationErrorMessage;
  return 'Not loading and no report.';
});
</script>
