import { incrementDateNumber } from 'src/utils/datenumber';
import dayjs from 'dayjs';
import { reportUtils } from 'src/utils/report';
import { clientFormatters } from 'src/utils/formatters.client';


const describeDate = (daysFromToday: number): string => {
  return daysFromToday === -1 ? "Yesterday was" :
    daysFromToday === 0  ? "Today is" :
      daysFromToday === 1  ? "Tomorrow will be" :
        daysFromToday < 0   ? "It was" : "It will be";
}

const describePrevDate = (daysFromToday: number):string => {
  return daysFromToday === -1 ? "yesterday" :
    daysFromToday === 0  ? "today" :
      daysFromToday === 1  ? "tomorrow" : "the day before";
}

function noMoreRainToday(report: IWeatherReport, day: IWeatherReportDay): boolean {
  // Must be today
  if (day.daysFromToday !== 0 || !day.hours?.length) {
    return false;
  }

  const currentHour = parseInt(dayjs().tz(report.timezone).format('H'));
  const hasRain = !!day.hours.find(h => {
    const hr = parseInt(dayjs().tz(report.timezone).format('H'));
    if (hr < currentHour) return false;
    return h.precipChance > 0;
  });
  return !hasRain;
}

const describeDateDifference_getRelevantTemps = (hours: IWeatherReportDayHour[]): number[] => {
  const temps = hours.map(hour => hour.tempReal).filter(temp => temp !== undefined);
  return temps.length > 18 ? temps.slice(9, 18) : temps;
};

const describeDateDifference = (hours: IWeatherReportDayHour[], prevHours: IWeatherReportDayHour[]): string => {
  const dayTemps = describeDateDifference_getRelevantTemps(hours);
  const prevTemps = describeDateDifference_getRelevantTemps(prevHours);

  if (!dayTemps.length || !prevTemps.length) {
    return "";
  }

  const dayAvg = dayTemps.reduce((a, b) => a + b, 0) / dayTemps.length;
  const prevDayAvg = prevTemps.reduce((a, b) => a + b, 0) / prevTemps.length;

  const diff = Math.round(dayAvg - prevDayAvg);
  return diff > 0 ? `${diff}° warmer than` : diff < 0 ? `${-diff}° cooler than` : "The same as";
}


export interface IGetComparativeSummaryArgs {
  report: IWeatherReport;
  dayNumber: IDateNumber;
}

// return "9° cooler than yesterday. 80% chance of snow.";
export const getComparativeSummary = (args:IGetComparativeSummaryArgs):string => {
  let subTitle = "";
  const days = [...args.report.days, args.report.calendarDay].filter(x => x);
  const prevDay = days.find(d => d.dateNumber === incrementDateNumber(args.dayNumber, -1));
  const day = days.find(d => d.dateNumber === args.dayNumber);

  if (prevDay?.hours?.length && day?.hours?.length) {
    const descriptionOfDifference = describeDateDifference(day.hours, prevDay.hours);

    if (descriptionOfDifference) {
      subTitle = `${descriptionOfDifference} ${describePrevDate(prevDay.daysFromToday)}.`;
    }

    // if (includeCurrentDay) {
    //   const curDay = describeDate(dayReport.daysFromToday);
    //   subTitle = `${curDay} ${subTitle}`;
    // }

    const precipType = reportUtils.precipTypeToRainOrSnow(day.precipType).toLowerCase();
    if (day.precipChance > 0) {
      subTitle += noMoreRainToday(args.report, day) ? ` No more ${precipType}.` : ` ${Math.round(day.precipChance * 100)}% chance of ${precipType}.`;
    } else {
      subTitle += ` No ${precipType}.`;
    }
  }

  if (!subTitle) {
    const temperatureMax = clientFormatters.temp(day.tempHigh);
    const temperatureMin = clientFormatters.temp(day.tempLow);

    if (temperatureMax !== undefined && temperatureMin !== undefined) {
      subTitle = `High ${temperatureMax}. Low ${temperatureMin}.`;
    }
  }

  if (day.daysFromToday > 10) {
    subTitle += " Based on historic averages.";
  }

  return subTitle;
}

