
<style lang="less" scoped>
.btn-tab {
  text-transform: uppercase;
  opacity: 0.4;
  font-size: 0.8em;
  color: #fff;
  border: solid 2px #444;
  padding: 5px 10px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 10px;
  &:hover {
    opacity: 0.7;
  }

  &.active {
    opacity: 1;
  }
}
.debug-logs {
  color: #fff;
  font-size: 11px;
  letter-spacing: -1px;
  font-family: Consolas, "Andale Mono", SFMono-Regular, Monospaced, mono, monospace;
  overflow-wrap: anywhere;

  > .log {
    margin-bottom: 5px;

    &.warn { color: #fd9; }
    &.error { color: #f88; }
  }
}

.debug-more {
  button {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    height: 50px;
    margin-bottom: 8px;
  }
}
</style>

<template>
  <wn-modal :settings="modalSettings">
    <div style="padding: 10px 15px;">
      <div style="display: flex; margin: 0 0 15px 0;">
        <div
            v-for="t of tabs"
            class="btn-tab"
            :class="{active: isTabSelected(t)}"
            @click="tab = t"
        >{{ t }}</div>
      </div>
      <div v-if="isTabSelected('Logs')" class="debug-logs" @click="tab = 'Logs'">
        <div class="log" v-for="log of collector.logs" :class="log.type">{{ log.date }}: {{ log.msg }}</div>
      </div>
      <div v-if="isTabSelected('State')" @click="tab = 'State'" class="debug-logs">
        {{ JSON.stringify(_.omit(clientContext, 'report'), null, 2) }}
      </div>
      <div v-if="isTabSelected('More')" @click="tab = 'More'" class="debug-more">
        <div>
          <button type="button" @click="clickReset">Reset App</button>
        </div>
        <div>
          <button type="button" @click="clickLocation">Get Location</button>
        </div>
      </div>
    </div>
  </wn-modal>
</template>

<script setup lang="ts">
import _ from 'lodash';
import WnModal from '@/client/modal/wn-modal.vue';
import { ClientLogCollector } from '@/client/debugger/log-collector';
import { useModalService } from '@/client/modal/service';
import { ref } from 'vue';
import { nativeRepoService } from '@/native/native-repo';
import { runNativeAppOperation } from '@/native/app-operations';
import { clientContext } from 'src/client/context';

const modal = useModalService();

const props = defineProps<{ collector: ClientLogCollector }>();

const tabs = ['Logs', "State", 'More'];

const tab = ref(tabs[0]);

const isTabSelected = (t:string) => t === tab.value;

const modalSettings:IModalSettings = {
  title: 'Debug',
  // bgColor: '#000',
  rightButton: {
    label: 'Close',
    click () {
      modal.close();
    },
  },
  onClickAbove(){
    modal.close();
  }
};

const clickLocation = async () => {
  try {
    const res = await runNativeAppOperation('get-location', { timeout: 10000 });
    alert(JSON.stringify(res));
  }catch(err) {
    alert(`EXCEPTION: ${err}`);
  }
};
const clickReset = () => {
  nativeRepoService.reset();
};
</script>