
import dayBg from './graphics/bg-day-back.jpg';
import dayFg from './graphics/bg-day-front.png';
import nightBg from './graphics/bg-night-back.jpg';
import nightFg from './graphics/bg-night-front.png';
import duskBg from './graphics/bg-dusk-back.jpg';
import duskFg from './graphics/bg-dusk-front.png';

export interface ITheme {
  bg: string;
  fg: string;
  dark: boolean;
  color: string;
}

const day:ITheme = { bg: dayBg, fg: dayFg, dark: true, color: '#1158A3' };
const dusk:ITheme = { bg: duskBg, fg: duskFg, dark: true, color: '#C45E25' };
const night:ITheme = { bg: nightBg, fg: nightFg, dark: true, color: '#000000' };

const all:ITheme[] = [day, dusk, night];

export const themesUtil = {
  all,
  day,dusk,night,
};
