
<style lang="less">
.wn-logo {
  width: 240px;
  position: relative;
}
</style>

<template>
  <img ref="imgRef" class="wn-logo" src="./wn-logo.svg" />
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import gsap from "gsap";
import { setTimeoutAsync } from '@/utils/timeout';

const imgRef = ref(null);

async function cloneAndFixPosition(img) {
  if (!(img instanceof HTMLImageElement)) return;

  const rect = img.getBoundingClientRect();
  const clonedImg = img.cloneNode(true) as HTMLImageElement;


  Object.assign(clonedImg.style, {
    position: 'fixed',
    top: `${rect.top}px`,
    left: `${rect.left}px`,
    width: `${rect.width}px`,
    height: `${rect.height}px`,
    zIndex: '9999'
  });

  document.body.appendChild(clonedImg);

  await setTimeoutAsync(300);
  img.style.visibility = 'hidden';

  return clonedImg;
}

const run = async (flip:() => void) => {
  const img = await cloneAndFixPosition(imgRef.value);
  const tl = gsap.timeline({ repeat: 0 });
  const onComplete = () => {
    img.style.visibility = 'hidden';
    img.parentNode.removeChild(img);
  };

  tl.fromTo(
      img,
      { scale: 1, y: 0 },
      { scale: 2, duration: 0.5, ease: "back.in" },
  )
      .to(img, { scale: 6, duration: 0.2, ease: "linear" })
      .to(img, { scale: 18, opacity: 0, duration: 0.2, ease: "linear", onStart:flip, onComplete })
};

// setTimeout(() => run(() => {}), 1000);

defineExpose({ run });

</script>