

import { nextTick, ObjectDirective } from 'vue';
import { VFitManager } from 'src/utils/v-fit-manager';

const stateMap = new WeakMap<HTMLElement, VFitManager>();

export const vFitDirective:ObjectDirective<HTMLElement> = {
  mounted(el) {
    const manager = new VFitManager(el);
    stateMap.set(el, manager);
  },
  updated(el){
    const mgr = stateMap.get(el);
    void nextTick(() => mgr?.redraw());
  },
  beforeUnmount(el) {
    const mgr = stateMap.get(el);
    mgr?.destroy();
    stateMap.delete(el);
  }
};

