

export const startScrollLock = (el:HTMLDivElement) => {
  const scrollers:{ el: HTMLDivElement, x: number, y: number, overflow: string }[] = [];
  let e = el;
  while (e = e.parentElement) {
    if (e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth) {
      const pos = {
        scrollLeft: e.scrollLeft,
        scrollTop: e.scrollTop,
      };
      const scroller = {
        el: e,
        overflow: e.style.overflow,
      };
      e.style.overflow = 'hidden';
      Object.assign(e, pos);
      scrollers.push(scroller);
    }
  }

  return {
    unlock(){
      for (const scroller of scrollers) {
        const pos = {
          scrollLeft: scroller.el.scrollLeft,
          scrollTop: scroller.el.scrollTop,
        };
        scroller.el.style.overflow = scroller.overflow;
        Object.assign(scroller.el, pos);
      }
    },
  };
}
