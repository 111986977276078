import { reactive, watch } from 'vue';
import { win } from 'src/client/window';
import { ITheme, themesUtil } from 'src/theme/themes';
import { nativeRepoService } from '../native/native-repo';
import { nativeService } from '../native/service';
import { clientLocationService } from './location';

const getTheme = () => {
  const today = clientContext?.report?.days?.find(d => d.dateNumber === clientContext?.report?.today);
  const now = Date.now();
  if (today) {
    const duskRadiusMs = 1000 * 60 * 30;
    const isDusk = Math.abs(now - today.sunriseTime) < duskRadiusMs
      || Math.abs(now - today.sunsetTime) < duskRadiusMs;
    if (isDusk) return themesUtil.dusk;
    if (now < today.sunriseTime) return themesUtil.night;
    if (now > today.sunsetTime) return themesUtil.night;
  }
  return themesUtil.day;
};

export const clientContext = reactive({
  isLocal: !window.location.href.includes('weathernerdapi.allstarapps.com'),
  isBrowser: !window['wn.native.context'],
  screenWidth: win.width,
  screenHeight: win.height,
  pullToRefreshHeight: 50,
  statusBarHeightOfStatus: 0,
  statusBarHeightOfTitle: 0,
  statusBarHeight: 0,
  locationsHeight: 0,
  bodyHeight: 0, // height of visible screen
  headerHeight: 0,
  graphHeight: 0,
  postGraphHeight: 0,
  footerBarHeight: 0,
  pageIndex: 1,
  pageIndexDecimal: 1,
  theme: null as ITheme,
  settings: nativeRepoService.loadUserSettings(),
  report: null as IWeatherReport, // getDemoWeatherReport() as IWeatherReport,
  reportError: null as string,
  isReportLoading: false,
  get isLiveLocation ():boolean { return clientContext.settings.currentLocation?.id === 'live'; },
  get isLiveLocationLoading ():boolean { return clientLocationService.isLoading; },
  get liveLocationErrorMessage ():boolean { return clientLocationService.errorMessage; },
  latestReloadRequestTime: Date.now(),
});

const redraw = () => {
  clientContext.statusBarHeightOfStatus = (() => {
    if (nativeService.context?.safeAreaInsets?.top) return nativeService.context.safeAreaInsets.top - 11;
    if (nativeService.isConnected) return 20;
    return 0;
  })();
  clientContext.statusBarHeightOfTitle = (() => {
    if (nativeService.context?.safeAreaInsets?.top) return 22;
    return 50;
  })();
  clientContext.statusBarHeight = clientContext.statusBarHeightOfStatus + clientContext.statusBarHeightOfTitle;
  clientContext.footerBarHeight = 100;
  clientContext.bodyHeight = clientContext.screenHeight - (clientContext.statusBarHeight + clientContext.footerBarHeight);
  clientContext.screenHeight = nativeService.context.resolutionInPoints.height;
  clientContext.screenWidth = nativeService.context.resolutionInPoints.width;
  clientContext.locationsHeight = 70;

  clientContext.headerHeight = Math.ceil(clientContext.bodyHeight * 0.20);
  clientContext.graphHeight = Math.ceil(clientContext.bodyHeight * 0.55);
  clientContext.postGraphHeight = Math.ceil(clientContext.bodyHeight * 0.25);

  document.querySelector('html').style.fontSize = `${clientContext.screenWidth/16}px`;
};
redraw();


watch(() => [clientContext.report], () => {
  clientContext.theme = getTheme();
}, { immediate: true });

watch(() => [win.width, win.height, nativeService.context?.safeAreaInsets?.top, nativeService.isConnected], redraw);
