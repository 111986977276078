
<style lang="less">
.full-graphic {
  pointer-events: none;
  top: 0;
  left: 0;
  position: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &.blurred {
    transform: scale(1.1);
    filter:blur(30px);
  }
}
</style>

<style>
.theme-color {
  color: var(--theme-color) !important;
}
.theme-bg-color {
  background-color: var(--theme-color) !important;
}
.theme-stroke-color {
  paint-order: stroke fill;
  -webkit-text-stroke-color: var(--theme-color) !important;
}
</style>

<template>
    <div class="full-graphic" :style="{backgroundImage: `url(${JSON.stringify(clientContext.theme.bg)})`, ...graphicStyle}" style="z-index: -4;" />
    <div class="full-graphic" :style="{backgroundImage: `url(${JSON.stringify(clientContext.theme.fg)})`, ...graphicStyle }" style="opacity: 1; z-index: -3;" />
</template>

<script setup lang="ts">
import { computed, watchEffect } from 'vue';
import { clientContext } from 'src/client/context';
import { globalLoading } from '@/client/loading-tracker';

function loadImage(src, callback) {
  const img = new Image();
  img.onload = () => callback(null, img);
  img.onerror = () => callback(new Error('Failed to load image'));
  img.src = src;
}

const loaders = {
  bg: globalLoading.start('bg'),
  fg: globalLoading.start('fg'),
};

loadImage(clientContext.theme.bg, () => loaders.bg.end());
loadImage(clientContext.theme.fg, () => loaders.fg.end());

const graphicStyle = computed(() => {
  return {
    width: `${clientContext.screenWidth}px`,
    height: `${clientContext.screenHeight}px`,
  };
});
const themeColor = computed(() => clientContext.theme.color);

watchEffect(() => {
  document.documentElement.style.setProperty('--theme-color', themeColor.value)
});
</script>
