import dayjs from 'dayjs';


export const timeToDateNumber = (time: number, tz: ITimezone):IDateNumber => {
  return new Date(time).toDateNum(tz);
};

export const incrementDateNumber = (dateNumber: number, count: number):IDateNumber => {
  return dayjs(Date.fromDateNum(dateNumber)).add(count, 'days').toDate().toDateNum();
};

export const parseDateNumber = (num:IDateNumber):{ year: number, month: number, day: number } => {
  const date = String(num || '').trim();
  if (/^[123]\d\d\d[01]\d[0123]\d$/.test(date)) {
    return {
      year: parseInt(date.substring(0, 4), 10),
      month: parseInt(date.substring(4, 6), 10),
      day: parseInt(date.substring(6), 10),
    };
  } else {
    return null;
  }
};

declare global {
  type IDateNumber = number; // YYYYMMDD

  interface Date {
    toDateNum ():IDateNumber;
  }

  interface DateConstructor {
    fromDateNum(dateNumber:IDateNumber, tz?:ITimezone):Date;
  }
}

Date.prototype.toDateNum = function (tz?:ITimezone) {
  const d = this;
  if (Number.isNaN(this.getTime())) return null;
  if (tz) return parseInt(dayjs(d).tz(tz).format('YYYYMMDD'), 10);
  return d.getFullYear() * 10000 + (d.getMonth() + 1) * 100 + d.getDate();
};

Date.fromDateNum = function (dateNum:IDateNumber, tz?:ITimezone) {
  if (typeof dateNum !== 'number') return null;
  const parsed = parseDateNumber(dateNum);
  if (!parsed) return null;
  if (tz) return dayjs.tz(String(dateNum), "YYYYMMDD", tz).toDate();
  return new Date(parsed.year, parsed.month-1, parsed.day);
};
