import { Ref } from 'vue';
import { clamp } from 'src/utils/math';
import { startScrollLock } from './scroll-locker';


export interface ISetupNowLineDraggingArgs {
  el: HTMLDivElement;
  nowLinePercOverride: Ref<number>;
  nowLinePerc: Ref<number>;
}

export const setupNowLineDragging = (args:ISetupNowLineDraggingArgs) => {
  const isTouchDevice = 'ontouchstart' in window;
  if (isTouchDevice) {
    setup_touch(args);
  }else{
    setup_mouse(args);
  }
};

const setup_mouse = (args:ISetupNowLineDraggingArgs) => {
  const { el, nowLinePercOverride } = args;
  if (!el) return;

  let isDragging = false;
  const width = el.clientWidth;

  function updateNowX(x:number) {
    nowLinePercOverride.value = clamp(x / width, 0, 1);
  }

  el.addEventListener('mousedown', function (e) {
    isDragging = true;
    updateNowX(e.clientX);
  });
  el.addEventListener('mousemove', function (e) {
    if (!isDragging) return;
    e.preventDefault();
    updateNowX(e.clientX);
  });
  el.addEventListener('mouseup', function stopInteraction(e) {
    isDragging = false;
    nowLinePercOverride.value = null;
  });
};

declare type IDragType = 'nowline' | 'screen';

const setup_touch = (args:ISetupNowLineDraggingArgs) => {
  const { el, nowLinePercOverride } = args;
  const elScreens = document.querySelector('.screens');


  el.addEventListener('touchstart', function (e) {
    const width = el.clientWidth;
    const cleaners = [];
    let _dt:IDragType = null;
    const state = {
      timeout: null,
      startX: null,
      latestX: null,
      startTime: null,
      scrollLock: null,
      get dragType():IDragType {
        return _dt;
      },
      set dragType(dt:IDragType) {
        if (dt === 'nowline' && !state.scrollLock) {
          state.scrollLock = startScrollLock(el);
          cleaners.push(() => state.scrollLock?.unlock());
        }
        _dt = dt;
      },
    };

    const x = e.touches?.[0]?.clientX;
    const y = e.touches?.[0]?.clientY;
    state.startX = x;
    state.startY = y;
    state.latestX = state.startX;
    state.latestY = state.startY;
    state.startTime = Date.now();
    //console.log(`touchstart: ${x}`);

    const distance = Math.abs(state.startX - (args.nowLinePerc.value * width));
    if (distance < 10) {
      state.dragType = 'nowline';
      return;
    }

    state.timeout = setTimeout(() => {
      if (state.dragType) return;
      const ms = Date.now() - state.startTime;
      const distX = Math.abs(state.startX - state.latestX);
      const distY = Math.abs(state.startY - state.latestY);
      const dist = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));
      const pxPerSecond = dist / (ms / 1000);
      state.dragType = pxPerSecond > 10 ? 'screen' : 'nowline';
      //console.log(`touchstart.timeout: ${state.dragType} ${JSON.stringify({ pxPerSecond, x })}`);
      if (state.dragType === 'nowline'){
        updateNowX(x);
      }
    }, 50);
    cleaners.push(() => clearTimeout(state.timeout));

    el.addEventListener('touchmove', handleMove);
    cleaners.push(() => el.removeEventListener('touchmove', handleMove));
    el.addEventListener('touchend', stopInteraction);
    cleaners.push(() => el.removeEventListener('touchend', stopInteraction));
    el.addEventListener('touchcancel', stopInteraction);
    cleaners.push(() => el.removeEventListener('touchcancel', stopInteraction));
    elScreens.addEventListener('scroll', onScroll);
    cleaners.push(() => elScreens.removeEventListener('scroll', onScroll));

    function updateNowX(x:number) {
      nowLinePercOverride.value = clamp(x / width, 0, 1);
    }

    function onScroll () {
      if (!state.dragType) state.dragType = 'screen';
      //console.log(`scroll: ${state.dragType} ${elScreens.scrollLeft}x${elScreens.scrollTop}`)
      if (state.dragType === 'nowline') {
        e.preventDefault();
      }
    }

    function handleMove(e) {
      const x = e.touches?.[0]?.clientX;
      state.latestX = x;
      //console.log(`touchmove: ${state.dragType} ${JSON.stringify({ x })}`);

      if (!state.dragType) return;

      if (state.dragType === 'nowline') {
        updateNowX(x);
      }else {
        e.preventDefault();
      }
    }

    function stopInteraction(e) {
      clearTimeout(state.timeout);
      nowLinePercOverride.value = null;
      //console.log(`touchend`);

      for (const c of cleaners) {
        c();
      }
    }
  });
};


function animateScroll(element, start, end, duration) {
  return new Promise(resolve => {
    const startTime = performance.now();

    function step(currentTime) {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutCubic = progress < 0.5
        ? 4 * progress * progress * progress
        : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      element.scrollLeft = start + (end - start) * easeInOutCubic;

      if (progress < 1) {
        requestAnimationFrame(step);
      }else{
        resolve();
      }
    }

    requestAnimationFrame(step);
  });
}
