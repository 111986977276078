import { clientContext } from 'src/client/context';
import { loadCalendarDayClient } from '../screen/calendar-screen/load-calendar-day.client';

export const clientRouter = {
  goTo(idx: number) {
    console.log(`clientRouter.goTo(${idx})...`);
    const screens = document.querySelector('.screens');
    const left = clientContext.screenWidth * idx;
    screens.scrollTo({ left, behavior: 'smooth' });
  },
  async goToDate(dateNumber: IDateNumber) {
    console.log(`clientRouter.goToDate(${dateNumber})...`);
    clientContext.settings.calendarDateNumber = dateNumber;
    await loadCalendarDayClient();
  },
};
