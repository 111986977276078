import dayjs from 'dayjs';

function deepStringify(obj, maxDepth = 4) {
  const seen = new WeakSet();

  function serialize(value, depth = 0) {
    if (depth > maxDepth) return '[Max Depth Exceeded]';

    if (value === null) return 'null';
    if (value === undefined) return 'undefined';
    if (value instanceof Node || value instanceof Element) return `[Node]`;
    if (value === window) return `[Window]`;

    const type = typeof value;

    // Handle primitive types
    if (type !== 'object') {
      if (type === 'function') return `[Function: ${value.name || 'anonymous'}]`;
      if (type === 'symbol') return value.toString();
      return String(value);
    }

    // Prevent circular references
    if (seen.has(value)) return '[Circular Reference]';
    seen.add(value);

    // Handle arrays
    if (Array.isArray(value)) {
      return `[${value.map(item => serialize(item, depth + 1)).join(', ')}]`;
    }

    // Handle other objects
    try {
      const pairs = [];
      const keys = new Set(Object.getOwnPropertyNames(value));
      keys.add('name');
      keys.add('message');
      keys.add('stack');
      keys.add('reason');
      for (const key of Array.from(keys)) {
        const val = value[key];
        if (val === null || val === undefined) continue;
        pairs.push(`${key}: ${serialize(val, depth + 1)}`);
      }
      return `{${String(value)},${pairs.join(', ')}}`;
    } catch (err) {
      return `[${value.constructor.name}]`;
    }
  }

  return serialize(obj);
}

let hasAlerted = false;

export interface IClientLog {
  date: string;
  msg: string;
  type: 'log' | 'warn' | 'error';
}

export class ClientLogCollector {
  logs:IClientLog[] = [];

  constructor() {
    console.log('logging.init');
    const collector = this;
    for (const key of ['log','warn','error'] as const) {
      const orig = console[key];
      console[key] = function() {
        orig.apply(console, arguments);
        const msg = Array.from(arguments).map(x => deepStringify(x)).join(' ');
        collector.addLog({
          date: dayjs().format('M/D h:mm:ssa'),
          msg,
          type: key,
        });
      };
    }

    window.addEventListener('error', function(event) {
      collector.addLog({
        date: dayjs().format('M/D h:mm:ssa'),
        msg: 'onerror: '+deepStringify(event),
        type: 'error',
      });
    });

    window.addEventListener('unhandledrejection', function(event) {
      collector.addLog({
        date: dayjs().format('M/D h:mm:ssa'),
        msg: 'unhandledRejection: ' + deepStringify(event),
        type: 'error',
      });
    });
  }

  addLog(log:IClientLog) {
    this.logs.push(log);
    const MAX_SIZE = 500;
    if (this.logs.length > MAX_SIZE) {
      this.logs.splice(0, this.logs.length - MAX_SIZE);
    }
    if (log.type === 'error' && !hasAlerted) {
      hasAlerted = true;
      let msg = log.msg;
      if (!msg?.length || msg.length < 10) msg += new Error().stack;
      alert(msg);
    }
  }
}

export const clientLogCollector = new ClientLogCollector();