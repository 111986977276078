

export class UserSettings {
  radarStyle: IRadarStyle = 'radar';
  radarMapStyle: 'streets' | 'terrain' = 'streets';
  radarShowLocation: boolean = true;
  radarShowOtherLocations: boolean = true;
  radarIsPlaying: boolean = true;
  showFullDay: boolean = false;

  timezone: ITimezone = 'America/Chicago';
  countryCode: ICountryCode = 'US';
  langCode: ILangCode = 'en-US';

  tempUnits: ITempUnit = 'fahrenheit';
  windUnits: IWindUnit = 'mph';
  precipUnits: IPrecipUnit = 'in';
  distanceUnits: IDistanceUnit = 'mi';
  locations: IUserLocationSettings[] = [
    {
      id: 'live',
      title: 'My Location',
      //ll: { lat: 35.222569, lon: -97.439476 },
      ll: null,
    },
  ];
  calendarDateNumber: IDateNumber = null;
  currentLocation: IUserLocationSettings = null;
  registrationTime?: number = null;
  private constructor() {
    if (this.currentLocation) this.locations.push(this.currentLocation);
  }

  static init (obj?: Partial<IUserSettings>):IUserSettings {
    return Object.assign(new UserSettings(), obj);
  }
}

declare global {
  interface IUserLocationSettings {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    id: 'live' | string;
    title: string;
    ll: ILatLon;
  }

  type IUserSettings = UserSettings;
}
