
<style lang="less">
.radar-settings {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  animation: radar-settings-fade-in 300ms ease-out;
  animation-fill-mode: forwards;
  opacity: 0;
  z-index: 1000;
  display: flex;
  align-items: stretch;
  justify-content: stretch;

  .radar-settings-body {
    padding: 50px 30px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  .btn-done {
    border-radius: 4px;
    font-weight: bold;
    padding: 5px;
    text-align: center;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    color: #000;
  }
}

@keyframes radar-settings-fade-in {
  from { opacity:0; transform: translateY(20vh); }
  to { opacity:1; transform: translateY(0); }
}
</style>

<template>
  <Teleport to="body">
    <div class="radar-settings" :style="radarSettingsStyle">
      <div class="radar-settings-body">
        <div style="flex: 1;"></div>
        <toggle style="margin-bottom: 20px;" v-for="params of toggles" :params="params" />
        <div style="flex: 0 0 15px;"></div>
        <div @click="clickClose" class="btn-done">DONE</div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { clientContext } from 'src/client/context';
import Toggle, { IRadarToggleParams } from 'src/radar/toggle.vue';

const radarSettingsStyle = computed(() => {
  return {
    width: `${clientContext.screenWidth}px`,
    height: `${clientContext.screenHeight}px`,
  };
});

const emit = defineEmits<{ (event: 'close'):void }>();

const toggles:IRadarToggleParams[] = [
  {
    onLabel: 'Radar',
    offLabel: 'Satellite',
    isOn: computed({
      get(){ return clientContext.settings.radarStyle === 'radar'; },
      set(v){ clientContext.settings.radarStyle = v ? 'radar' : 'satellite'; },
    }),
  },
  {
    onLabel: 'Streets',
    offLabel: 'Terrain',
    isOn: computed({
      get(){ return clientContext.settings.radarMapStyle === 'streets'; },
      set(v){ clientContext.settings.radarMapStyle = v ? 'streets' : 'terrain'; },
    }),
  },
  {
    onLabel: 'Show Location',
    offLabel: 'Hide',
    isOn: computed({
      get(){ return clientContext.settings.radarShowLocation; },
      set(v){ clientContext.settings.radarShowLocation = v; },
    }),
  },
  {
    onLabel: 'Show Other Locs',
    offLabel: 'Hide',
    isOn: computed({
      get(){ return clientContext.settings.radarShowOtherLocations; },
      set(v){ clientContext.settings.radarShowOtherLocations = v; },
    }),
  },
];

const clickClose = () => {
  emit('close');
};

</script>
