import { nativeService } from './service';

export declare type INativeHapticStrength = 'low' | 'medium' | 'high';

class NativeHapticService {
  send(strength:INativeHapticStrength) {
    return nativeService.run('send-haptic-feedback', { strength });
  }
}

export const nativeHapticService = new NativeHapticService();
