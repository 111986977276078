import { nativeService } from './service';
import { AppOperations } from './app-operations';
import { setTimeoutAsync } from '../utils/timeout';
import { clientContext } from '../client/context';
import { demoReceiptUtil } from '../iap/demo-receipt-util';

const updateReceipt = (receipt:string) => {
  if (receipt !== nativeService.context.receipt) {
    nativeService.context.receipt = receipt;
  }
};

class NativeStoreKitService {
  productIds = {
    TwoMonths: 'WNTWOMONTHS',
    OneYear: 'WNONE',
    all: ['WNTWOMONTHS', 'WNONE'],
  };
  async getReceipt(args: AppOperations['get-receipt']['request']): Promise<AppOperations['get-receipt']['response']> {
    const res = await nativeService.run('get-receipt', args);
    updateReceipt(res.receipt);
    return res;
  }

  async refreshReceipt(args: AppOperations['refresh-receipt']['request']): Promise<AppOperations['refresh-receipt']['response']> {
    const res = await nativeService.run('refresh-receipt', args);
    updateReceipt(res.receipt);
    return res;
  }

  async purchase(args: AppOperations['purchase']['request']): Promise<AppOperations['purchase']['response']> {
    const res = await nativeService.run('purchase', args);
    updateReceipt(res.receipt);
    return res;
  }

  getProducts(args: AppOperations['get-products']['request']): Promise<AppOperations['get-products']['response']> {
    return nativeService.run('get-products', args);
  }
}

class DemoStoreKitService extends NativeStoreKitService {
  receipt: string = null;

  async getReceipt(args: AppOperations['get-receipt']['request']): Promise<AppOperations['get-receipt']['response']> {
    await setTimeoutAsync(100);
    return { receipt: this.receipt };
  }

  async refreshReceipt(args: AppOperations['refresh-receipt']['request']): Promise<AppOperations['refresh-receipt']['response']> {
    await setTimeoutAsync(1000);
    return { receipt: this.receipt };
  }

  async purchase(args: AppOperations['purchase']['request']): Promise<AppOperations['purchase']['response']> {
    await setTimeoutAsync(1000);
    const msg = `Buy ${args.productId}?`;
    if (confirm(msg)) {
      await setTimeoutAsync(1000);
      this.receipt = demoReceiptUtil.generate({ status: { expiresOnMs: Date.now() + (1000 * 60*60*24*30) } });
      updateReceipt(this.receipt);
      return { receipt: this.receipt, success: true };
    }else{
      await setTimeoutAsync(100);
      return { receipt: this.receipt, success: false };
    }
  }

  async getProducts(args: AppOperations['get-products']['request']): Promise<AppOperations['get-products']['response']> {
    await setTimeoutAsync(3000);
    return {
      products: [
        {
          title: 'Two Months',
          price: '0.99',
          currency: 'USD',
          currencySymbol: '$',
          description: 'Access all premium features on a monthly basis.',
          identifier: 'WNTWOMONTHS',
        },
        {
          title: 'One Year',
          price: '3.99',
          currency: 'USD',
          currencySymbol: '$',
          description: 'Access all premium features for a year.',
          identifier: 'WNONE',
        },
      ]
    };
  }
}


export const nativeStoreKitService = clientContext.isBrowser ? new DemoStoreKitService() : new NativeStoreKitService();
