<style lang="less">
.screens-expired {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  padding: 10px 30px;
  text-align: center;

  .expired-logo {
    text-align: center;
    margin: 15px 0;
    img {
      width: 200px
    }
  }

  .txt-day-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  .txt-renew {
    font-size: 1.3em;
    margin-bottom: 25px;
  }
}

.expired-tear {
  pointer-events: none;
}
</style>

<template>
  <div class="screens-expired" :style="style">

    <div>
      <div class="txt-day-title">
        <div v-fit>{{ dayTitle }}</div>
      </div>
      <day-stat-row :cells="cells" />
    </div>

    <div class="expired-logo">
      <img src="./expired-logo.png" ref="refLogo" />
    </div>

    <div>
      <div class="txt-renew">
        Bummer. Looks like
        your subscription expired.
      </div>
    </div>

    <renewal-buttons @done="onRenewal" />

  </div>
</template>

<script setup lang="ts">
import { clientContext } from '@/client/context';
import { computed, ref } from 'vue';
import { startTears } from './tears';
import DayStatRow, { IStatCell } from '@/screen/day-screen/day-stat-row.vue';
import { clientFormatters } from '@/utils/formatters.client';
import { reportUtils } from '@/utils/report';
import dayjs from 'dayjs';
import RenewalButtons from '@/expired/renewal/renewal-buttons.vue';

const refLogo = ref<HTMLDivElement>(null);
const style = computed(() => {
  return {
    minHeight: `${clientContext.screenHeight - clientContext.statusBarHeight }px`,
    marginTop: `${clientContext.statusBarHeight}px`
  };
});

startTears({ refLogo });

const gr = clientContext.report.guestReport;
const cells:IStatCell[] = [
  { label: 'NOW', value: clientFormatters.temp(gr?.temp), big: true },
  { label: 'LOW', value: clientFormatters.temp(gr?.tempLow) },
  { label: 'HIGH', value: clientFormatters.temp(gr?.tempHigh) },
  { label: reportUtils.precipTypeToRainOrSnow(gr?.precipType), value: Math.round(gr?.precipChance * 100), valueSuffix: '%' },
];

const dayTitle = dayjs(Date.fromDateNum(gr.dateNumber)).format('dddd, MMMM Do');

const onRenewal = async (receipt:string) => {
  // setting the nativeContext.receipt should trigger reload
};

</script>
